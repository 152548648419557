export class BundleItemModel {
  public variantId?: number;
  public name: string;
  public sku: string;
  public quantity: number;

  constructor(data: any = {}) {
    this.variantId = data.variantId;
    this.name = data.name;
    this.sku = data.sku;
    this.quantity = data.quantity;
  }

  public mapData(data: any = {}) {
    this.variantId = data.single_product_id;
    this.name = data.single_product_name;
    this.sku = data.single_sku;
    this.quantity = data.quantity;
  }
}
