import {get, put} from '@/services/http';
import { BundleModel } from '@/models/bundle';
import List from './list';

export class BundleList extends List<BundleModel> {

  public async fetch() {
    const res: any = await get(`/bundle_products/data`, {});
    const data = res.data.bundleProducts || [];
    this.mapData(data);
  }

  public mapData(data: any = []) {
    if (!Array.isArray(data)) {
      return;
    }
    for (const s of data) {
      const bundle: BundleModel = new BundleModel();
      bundle.mapData(s);
      this.add(bundle);
    }
  }

  public async save() {
    try {
      for (const item of this.items) {
        item.bundleItems.items = item.bundleItems.items.filter(i => i.quantity > 0);
      }

      await put(`/bundle_products/data`, {
        bundleProducts: this.items,
      });
      return true;
    } catch (e) {}
    return false;
  }

  public async pushChanges() {
    try {
      await put(`/bundle_products/push_changes`, {});
      return true;
    } catch (e) {}
    return false;
  }

  public removeItem(item: BundleModel) {
    this.items = this.items.filter(i => i.variantId !== item.variantId);
  }
}

