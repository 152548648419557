
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import Chart from "chart.js/auto";
import { ReportModel } from "@/models/report";
import { chartColors } from "@/services/configs";

@Component({})
export default class ReportCardBarChart extends Vue {
  public chart?: any;
  @Prop({ default: false })
  public needReload!: boolean;
  @Prop({ default: [] })
  public selectedReportColumns!: any;
  public activeColumns: any = [];
  @Prop({ default: new ReportModel() })
  public report!: ReportModel;
  @Prop({ default: 0 })
  public id!: number;
  public noData: boolean = false;

  public reload() {
    this.$emit("reload-completed", "BAR_CHART");
    if (this.chart) {
        this.chart.destroy();
      }
      if (this.report.columns.length <= 1) {
        this.noData = true;
        return;
      } else {
        this.noData = false;
      }
      this.removeUnselectedColumn();
    const timeSeries: string[] = ["Day", "Week", "Month", "Quarter", "Year"];
    if (
      this.report.rows.length > 30 &&
      this.report.columns[0] && this.report.columns[0].name &&
      !timeSeries.includes(this.report.columns[0].name)
    ) {
      this.report.columns[0].values = this.report.columns[0].values.slice(
        0,
        30
      );
      const originalValues: number[] = [];
      if (this.report.columns[1]) {
        for (const index in this.report.columns[1].originalValues) {
          if (this.report.columns[1].originalValues[index]) {
            if (Number(index) < 30) {
              originalValues[index] = Number(
                this.report.columns[1].originalValues[index]
              );
              continue;
            }
          }
        }
      }

      this.report.columns[1].originalValues = originalValues.map(String);
    }
    const ctx = document.getElementById("barChartReport" + this.id);
    if (ctx instanceof HTMLCanvasElement) {
      Chart.defaults.elements.point.radius = 0;
      Chart.defaults.elements.point.hoverRadius = 5;
      const datasets = [];
      const color = chartColors;

      const multiDataSets: any = {};
      const maxLines: number = 5;
      let mainDimIdx: number = 0;
      let secondDimIdx: number = 1;
      if (
        this.report.columns.length > 1 &&
        timeSeries.includes(this.report.columns["1"].name)
      ) {
        mainDimIdx = 1;
        secondDimIdx = 0;
      }

      /** build dimensions */
      let dims: string[] = [];
      if (this.report.columns.length > 0) {
        dims = this.report.columns[mainDimIdx].values;
      }

      /** build data sets */
      let maxDataSet = 2;
      // if (this.selectedReportColumns && this.selectedReportColumns.length > 0) {
      //   maxDataSet = this.selectedReportColumns.length - 1;
      // }
      let i = 0;
      for (const key in this.report.columns) {
        if (this.report.columns[key]) {
          if (key === "0") {
            continue;
          }
          const column = this.report.columns[key];

          if (column.dataType !== "text" || key !== "1") {
            if (!this.isSelectedColumn(column.code)) {
              continue;
            }
            /** add measure to data sets */
            i++;
            if (i <= maxDataSet) {
              datasets.push({
                label: column.name,
                data: column.originalValues,
                backgroundColor: color[key],
                borderColor: color[key],
                borderWidth: 3,
              });
            } else {
              break;
            }
          } else {
            /** convert second dimension to multiple data sets */
            for (const idx in this.report.columns[secondDimIdx].values) {
              if (this.report.columns[secondDimIdx].values[idx] && this.report.columns["2"]) {
                const v =
                  this.report.columns[secondDimIdx].values[idx] +
                  " - " +
                  this.report.columns["2"].name;
                if (
                  !multiDataSets[v] &&
                  Object.keys(multiDataSets).length < maxLines
                ) {
                  const newDataSet: any = {};
                  multiDataSets[v] = newDataSet;
                }
                if (multiDataSets[v]) {
                  multiDataSets[v][
                    this.report.columns[mainDimIdx].values[idx]
                  ] = this.report.columns["2"].originalValues[idx];
                }
              }
            }
            break;
          }
        }
      }

      if (multiDataSets) {
        for (const dim of dims) {
          for (const key in multiDataSets) {
            if (!multiDataSets[key][dim]) {
              multiDataSets[key][dim] = 0;
            }
          }
        }
        let idx: number = 0;
        for (const dim in multiDataSets) {
          if (multiDataSets[dim]) {
            datasets.push({
              label: dim,
              data: Object.values(multiDataSets[dim]),
              backgroundColor: color[idx],
              borderColor: color[idx++],
              borderWidth: 3,
            });
          }
        }
      }

      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: dims,
          datasets,
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
          interaction: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }

  public isSelectedColumn(code: string) {
    if (
      !this.selectedReportColumns ||
      this.selectedReportColumns.length === 0
    ) {
      return true;
    }
    for (const c of this.selectedReportColumns) {
      if (c.code === code) {
        return true;
      }
    }
    return false;
  }

  public removeUnselectedColumn() {
    const newColumns: any = [];
    let isFirst: boolean = true;
    this.activeColumns = this.selectedReportColumns;
    if (
      !this.selectedReportColumns ||
      this.selectedReportColumns.length === 0
    ) {
      this.activeColumns = this.report.columns;
    }
    for (const column of this.activeColumns) {
      if (["image"].includes(column.code) && isFirst) {
        continue;
      }
      if (["text", "date", "datetime"].includes(column.dataType) && !isFirst) {
        continue;
      }
      isFirst = false;
      newColumns.push(this.getReportColumnData(column));
    }
    this.activeColumns = newColumns;
    this.report.columns = newColumns;
  }

  public getReportColumnData(activeColumn: any) {
    for (const item of this.report.columns) {
      if (item.code === activeColumn.code) {
        item.name = activeColumn.name;
        return item;
      }
    }
  }

  @Watch("needReload", { immediate: true, deep: true })
  private async onNeedReloadTableChanged(newVal: any, oldVal: any) {
    if (newVal === true) {
      this.reload();
    }
  }
}
