import { get, post, put, del } from '@/services/http';
import { BundleItemModel } from '@/models/bundle_item';
import { BundleItemList } from '@/collections/bundle_items';

export class BundleModel {
  public variantId: number = 0;
  public name: string = '';
  public sku: string = '';
  public bundleItems: BundleItemList = new BundleItemList();

  constructor(data: any = {}) {
    this.variantId = data.variantId;
    this.name = data.name;
    this.sku = data.sku;
    this.bundleItems = new BundleItemList();
  }

  public mapData(data: any = {}) {
    this.variantId = data.bundle_product_id;
    this.name = data.bundle_product_name;
    this.sku = data.bundle_sku;
    this.bundleItems = new BundleItemList();
    if (data.single_products) {
      this.bundleItems.mapData(data.single_products);
    }
  }

  public async save() {
    const bundleProducts: any = {
      bundle_product_id: this.variantId,
      bundle_product_name: this.name,
      bundle_sku: this.sku, single_products: [],
    };
    for (const item of this.bundleItems.items) {
      bundleProducts.single_products.push({
        single_product_id: item.variantId,
        single_product_name: item.name,
        single_sku: item.sku,
        quantity: item.quantity,
      });
    }
  }

  public addItem(item: BundleItemModel) {
    this.bundleItems.add(item);
  }

  public async fetchVariants(params = {}) {
    const res: any = await get(`/dimensions/by_variant`, params);
    return res.data || [];
  }
}
