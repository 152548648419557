
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class VideoGuides extends Vue {
  public canShowGuide: boolean = false;
  @Prop({default: []})
  public feature!: string[];
  public guides: any = [
    {
      feature: ['assisty', 'inventory'],
      title: 'Solving Inventory Problems with Assisty',
      link: 'https://app.arcade.software/share/JwjYxQ49YBHnqUDbhI85',
      thumbnail: 'inventory_problem.svg',
    },
    {
      feature: ['assisty','report_page'],
      title: 'Navigating the Report Page Structure',
      link: 'https://app.arcade.software/share/4Q1KwHWrBhgVKOdx9w5g',
      thumbnail: 'report_page_navigation.svg',
    },
    {
      feature: ['report_page'],
      title: 'How to visualize data',
      link: 'https://app.arcade.software/share/fwkLameYzsn0K5vngfuK',
      thumbnail: 'report_page_visualization.svg',
    },
    {
      feature: ['assisty', 'data_import'],
      title: 'How to Check Data Import Status',
      link: 'https://app.arcade.software/share/ht1J1nqjJjZLx1DJYTkk',
      thumbnail: 'data_import.svg',
    },
    {
      feature: ['assisty','settings'],
      title: 'How to Configure Replenishment Parameters',
      link: 'https://app.arcade.software/share/A1KnI76LyI4WyQvSCbb5',
      thumbnail: 'replenishment_setting.svg',
    },
    {
      feature: ['settings'],
      title: 'How to Configure Incoming Inventory Settings',
      link: 'https://app.arcade.software/share/3mvZQOUtCJmOC9OWzUK2',
      thumbnail: 'setting_incoming_inventory.svg',
    },
    {
      feature: ['assisty', 'inventory_replenishment'],
      title: 'How to Replenish Inventory',
      link: 'https://app.arcade.software/share/aBOFS9Seslx5FSV2meRc',
      thumbnail: 'inventory_replenishment.svg',
    },
    {
      feature: ['assisty', 'inventory_forecasting'],
      title: 'How to Forecast Demand',
      link: 'https://app.arcade.software/share/lmedvCyXcO0b8fPuvAG1',
      thumbnail: 'inventory_forecasting.svg',
    },
    {
      feature: ['assisty','schedule'],
      title: 'Report Scheduling Made Simple',
      link: 'https://app.arcade.software/share/TWSQanMkxS0cwl97kIL6',
      thumbnail: 'schedule_introduction.svg',
    },
    {
      feature: ['schedule'],
      title: 'How to scheduling a report',
      link: 'https://app.arcade.software/share/wWSPeNPU2r83CBnANmbm',
      thumbnail: 'schedule_create.svg',
    },
    {
      feature: ['schedule'],
      title: 'How to Edit an Existing Schedule',
      link: 'https://app.arcade.software/share/r3QoanaRYGIS3M3At2uO',
      thumbnail: 'schedule_edit.svg',
    },
    {
      feature: ['assisty','product_segment'],
      title: 'Navigating Product Segmentation',
      link: 'https://app.arcade.software/share/4Q1KwHWrBhgVKOdx9w5g',
      thumbnail: 'product_segment.svg',
    },
    {
      feature: ['product_segment'],
      title: 'Create Product Segment from a Report',
      link: 'https://app.arcade.software/share/H8ng2pZLWc0PypMyDF3E',
      thumbnail: 'create_product_from_report.svg',
    },
    {
      feature: ['report_page'],
      title: 'How to set up email alerts for low stock',
      link: 'https://app.arcade.software/share/LpjfaoBMuidAqIlq0XoU',
      thumbnail: 'setup_low_stock.svg',
    },
  ];
  public async created() {
    this.canShowGuide = true;
  }
  public get activeGuides() {
    // Filter the guides based on the feature prop
    // if the feature prop is not provided, return all guides
    if (this.feature.length === 0) {
      return this.guides;
    } else {
      return this.guides.filter((guide: any) => {
        return this.feature.some((feature: string) => {
          return guide.feature.includes(feature);
        });
      });
    }
  }
}
