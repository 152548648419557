
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';
import PluginConfiguration from '@/components/PluginConfiguration.vue';
import IncomingInventorySpreadSheetSettings from '@/components/IncomingInventorySpreadSheetSettings.vue';
import QuickbooksIntegration from '@/components/QuickbooksIntegration.vue';
import {post, get} from "@/services/http";
import BundleMapping from '@/components/BundleMapping.vue';
import SpreadsheetSettings from '@/components/SpreadsheetSettings.vue';

@Component({
  components: {
    SpreadsheetSettings,
    BundleMapping,
    ActionButton,
    Alert,
    GoogleDriveSelector,
    PluginConfiguration,
    IncomingInventorySpreadSheetSettings,
    QuickbooksIntegration
  },
})
export default class BundleMappingSettings extends Vue {
  @Prop({default: null})
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public useSpreadsheets: boolean = false;
  public configKey: string = 'bundle_mapping_source';

  public async created() {
    try {
      this.loading = true;

      const existingConfig = await this.getConfig();
      if (existingConfig) {
        this.useSpreadsheets = existingConfig === 'google_spreadsheets';
      } else {
        this.useSpreadsheets = this.store.config.hasOwnProperty('bundleProductUrl');
      }

      this.loading = false;
      this.success = true;
    } catch (e) {
      console.log(e);
    }
  }

  public async getConfig() {
    const res: any = await get('/users/config', {key: this.configKey});
    if (res.data && res.data.length > 0) {
      return res.data[0].value;
    }
    return null;
  }

  public async updateConfig() {
    const payload = {
      key: this.configKey,
      value: this.useSpreadsheets ? 'google_spreadsheets' : 'in_app'
    };
    await post('/users/config', payload);
  }

}
