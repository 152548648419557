
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { EventHandler } from "@/modules/events";
import { MyErrorHandler } from "@/modules/errors";
import HeaderTitle from "@/components/HeaderTitle.vue";
import TableReport from "@/components/TableReport.vue";
import BigNumberReport from "@/components/BigNumberReport.vue";
import DateFilter from "@/components/DateFilter.vue";
import GroupByDimension from "@/components/GroupByDimension.vue";
import AnalyticsTypeFilter from "@/components/AnalyticsTypeFilter.vue";
import ChatWithUs from "@/components/ChatWithUs.vue";
import { MeasureModel } from "@/models/measure";
import { DimensionModel } from "@/models/dimension";
import { AnalyticsTypeModel } from "@/models/analytics_type";
import { CustomReportModel } from "@/models/custom_report";
import { TemplateReportModel } from "@/models/template_report";
import dateFormat from "dateformat";
import { FilterDimensionList } from "@/collections/filter_dimensions";
import { StoreModel } from "@/models/store";
import { TemplateReportList } from "@/collections/template_reports";
import { MeasureList } from "@/collections/measures";
import FilterColumn from "@/components/FilterColumn.vue";
import ReportParam from "@/components/ReportParam.vue";
import { FilterColumnList } from "@/collections/filter_columns";
import { ParamList } from "@/collections/params";
import { FilterColumnModel } from "@/models/filter_column";
import { CustomReportList } from "@/collections/custom_reports";
import FeatureLockedNotify from "@/components/FeatureLockedNotify.vue";
import { RuleModel } from "@/models/rule";
import ColumnSelector from "@/components/ColumnSelector.vue";
import CardColumnSelector from "@/components/CardColumnSelector.vue";
import ReportCard from "../components/ReportCard.vue";
import { ReportCardModel } from "@/models/report_card";
import { ParamModel } from "@/models/param";
import { SearchModel } from "@/models/search";
import ErrorMessage from "@/components/ErrorMessage.vue";
import ComparisonDate from "@/components/ComparisonDate.vue";
import { ComparisonDateModel } from "@/models/comparison_date";
import { EventBus } from "@/main";
import Alert from "@/components/Alert.vue";
import ActionButton from "@/components/ActionButton.vue";
import LoadingSlider from "@/components/LoadingSlider.vue";
import { DimensionColumnList } from "@/collections/dimension_columns";
import { MetricList } from "@/collections/metrics";
import SegmentSelector from "@/components/SegmentSelector.vue";
import { ProductSegmentModel } from "@/models/product_segment";
import EtlStatusPage from "@/components/EtlStatusPage.vue";
import { DashboardList } from "@/collections/dashboards";
import { DashboardModel } from "@/models/dashboard";
import { ScheduleModel } from "@/models/schedule";
import { ScheduleProfileModel } from "@/models/schedule_profile";
import { ScheduleProfileList } from "@/collections/schedule_profiles";
import moment from "moment";
import VideoGuides from "@/components/VideoGuides.vue";
import ReportSearch from "@/components/ReportSearch.vue";
import { FunnelEventModel } from '@/models/funnelEvent';
import OnboardCompleteConfirm from "@/components/OnboardCompleteConfirm.vue";
import AppcueTrigger from '@/components/AppcueTrigger.vue';
import InteractiveGuides from '@/components/InteractiveGuides.vue';
import { init } from "mixpanel-browser";
import zlib from 'zlib';
import { Buffer } from 'buffer';

interface FilterView {
  name?: string;
  filter: FilterColumnList;
  isDefault?: boolean;
}

interface ColumnView {
  name?: string;
  reportColumns: [];
  isDefault?: boolean;
}

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

interface Range {
  start?: any;
  end?: any;
}

@Component({
  components: {
    TableReport,
    HeaderTitle,
    DateFilter,
    GroupByDimension,
    AnalyticsTypeFilter,
    ChatWithUs,
    BigNumberReport,
    FilterColumn,
    FeatureLockedNotify,
    ColumnSelector,
    ReportCard,
    ReportParam,
    ErrorMessage,
    ComparisonDate,
    Alert,
    ActionButton,
    LoadingSlider,
    SegmentSelector,
    EtlStatusPage,
    VideoGuides,
    ReportSearch,
    OnboardCompleteConfirm,
    AppcueTrigger,
    InteractiveGuides,
    CardColumnSelector,
  },
})
export default class ReportPage extends Vue {

  public get getSelectedCardColumns() {
    let cardColumns: any = [];
    if (this.selectedCardColumns && this.selectedCardColumns.length > 0) {
      cardColumns = this.selectedCardColumns;
    } else {
      cardColumns = this.savedReportColumns;
    }
    return cardColumns;
  }

  public get canSaveProductSegment() {
    const dimensions: any = ["by_product", "by_product_sku", "by_variant"];
    if (dimensions.includes(this.dimension.code)) {
      return true;
    }
    return false;
  }

  public get canCreateViewForTemplateReport() {
    if (
      this.customReport &&
      this.customReport.id &&
      this.customReport.id !== undefined
    ) {
      return true;
    }
    if (this.store.shopName !== "dariustest2.myshopify.com") {
      return false;
    }
    if (localStorage.getItem("canAddView") !== "true") {
      return false;
    }
    if (localStorage.getItem("loggedInFromHades") !== "true") {
      return false;
    }
    return true;
  }

  public get isAssistyAdmin() {
    if (this.store.shopName !== "dariustest2.myshopify.com") {
      return false;
    }
    if (localStorage.getItem("canAddView") !== "true") {
      return false;
    }
    if (localStorage.getItem("loggedInFromHades") !== "true") {
      return false;
    }
    return true;
  }

  public get activeColumnViewIndex() {
    if (!this.activeColumnView || this.activeColumnView === null) {
      return -1;
    }
    if (!this.activeColumnView.name) {
      return -1;
    }
    let index: number = -1;
    for (const columnView of this.columnViews) {
      index = index + 1;
      if (columnView.name === this.activeColumnView.name) {
        return index;
      }
    }
    return -1;
  }

  public get activeFilterViewIndex() {
    if (!this.activeFilterView || this.activeFilterView === null) {
      return -1;
    }
    if (!this.activeFilterView.name) {
      return -1;
    }
    let index: number = -1;
    for (const filterView of this.filterViews) {
      index = index + 1;
      if (filterView.name === this.activeFilterView.name) {
        return index;
      }
    }
    return -1;
  }

  public get filterReportColumns() {
    let reportColumns: any = [];
    if (this.measure.filterDimensions.size() > 0) {
      for (const dim of this.measure.filterDimensions.toArray()) {
        reportColumns.push({
          name: dim.label ? dim.label : dim.name,
          code: dim.fieldCode,
          dataType: dim.dataType,
          luisMapping: dim.code,
        });
      }
    }
    for (const column of this.initReportFilterColumns) {
      reportColumns.push(column);
    }
    reportColumns = this.refineFilterColumns(reportColumns);
    return reportColumns;
  }

  public get daysToShowInventoryOptimization() {
    const now_timestamp: number = this.toTimestamp("");
    const installed_timestamp: number = this.toTimestamp(
      this.store.installedAt
    );
    const days = Math.round(
      (now_timestamp - installed_timestamp) / (24 * 60 * 60)
    );
    return this.lockInventoryOptimizationDays - days;
  }

  public get pageTitle() {
    let title: string = "";
    if (
      this.templateReport.id &&
      this.templateReport.dimension &&
      this.dimension.code === this.templateReport.dimension.code &&
      this.templateReport.analyticsType &&
      (this.analyticsTypeCode === this.templateReport.analyticsType.code ||
        (this.analyticsTypeCode === "" &&
          this.templateReport.analyticsType.code === null))
    ) {
      title = this.templateReport.name;
    } else {
      title = this.measure.name;
    }
    return title;
  }

  public get dateRangeText() {
    return `${dateFormat(this.dates[0], "mmm d, yyyy", true)} - ${dateFormat(
      this.dates[1],
      "mmm d, yyyy",
      true
    )}`;
  }
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public measure: MeasureModel = new MeasureModel();
  public dimension: DimensionModel = new DimensionModel();
  public analyticsType: AnalyticsTypeModel = new AnalyticsTypeModel();
  public analyticsTypeCode: string = "";
  public newName: string = "";
  public readyToGetReport: boolean = false;
  public loading: boolean = false;
  public loadingSaveReport: boolean = false;
  public loadingSaveAs: boolean = false;
  public loadingRename: boolean = false;
  public loadingDelete: boolean = false;
  public dates: string[] = [];
  public defaultRange: string = "";
  public timeFilters = [
    "by_day",
    "by_week",
    "by_month",
    "by_quarter",
    "by_year",
  ];
  public customReport: CustomReportModel = new CustomReportModel();
  public newCustomReport: CustomReportModel = new CustomReportModel();
  public templateReport: TemplateReportModel = new TemplateReportModel();
  public filterDimensions: FilterDimensionList = new FilterDimensionList();
  public reportCardModel: ReportCardModel = new ReportCardModel();
  public useDefaultConfig: boolean = false;
  public dialogSaveAs: boolean = false;
  public saveAsOption: string = "new";
  public dialogRename: boolean = false;
  public dialogDelete: boolean = false;
  public dialogShareReport: boolean = false;
  public dialogSelectChartType: boolean = false;
  public linkCopied: boolean = false;
  public rule: RuleModel = new RuleModel({ code: "favorite_reports_count" });
  public rulePaidMeasure: RuleModel = new RuleModel({ code: "paid_measures" });
  public isInitFilterFromParam: boolean = false;
  public isInitReportParamFromParam: boolean = false;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: "available" })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public timeRangeLimit!: string[];
  public isLocked: string = "AVAILABLE";
  public reportFilters: FilterColumnList = new FilterColumnList();
  public filterColumns: FilterColumnList = new FilterColumnList();
  public initFilterColumns: FilterColumnList = new FilterColumnList();
  public reportParams: ParamList = new ParamList();
  public initReportParams: ParamList = new ParamList();
  public reportColumns: any = [];
  public initReportColumns: any = [];
  public initReportFilterColumns: any = [];
  public selectedReportColumns: any = [];
  public savedReportColumns: any = [];
  public isResetFilterColumns: boolean = false;
  public customReports: CustomReportList = new CustomReportList();
  public pivotOption: string = "";
  public initPivotOption: string = "";
  public reportName: string = "";
  public columnFilterMenu: boolean = false;
  public groupByDimensionMenu: boolean = false;
  public filterDateRangeMenu: boolean = false;
  public reportGuideMenu: boolean = false;
  public columnSelectorMenu: boolean = false;
  public dateRangeMenu: boolean = false;
  public showActions: boolean = true;
  public breakpoint: string = "md";
  public pageLayoutShowChart: boolean = true;
  public pageLayoutShowTable: boolean = true;
  public needRefreshTableData: boolean = false;
  public needRefreshMetricData: boolean = false;
  public dataError: MyErrorHandler = new MyErrorHandler("");
  public comparisonDateModel: ComparisonDateModel = new ComparisonDateModel();
  public initComparisonDate: ComparisonDateModel = new ComparisonDateModel();
  public isCreated: boolean = false;
  public showSetupMenu: boolean = false;
  public setupModule: string = "column";
  public chartType: string = "";
  public showChart: boolean = false;
  public hasChart: boolean = true;
  public userguides: any[] = [];
  @Prop({ default: () => new DimensionColumnList() })
  public activeDimensions!: DimensionColumnList;
  public availableParams: ParamList = new ParamList();
  public showPivot: boolean = false;
  public resourceLockedCode: string = "paid_measures";
  public filterViews: FilterView[] = [];
  public columnViews: ColumnView[] = [];
  public dialogEditFilterView: boolean = false;
  public dialogEditColumnView: boolean = false;
  public columnSelectorAction: string = "column";
  public activeFilterView: FilterView = {
    name: "",
    isDefault: false,
    filter: new FilterColumnList(),
  };
  public activeColumnView: ColumnView = {
    name: "",
    isDefault: false,
    reportColumns: [],
  };
  public initPageOptions: Option = {};
  public pageOptions: Option = {};
  public fullscreenDataTable: boolean = false;
  public calculatedMetrics: MetricList = new MetricList();
  public initCalculatedMetrics: MetricList = new MetricList();
  public isExpandPageMenu: boolean = true;
  public showSetting: boolean = true;
  public isFavoriteReport: boolean = false;
  public canAddFavorite: boolean = false;
  public productSegment: ProductSegmentModel = new ProductSegmentModel();
  public showLockInventoryOptimization: boolean = false;
  public lockInventoryOptimizationDays: number = 1;
  public dialogSaveCardToDashboard: boolean = false;
  public selectedDashboard: DashboardModel = new DashboardModel();
  public myDashboards: DashboardList = new DashboardList();
  public saveCardMessageColor: string = "";
  public saveCardMessage: string =
    "Please selelect a dashboard to save the card";
  public loadDashboardList: boolean = false;
  public saveReportToDashboardLoading: boolean = false;
  public createScheduleDialog: boolean = false;
  public selectedScheduleProfile: ScheduleProfileModel =
    new ScheduleProfileModel();
  public scheduleProfiles: ScheduleProfileList = new ScheduleProfileList();
  public isLoadingScheduleProfiles: boolean = false;
  public newScheduleTitle: string = "";
  public saveScheduleLoading: boolean = false;
  public schedule: ScheduleModel = new ScheduleModel();
  public createScheduleError: string = "";
  public searchData: FilterColumnModel = new FilterColumnModel();
  public dialogSaveOptions: boolean = false;
  public settingPages: any[] = [
    {
      measure: 'inventory_replenishment',
      title: 'Set Replenishment Params',
      url: '/settings/inventory-replenishment-parameters',
    },
    {
      measure: 'inventory_planning_date',
      title: 'Set Replenishment Params',
      url: '/settings/inventory-replenishment-parameters',
    },
    {
      measure: 'fixed_period_inventory_replenishment',
      title: 'Set Replenishment Params',
      url: '/settings/inventory-replenishment-parameters',
    },
    {
      measure: 'fixed_order_quantity_inventory_replenishment',
      title: 'Set Replenishment Params',
      url: '/settings/inventory-replenishment-parameters',
    },
    {
      measure: 'inventory_replenishment_min_max',
      title: 'Set Replenishment Params',
      url: '/settings/inventory-replenishment-parameters',
    },
    {
      measure: 'inventory_replenishment_min_max_stock_cover_days',
      title: 'Set Replenishment Params',
      url: '/settings/inventory-replenishment-parameters',
    },
  ];
  public datatableSize: string = "report-page-datatable-size-big";
  public showEditMetricColumns: boolean = false;
  public selectedCardColumns: any = [];
  public isDimensionChanged: boolean = false;
  public async created() {
    this.readyToGetReport = false;
    this.loading = true;
    this.checkIsMenuPinned();
    this.determineScreenSize();
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.rule.code) {
        this.rule = rule;
      }
      if (rule.code === this.rulePaidMeasure.code) {
        this.rulePaidMeasure = rule;
      }
    }

    await this.fullLoadData();
    if (this.isAthenaFinished) {
      try {
        const funnelEvent = new FunnelEventModel({
          name: 'VIEWED_FIRST_REPORT',
        });
        funnelEvent.create();
      } catch (error) {
        // skipped
      }
    }
    //this.loadShowChartConfig();
    document.title = this.reportName;
    const searchModel: SearchModel = new SearchModel();
    searchModel.addRecentViewReport(this.templateReport.id);
    EventBus.$on("reload-report-data", async () => {
      await this.retryLoadingData();
    });
    EventBus.$on("update-pivot-option", async (pivotOption: any) => {
      this.pivotOption = pivotOption;
      this.initPivotOption = pivotOption;
      this.columnSelectorAction = "column";
      this.needRefreshTableData = true;
    });

    EventBus.$on("close-column-selector-form", async (data: any) => {
      this.showSetupMenu = false;
    });
    EventBus.$on("pin-menu", async (isPin: any) => {
      this.pinMenu(isPin);
    });

    EventBus.$on("apply-visulization-column", async (selectedColumns: any) => {
      this.showEditMetricColumns = false;
      this.needRefreshMetricData = true;
      this.selectedCardColumns = selectedColumns;
      this.replaceReportQuery();
    });

    this.loading = false;
  }

  public pinMenu(isPin: boolean = false) {
    if (isPin) {
      this.datatableSize = "report-page-datatable-size-small";
    } else {
      this.datatableSize = "report-page-datatable-size-big";
    }
  }

  public checkIsMenuPinned() {
    //get isPinMenu from local storage
    let isPinMenu = localStorage.getItem("isPinMenu") || "false";
    if (isPinMenu === "true") {
      this.datatableSize = "report-page-datatable-size-small";
    } else {
      this.datatableSize = "report-page-datatable-size-big";
    }
  }

  public get canShowSettingButton() {
    return this.settingPages.some((page) => page.measure === this.measure.code);
  }

  public get getSettingLabel() {
    return this.settingPages.find((page) => page.measure === this.measure.code)
      ?.title;
  }

  public goToSettingPage() {
    const page = this.settingPages.find(
      (page) => page.measure === this.measure.code
    );
    if (page) {
      this.$router.push(page.url);
    }
  }

  public async showCreateScheduleDialog() {
    this.schedule = new ScheduleModel();
    this.schedule.title = this.reportName;
    this.isLoadingScheduleProfiles = true;
    this.createScheduleDialog = true;
    this.scheduleProfiles = new ScheduleProfileList();
    try {
      await this.scheduleProfiles.fetch();
      const defaultProfile = this.getDefaultScheduleProfile();
      this.scheduleProfiles.items.push(defaultProfile);
      this.isLoadingScheduleProfiles = false;
    } catch (e: any) {
      this.isLoadingScheduleProfiles = false;
      EventBus.$emit("show-snackbar", "Failed to load schedule profiles");
    }
  }

  public getDefaultScheduleProfile() {
    const defaultProfile: ScheduleProfileModel = new ScheduleProfileModel();
    defaultProfile.name = "Create new schedule profile";
    defaultProfile.outputType = "email";
    defaultProfile.destination = this.store.config.email;
    defaultProfile.fileFormat = "csv";
    defaultProfile.interval = "daily";
    defaultProfile.timezone = this.store.config.timezone;
    defaultProfile.deliveryDate = [];
    defaultProfile.deliveryWeekday = [];
    defaultProfile.deliveryHour = ["09"];
    defaultProfile.emails = [this.store.config.email];
    return defaultProfile;
  }

  public async saveCurrentSchedule() {
    this.loadingSaveReport = true;
    const scheduleData = this.getScheduleData();
    const reportIndex = Number(this.$route.params.report_index);
    this.schedule.options[reportIndex] = scheduleData;
    try {
      await this.schedule.update();
      EventBus.$emit("show-snackbar", "Schedule updated successfully");
      const id: number = this.schedule.id;
      this.schedule = new ScheduleModel();
      this.$router.push(`/schedule/${id}`);
    } catch (e: any) {
      //
      EventBus.$emit("show-snackbar", "Failed to update schedule");
    }
    this.loadingSaveReport = false;
  }

  public async createSchedule() {
    let scheduleProfile: ScheduleProfileModel = this.selectedScheduleProfile;
    if (this.scheduleProfiles.items.length === 0) {
      scheduleProfile = this.getDefaultScheduleProfile();
    }
    this.createScheduleError = "";
    if (
      !this.reportName.trim() ||
      !scheduleProfile.interval ||
      !scheduleProfile.fileFormat ||
      !scheduleProfile.outputType ||
      !scheduleProfile.destination
    ) {
      this.createScheduleError =
        "Please name your schedule and select a schedule profile";
      return false;
    }
    const scheduleData = this.getScheduleData();
    this.schedule.options = [scheduleData];
    this.schedule.outputType = scheduleProfile.outputType;
    this.schedule.destination = scheduleProfile.destination;
    this.schedule.fileFormat = scheduleProfile.fileFormat;
    this.schedule.interval = scheduleProfile.interval;
    this.schedule.timezone = scheduleProfile.timezone;
    this.schedule.deliveryDate = scheduleProfile.deliveryDate;
    this.schedule.deliveryWeekday = scheduleProfile.deliveryWeekday;
    this.schedule.deliveryHour = scheduleProfile.deliveryHour;
    this.schedule.emails = scheduleProfile.emails;
    this.schedule.timezone = scheduleProfile.timezone;
    if (scheduleProfile.receivers && scheduleProfile.receivers.items.length > 0) {
      this.schedule.receivers = scheduleProfile.receivers;
    }
    if (scheduleProfile.emailTemplate && scheduleProfile.emailTemplate.id) {
      this.schedule.emailTemplate = scheduleProfile.emailTemplate;
    }
    try {
      this.saveScheduleLoading = true;

      // check if profile is default
      if (!scheduleProfile.id) {
        localStorage.setItem(
          "schedule_to_create",
          JSON.stringify(this.schedule)
        );
        this.$router.push({ name: "scheduleCreate" });
      } else {
        await this.schedule.save();
      }
      this.saveScheduleLoading = false;
    } catch (e: any) {
      this.saveScheduleLoading = false;
    }
  }

  public getScheduleData() {
    const scheduleData: any = {};
    scheduleData.name = this.reportName;
    scheduleData.measureCode = this.measure.code;
    scheduleData.measureName = this.measure.name;
    scheduleData.dimensionCode = this.dimension.code;
    scheduleData.dimensionName = this.dimension.name;
    scheduleData.analyticsTypeCode = "";
    scheduleData.pivotOption = "";
    scheduleData.timeRange = [];
    scheduleData.filterColumns = [];
    scheduleData.reportParams = [];
    scheduleData.reportColumns = [];
    scheduleData.calculatedMetrics = [];
    scheduleData.sortBy = this.generateSortByParam(
      this.pageOptions.sortBy,
      this.pageOptions.sortDesc
    );

    if (this.analyticsType && this.analyticsType.code) {
      scheduleData.analyticsTypeCode = this.analyticsType.code;
    }
    if (this.pivotOption) {
      scheduleData.pivotOption = this.pivotOption;
    }
    if (this.measure.hasTimerange) {
      if (this.defaultRange) {
        scheduleData.timeRange = [this.defaultRange];
      } else if (this.dates.length > 2) {
        scheduleData.timeRange = [this.dates[0], this.dates[1]];
      }
    }
    if (this.filterColumns && this.filterColumns.items.length > 0) {
      scheduleData.filterColumns = this.filterColumns.items;
    }
    if (this.savedReportColumns && this.savedReportColumns.length > 0) {
      scheduleData.reportColumns = this.savedReportColumns;
    }
    if (this.reportParams && this.reportParams.items.length > 0) {
      scheduleData.reportParams = this.reportParams.items;
    }
    if (this.calculatedMetrics && this.calculatedMetrics.items.length > 0) {
      scheduleData.calculatedMetrics = this.calculatedMetrics.items;
    }
    return scheduleData;
  }

  public viewSchedule() {
    if (!this.schedule.id) {
      return;
    } else {
      this.$router.push(`/schedule/${this.schedule.id}`);
      // this.$router.push(`/schedules`);
    }
  }
  public generateSortByParam(sortBy: any = [], sortDesc: any = []) {
    if (!sortBy || sortBy.length === 0) {
      return [];
    }
    let index: number = 0;
    const sortByResult: any = [];
    for (let index = 0; index < sortBy.length; index++) {
      sortByResult.push({
        key: sortBy[index],
        order: sortDesc[index],
      });
    }

    return sortByResult;
  }

  public showCreateProductSegmentDialog() {
    EventBus.$emit("show-create-product-segment-dialog");
  }

  public async showSaveReportToDashboardDialog() {
    this.dialogSaveCardToDashboard = true;
    if (this.myDashboards.items.length === 0) {
      this.loadDashboardList = true;
      await this.myDashboards.fetch();
      this.loadDashboardList = false;
    }
  }

  public async saveReportToDashboard() {
    this.saveReportToDashboardLoading = true;
    this.saveCardMessageColor = "";
    this.saveCardMessage = "Please select a dashboard to save the card";
    const reportCard = new ReportCardModel();
    reportCard.name = this.reportName;
    if (this.hasChart && this.showChart) {
      reportCard.typeReport = this.chartType;
    } else {
      reportCard.typeReport = "TABLE";
    }
    reportCard.dimension = this.dimension;
    reportCard.measure = this.measure;
    reportCard.analyticsType = this.analyticsType;
    reportCard.filterColumns = this.filterColumns;
    reportCard.reportColumns = this.savedReportColumns;
    reportCard.reportParams = this.reportParams;
    reportCard.filterTimerange = this.dates;
    reportCard.dashboardIds = this.selectedDashboard.id;
    reportCard.productSegmentId = this.productSegment.id;
    reportCard.calculatedMetrics = this.calculatedMetrics;
    reportCard.pageOptions = this.pageOptions;
    if (this.defaultRange && this.defaultRange !== "custom") {
      reportCard.defaultRange = this.defaultRange;
    } else {
      reportCard.defaultRange = "30d";
    }
    reportCard.pivotOption = this.pivotOption;
    try {
      await this.selectedDashboard.fetch();
      await reportCard.create();
      this.selectedDashboard.reportCardList.add(reportCard);
      await this.selectedDashboard.updateCardOption();
      // this.selectedDashboard = new DashboardModel();
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track("Create Report Card from report", {
        name: reportCard.name,
        id: reportCard.id,
        measure: reportCard.measure.code,
        dimension: reportCard.dimension ? reportCard.dimension.code : "",
      });
      EventBus.$emit("show-snackbar", {
        message: "Report has been saved to dashboard",
        color: "success",
      });
    } catch (e) {
      // ignore this
      this.saveReportToDashboardLoading = false;
      this.saveCardMessageColor = "red";
      this.saveCardMessage = "There is something wrong!";
      this.dialogSaveCardToDashboard = false;
      EventBus.$emit("show-snackbar", {
        message: "There is something wrong!",
        color: "error",
      });
    }
    this.saveReportToDashboardLoading = false;
    this.dialogSaveCardToDashboard = false;
  }

  public saveFavoriteReport() {
    this.isFavoriteReport = !this.isFavoriteReport;
    let data: any = {};
    if (this.customReport && this.customReport.id) {
      data = {
        name: this.customReport.name,
        id: this.customReport.id,
        type: "customReport",
      };
    } else if (this.templateReport && this.templateReport.id) {
      data = {
        name: this.templateReport.name,
        id: this.templateReport.id,
        type: "templateReport",
      };
    }
    if (this.isFavoriteReport) {
      this.sendMixpanelEvent("Save Favorite Report");
    } else {
      this.sendMixpanelEvent("Remove Favorite Report");
    }

    this.store.saveFavoriteReport(this.isFavoriteReport, data);
    EventBus.$emit("show-snackbar", {
      message: this.isFavoriteReport
        ? "Report has been added to favorite"
        : "Report has been removed from favorite",
      color: "success",
    });
  }

  public turnFullscreenTable(isFullscreenTable: boolean = false) {
    this.fullscreenDataTable = isFullscreenTable;
  }

  public actionPivotClick() {
    this.showSetupMenu = true;
    this.columnSelectorAction = "pivot";
    EventBus.$emit("close-column-view-form");
  }

  public actionShowAddCalculatedMetricForm() {
    this.showSetupMenu = true;
    this.columnSelectorAction = "metric";
  }

  public actionManageColumnClick() {
    this.showSetupMenu = true;
    this.columnSelectorAction = "column";
    EventBus.$emit("close-column-view-form");
  }

  public openCreateColumnViewFormEvent() {
    this.showSetupMenu = true;
    this.columnSelectorAction = "view";
    EventBus.$emit("open-create-column-view-form");
  }

  public openAddMoreColumnDialogEvent() {
    EventBus.$emit("open-add-more-column-dialog-event");
  }

  public openCreateFilterviewFormEvent() {
    EventBus.$emit("open-create-filter-view-form");
  }

  public actionShowSetupColumn() {
    this.showSetupMenu = true;
    this.columnSelectorAction = "column";
    EventBus.$emit("close-column-view-form");
  }

  public actionCloseSetupMenu() {
    this.showSetupMenu = false;
    EventBus.$emit("close-column-view-form");
  }

  public expandPageMenu() {
    this.isExpandPageMenu = !this.isExpandPageMenu;
  }

  public loadDefaultView() {
    const filterViews: FilterView[] = [];
    const columnViews: ColumnView[] = [];

    for (const filter of this.filterViews) {
      if (filter) {
        filterViews.push(filter);
      }
    }
    this.filterViews = filterViews;
    for (const column of this.columnViews) {
      columnViews.push(column);
    }
    this.columnViews = columnViews;

    if (this.filterViews && this.filterViews.length > 0) {
      for (const filter of this.filterViews) {
        if (filter && filter !== null) {
          if (filter.isDefault) {
            this.loadFilterView(filter);
          }
        }
      }
    }
    if (this.columnViews && this.columnViews.length > 0) {
      for (const column of this.columnViews) {
        if (column && column !== null) {
          if (column.isDefault) {
            this.loadColumnView(column);
          }
        }
      }
    }
  }

  public prepareViewBeforeDuplicate() {
    if (this.filterViews && this.filterViews.length > 0) {
      for (const filter of this.filterViews) {
        if (filter !== null && filter) {
          if (filter.isDefault) {
            filter.isDefault = false;
          }
        }
      }
    }
    if (this.columnViews && this.columnViews.length > 0) {
      for (const column of this.columnViews) {
        if (column !== null && column) {
          if (column.isDefault) {
            column.isDefault = false;
          }
        }
      }
    }
  }

  public updateCalculatedMetric(data: any) {
    if (!data || !data.metrics || data.metrics.items.length === 0) {
      return;
    }

    this.calculatedMetrics = data.metrics;
    this.showSetupMenu = false;
    this.saveReportOptions();
  }

  public createFilterView(data: any) {
    if (!data || !data.name) {
      return;
    }
    if (!this.isRightReport(data.templateReportId, data.customReportId)) {
      return;
    }
    const newFitlerView = {
      name: data.name,
      filter: data.filters,
      isDefault: data.isDefault,
    };
    this.activeFilterView = newFitlerView;
    if (!this.filterViews) {
      this.filterViews = [];
    }
    // if is view is default then update all other view to not default
    if (data.isDefault) {
      for (const item of this.filterViews) {
        item.isDefault = false;
      }
    }
    if (data.index !== -1) {
      this.filterViews[data.index] = newFitlerView;
      this.sendMixpanelEvent("update filter view");
    } else {
      this.filterViews.push(newFitlerView);
      this.sendMixpanelEvent("create filter view");
    }
    this.filterColumns = newFitlerView.filter;
    this.saveReportOptions();
  }

  public isRightReport(
    templateReportId: number = 0,
    customReportId: number = 0
  ) {
    if (
      this.customReport &&
      this.customReport.id &&
      this.customReport.id !== undefined
    ) {
      if (this.customReport.id === customReportId) {
        return true;
      }
    } else {
      if (this.templateReport.id === templateReportId) {
        return true;
      }
    }
    return false;
  }
  public createColumnView(data: any) {
    if (!data || !data.name) {
      return;
    }
    if (!this.isRightReport(data.templateReportId, data.customReportId)) {
      return;
    }
    this.showSetupMenu = false;
    const newColumnView = {
      name: data.name,
      reportColumns: data.reportColumns,
      isDefault: data.isDefault,
    };
    this.activeColumnView = newColumnView;

    if (!this.columnViews) {
      this.columnViews = [];
    }
    // if is view is default then update all other view to not default
    if (data.isDefault) {
      for (const item of this.columnViews) {
        item.isDefault = false;
      }
    }
    if (data.index !== -1) {
      this.columnViews[data.index] = newColumnView;
      this.sendMixpanelEvent("update column view");
    } else {
      this.columnViews.push(newColumnView);
      this.sendMixpanelEvent("create column view");
    }
    this.saveReportOptions();
    this.selectedReportColumns = data.reportColumns;
    this.needRefreshTableData = true;
  }

  public saveReportOptions() {
    if (
      this.customReport &&
      this.customReport.id &&
      this.customReport.id !== undefined
    ) {
      this.customReport.filterViews = this.filterViews;
      this.customReport.columnViews = this.columnViews;
      this.customReport.calculatedMetrics = this.calculatedMetrics;
      this.customReport.updateOptions();
    } else {
      if (this.canCreateViewForTemplateReport) {
        this.updateOptions();
      }
    }
  }

  public updateOptions() {
    if (!this.templateReport.id) {
      return;
    }
    this.templateReport.filterViews = this.filterViews;
    this.templateReport.columnViews = this.columnViews;
    this.templateReport.pageOptions = this.pageOptions;
    this.templateReport.calculatedMetrics = this.calculatedMetrics;
    this.templateReport.updateOptions();
  }

  public updateReportParams() {
    if (!this.templateReport.id) {
      return;
    }
    this.templateReport.filterViews = this.filterViews;
    this.templateReport.columnViews = this.columnViews;
    this.templateReport.pageOptions = this.pageOptions;
    this.templateReport.reportParams = this.reportParams;
    this.templateReport.calculatedMetrics = this.calculatedMetrics;
    this.templateReport.updateReportParams();
  }

  public loadFilterView(filterView: FilterView) {
    this.activeFilterView = {
      name: filterView.name,
      isDefault: filterView.isDefault,
      filter: filterView.filter,
    };
    this.filterColumns = filterView.filter;
    this.initFilterColumns = filterView.filter;
    this.sendMixpanelEvent("load filter view");
  }

  public showEditFilterViewForm(filterView: FilterView) {
    this.loadFilterView(filterView);
    EventBus.$emit("open-create-filter-view-form");
    this.dialogEditFilterView = false;
  }

  public showEditColumnViewForm(columnView: ColumnView) {
    this.loadColumnView(columnView);
    EventBus.$emit("open-create-column-view-form");
    this.dialogEditColumnView = false;
  }

  public loadColumnView(columnView: ColumnView) {
    this.activeColumnView = {
      name: columnView.name,
      isDefault: columnView.isDefault,
      reportColumns: columnView.reportColumns,
    };
    if (columnView.reportColumns && columnView.reportColumns.length > 0) {
      this.selectedReportColumns = columnView.reportColumns;
    } else {
      this.selectedReportColumns = this.reportColumns;
    }
    this.sendMixpanelEvent("load column view");
    this.needRefreshTableData = true;
  }

  public deleteFilterView(index: number) {
    if (this.activeFilterView.name === this.filterViews[index].name) {
      this.activeFilterView = {
        name: "",
        isDefault: false,
        filter: new FilterColumnList(),
      };
    }
    this.filterViews.splice(index, 1);
    if (this.filterViews.length === 0) {
      this.dialogEditFilterView = false;
    }
    this.saveReportOptions();
    this.sendMixpanelEvent("delete filter view");
  }

  public updateFilterViewName() {
    this.dialogEditFilterView = false;
    this.saveReportOptions();
    this.sendMixpanelEvent("update filter view");
  }

  public deleteColumnView(index: number) {
    if (this.activeColumnView.name === this.columnViews[index].name) {
      this.activeColumnView = { name: "", isDefault: false, reportColumns: [] };
    }
    this.columnViews.splice(index, 1);
    if (this.columnViews.length === 0) {
      this.dialogEditColumnView = false;
    }
    this.saveReportOptions();
    this.sendMixpanelEvent("delete column view");
  }

  public updateColumnViewName() {
    this.dialogEditColumnView = false;
    this.saveReportOptions();
    this.sendMixpanelEvent("rename column view");
  }

  public async initActiveDimensions() {
    this.activeDimensions = new DimensionColumnList();
    await this.activeDimensions.fetch();
  }

  public async initAvailableParams() {
    if (!this.activeDimensions || this.activeDimensions.size() === 0) {
      await this.initActiveDimensions();
    }
    this.availableParams = new ParamList();
    if (this.measure.params && this.measure.params.size() > 0) {
      for (const item of this.measure.params.items) {
        this.availableParams.add(item);
      }
    }
    for (const dimension of this.activeDimensions.items) {
      if (dimension.luis_mapping === this.dimension.code) {
        if (dimension.params && dimension.params.size() > 0) {
          this.dimension.params = dimension.params;
          for (const item of dimension.params.items) {
            if (this.canAddAvailableParam(item)) {
              this.availableParams.add(item);
            }
          }
        }
      }
    }
  }

  public initParamFromDefaultValue() {
    if (!this.initReportParams || this.initReportParams.items.length === 0) {
      for (const item of this.availableParams.items) {
        if (item.value && item.value.length > 0) {
          const param = new ParamModel();
          param.NeedApiRequest = item.NeedApiRequest;
          param.code = item.code;
          param.listValues = item.listValues;
          param.name = item.name;
          param.sortOrder = item.sortOrder;
          param.value = item.value;
          param.valueType = item.valueType;
          param.dataType = item.dataType;
          param.default = item.default;
          if (this.canAddInitReportParams(param)) {
            this.initReportParams.add(param);
          }
        }
      }
      if (this.initReportParams.size() > 0) {
        this.reportParams = this.initReportParams;
      }
    }
  }

  public canAddInitReportParams(param: ParamModel) {
    for (const item of this.initReportParams.items) {
      if (item.code === param.code) {
        return false;
      }
    }
    return true;
  }

  public canAddAvailableParam(param: ParamModel) {
    for (const item of this.availableParams.items) {
      if (item.code === param.code) {
        return false;
      }
    }
    return true;
  }

  public openChat(openChatMessage: string) {
    // @ts-ignore: Unreachable code error
    this.$crisp.do("chat:open");
    // @ts-ignore: Unreachable code error
    // this.$crisp.do('message:send', ['text', this.$t(`chat_with_us.${this.defaultMessage}`)]);
    this.$crisp.do("message:send", ["text", openChatMessage]);
  }

  public turnoffChart() {
    this.showChart = false;
    if (this.showChart) {
      this.sendMixpanelEvent("turn-on chart");
    } else {
      this.sendMixpanelEvent("turn-off chart");
    }
    this.replaceReportQuery();
  }

  public turnonChart() {
    this.showChart = true;
    this.replaceReportQuery();
  }

  public changeChartType(chartType: string) {
    this.dialogSelectChartType = false;
    this.chartType = chartType;
    this.turnonChart();
    EventBus.$emit("report-card-change-chart-type", chartType);

  }

  public showExportDialog() {
    EventBus.$emit("show-download-dialog");
  }
  public showGeneratePODialog() {
    EventBus.$emit("show-generate-po-dialog");
  }

  public get canGeneratePO() {
    const repelnishemntMeasures: any = [
      'inventory_replenishment',
      'inventory_planning_date',
      'fixed_period_inventory_replenishment',
      'fixed_order_quantity_inventory_replenishment',
      'inventory_replenishment_min_max',
      'inventory_replenishment_min_max_stock_cover_days',
      'inventory_replenishment_bundles',
    ];
    //check if the current measure code is in the list
    return repelnishemntMeasures.includes(this.measure.code);
  }


  public getMenuItemStyle(hover: boolean = true) {
    let style: string = "";
    if (hover) {
      style = style + "background-color: #d4d4d4;";
    }
    if (this.isExpandPageMenu) {
      style = style + "padding: 0 16px;";
    }
    return style;
  }

  public error(error: any) {
    if (error instanceof MyErrorHandler) {
      this.dataError = error;
    } else {
      this.dataError = new MyErrorHandler("Unexpected Exception");
    }
  }

  public async retryLoadingData() {
    this.dataError = new MyErrorHandler("");
    this.needRefreshTableData = true;
    this.needRefreshMetricData = true;
    this.sendMixpanelEvent("retry loading data");
  }

  public refreshTableDataCompleted() {
    this.needRefreshTableData = false;
  }

  public refreshMetricDataCompleted() {
    this.needRefreshMetricData = false;
  }

  public needRefreshTableDataEvent() {
    this.needRefreshTableData = true;
  }
  public needRefreshMetricDataEvent() {
    this.needRefreshMetricData = true;
  }

  public changePageLayout(
    showChart: boolean = true,
    showTable: boolean = true
  ) {
    this.pageLayoutShowChart = showChart;
    this.pageLayoutShowTable = showTable;
  }
  public determineScreenSize() {
    this.breakpoint = this.$vuetify.breakpoint.name;
    if (["xs", "sm"].includes(this.breakpoint)) {
      this.showActions = false;
    }
  }

  public initReportCardModel() {
    const itemData = {
      id: 10,
      sortOrder: 10,
      typeReport: this.chartType,
      dimensionCode: this.dimension.code,
      measureCode: this.measure.code,
      analyticsTypeCode: this.analyticsType.code,
      name: this.reportName,
      defaultRange: this.defaultRange,
      isDetail: false,
      cardHeightSize: 1,
      cardWidthSize: 12,
      selectedReportColumns: this.savedReportColumns,
    };
    //check if the typeReport is LINE_CHART or BAR_CHART
    //then set isDetail to true
    if (["LINE_CHART", "BAR_CHART"].includes(itemData.typeReport)) {
      itemData.isDetail = true;
    }

    this.reportCardModel = new ReportCardModel();
    this.reportCardModel.mapData(itemData);
  }

  public showDialogSelectChartType() {
    this.dialogSelectChartType = true;
  }

  public showRenameDialog() {
    this.dialogRename = true;
  }

  public showDeleteDialog() {
    this.dialogDelete = true;
  }

  public showSaveAsDialog() {
    this.dialogSaveAs = true;
  }

  public showShareDialog() {
    this.dialogShareReport = true;
  }
  public printReport() {
    window.print();
    this.sendMixpanelEvent("print report");
  }

  public refineFilterColumns(reportColumns: any) {
    const duplicateColumns: any = {
      "Item[Item Category]": "product_collections",
      product_tag: "product_tags",
      order_tag: "order_tags",
      customer_tag: "customer_tags",
    };

    for (const column of reportColumns) {
      if (Object.keys(duplicateColumns).includes(column.code)) {
        let index: number = 0;
        for (const column2 of reportColumns) {
          if (column2.code === duplicateColumns[column.code]) {
            reportColumns.splice(index, 1);
            break;
          }
          index++;
        }
      }
    }
    return reportColumns;
  }

  public getShortReportName(reportName: string) {
    const maxLength: number = 55;
    if (!reportName || reportName === undefined) {
      return "";
    }
    if (reportName.length > maxLength) {
      return reportName.substring(0, maxLength) + "...";
    }
    //make report name to lowercase
    reportName = reportName.toLowerCase();
    //make the first character of the report name to uppercase
    reportName = reportName.charAt(0).toUpperCase() + reportName.slice(1);
    return reportName;
  }

  public getDate(date: string) {
    return dateFormat(date, "mmm d, yyyy");
  }

  public async CopyReportLink() {
    this.sendMixpanelEvent("Copy link");
    const shopify_store = this.store.shopName || "";
    const storeName = shopify_store.replace(".myshopify.com", "");
    let link = `https://admin.shopify.com/store/${storeName}/apps/assisty${this.$route.fullPath}`;
    navigator.clipboard.writeText(link);
    this.linkCopied = true;
    EventBus.$emit("show-snackbar", "Link copied to clipboard");
    await this.sleep(3000);
    this.linkCopied = false;
  }



  public operatorName(value: string) {
    const operators: any = [
      { name: "equal", value: "eq" },
      { name: "equal (case-insensitive)", value: "eqi" },
      { name: "not equal", value: "ne" },
      { name: "is lower than", value: "lt" },
      { name: "is lower than or equal to", value: "lte" },
      { name: "is greater than", value: "gt" },
      { name: "is greater than or equal to", value: "gte" },
      { name: "contains", value: "contains" },
      { name: "not contains", value: "notContains" },
      { name: "is blank", value: "null" },
      { name: "is not blank", value: "notNull" },
    ];
    for (const item of operators) {
      if (item.value === value) {
        return item.name;
      }
    }
    return value;
  }

  public filterValuesToString(values: any) {
    if (!values) {
      return "";
    }
    return values.join(" or ");
  }

  public goBack() {
    let goBackUrl: string = "/";
    if (this.$route.params.custom_report_id) {
      goBackUrl = "/my-reports";
    }

    if (this.$route.params.type_id) {
      if (this.store.currentPackage.name !== "Free") {
        goBackUrl =
          "/reports/" +
          this.$route.params.type_id +
          "/" +
          this.$route.params.type_name;
      } else {
        if (this.templateReport.isLocked === "ISLOCKED") {
          goBackUrl =
            "/premium_reports/true/" +
            this.$route.params.type_id +
            "/" +
            this.$route.params.type_name;
        } else {
          goBackUrl =
            "/reports/" +
            this.$route.params.type_id +
            "/" +
            this.$route.params.type_name;
        }
      }
    }

    if (goBackUrl !== "/") {
      this.$router.push(goBackUrl);
    } else {
      this.$router.go(-1);
    }
  }

  public async fullLoadData() {
    this.loading = true;
    this.readyToGetReport = false;
    // get report_query from params
    const reportQuery = this.$route.query.report_query || '';
    if (reportQuery && reportQuery !== undefined) {
      await this.assignReportQuery();
      await this.initAvailableParams();
    } else {
      await this.defineMeasure();
      if (this.$route.params.custom_report_id) {
        await this.loadReportFromCustomReport();
      } else if (
        this.$route.params.measure_code &&
        this.$route.params.measure_code !== undefined
      ) {
        await this.loadReportFromUrlParams();
      } else if (
        this.$route.params.template_report_id &&
        this.$route.params.template_report_id !== undefined
      ) {
        await this.loadReportFromTemplateReport();
      } else if (
        this.$route.params.schedule_id &&
        this.$route.params.schedule_id !== undefined
      ) {
        await this.loadReportFromScheduleData();
      } else if (
        this.$route.params.card_id &&
        this.$route.params.card_id !== undefined
      ) {
        await this.loadReportFromReportCard();
      } else {
        this.error(new MyErrorHandler("Invalid report"));
      }
      
    }
    
    if (!this.canViewReport()) {
      this.loading = false;
      return;
    }
    this.autoDetectTimerangeLimit();
    this.autoDetectDimension();
    await this.setDefaultTimerange();
    this.getDatesFromDefaultTimeRange(this.defaultRange);
    this.rebuildSaveAsName();
    //await this.assignReportQuery();
    this.initParamFromDefaultValue();
    this.loading = false;
    this.initReportCardModel();
    this.sendMixpanelEvent();
    if (this.customReport && this.customReport.id) {
      this.saveAsOption = 'replace';
    } else {
      this.saveAsOption = 'new';
    }
    this.readyToGetReport = true;
  }

  public autoDetectDimension() {
    if (this.measure.dimensions.size() > 0) {
      if (!this.dimension.code) {
        if (this.measure.hasTimerange) {
          for (const dimension of this.measure.dimensions.items) {
            if (
              [
                "by_day",
                "by_week",
                "by_month",
                "by_quarter",
                "by_year",
              ].includes(dimension.code)
            ) {
              this.dimension = dimension;
              break;
            }
          }
        } else {
          this.dimension = this.measure.dimensions.items[0];
        }
      }
    } else {
      this.measure.hasTimerange = false;
    }
  }

  public async loadReportFromUrlParams() {
    // get report from params
    if (
      this.$route.params.measure_code &&
      this.$route.params.measure_code !== undefined
    ) {
      if (this.$route.query.title) {
        this.reportName = this.$route.query.title + "";
      } else {
        this.reportName = this.measure.name;
      }
      if (!this.canShowInventoryOptimization(this.measure.code)) {
        this.showLockInventoryOptimization = true;
        return;
      }
      this.loadDimensionFromParam();
      this.loadDateRangeFromParam();
      this.loadAnalyticTypeFronmParam();
      this.loadFilterColumnFromParam();
      await this.initAvailableParams();
      this.loadReportParamsFromUrl();
      // get pivotOption
      this.pivotOption = this.$route.query.pivot_option + "";
      this.initPivotOption = this.pivotOption;
    }
  }

  public loadAnalyticTypeFronmParam() {
    // get analytic type
    const analyticType = this.$route.query.analytic;
    if (analyticType && analyticType !== undefined) {
      this.analyticsType = new AnalyticsTypeModel({
        id: "0",
        name: analyticType,
        code: analyticType,
      });
    }
  }
  public loadDimensionFromParam() {
    // get dimension
    const dimensionCode = this.$route.query.dimension;
    for (const dimension of this.measure.dimensions.items) {
      if (dimension.code === dimensionCode) {
        this.dimension = dimension;
        break;
      }
    }
  }
  public loadDateRangeFromParam() {
    // get timeRange Filter
    const defaultRange = this.$route.query.defaultRange;
    if (defaultRange && defaultRange !== undefined) {
      this.defaultRange = defaultRange + "";
    }
    const start = this.$route.query.start_time;
    const end = this.$route.query.end_time;
    if (start && start !== undefined) {
      this.dates.push(start + "");
      if (end && end !== undefined) {
        this.dates.push(end + "");
      }
      this.dates.push("custom");
    }
  }
  public loadFilterColumnFromParam() {
    // get filter
    const filterParam = this.$route.query.filter + "";
    const filters = filterParam.split("_col_");
    if (filters.length > 0) {
      this.filterColumns = new FilterColumnList();
      for (const filter of filters) {
        const filterCond = filter.split("__");
        if (filterCond.length < 4) {
          continue;
        }
        const itemData = {
          name: filterCond[0],
          code: filterCond[1],
          operator: filterCond[2],
          value: filterCond[3].split("_or_"),
          dataType: filterCond[4],
        };
        const filterColumn = new FilterColumnModel(itemData);
        this.filterColumns.add(filterColumn);
      }
      this.initFilterColumns = this.filterColumns;
      this.reportFilters = this.initFilterColumns;
      this.isInitFilterFromParam = true;
    }
  }

  public loadReportParamsFromUrl() {
    const paramData = this.$route.query.params + "";
    const params = paramData.split("_param_");
    if (params.length > 0) {
      const reportParams: ParamList = new ParamList();
      for (const param of params) {
        const paramAttributes = param.split("__");
        if (paramAttributes.length < 5) {
          continue;
        }
        const paramModel = new ParamModel({
          name: paramAttributes[0],
          code: paramAttributes[1],
          dataType: paramAttributes[2],
          valueType: paramAttributes[3],
          value: paramAttributes[4].split("_or_"),
        });
        reportParams.add(paramModel);
      }
      this.loadReportParamFromSavedParams(reportParams);
      this.initReportParams = this.reportParams;
      this.isInitReportParamFromParam = true;
    }
  }

  public async loadReportFromCustomReport() {
    // load data from custom report
    if (this.$route.params.custom_report_id) {
      this.reportName = this.customReport.name;
      this.loadDimensionFromCustomReport();
      this.loadAnalyticTypeFromCustomReport();
      await this.initAvailableParams();
      this.filterDimensions = this.customReport.filterDimensions;
      this.initFilterColumns = this.customReport.filterColumns;
      this.reportFilters = this.initFilterColumns;
      // this.reportParams = this.customReport.reportParams;
      this.loadReportParamFromSavedParams(this.customReport.reportParams);
      this.initReportParams = this.customReport.reportParams;
      this.filterColumns = this.customReport.filterColumns;
      this.pivotOption = this.customReport.pivotOption;
      this.initPivotOption = this.pivotOption;
      this.savedReportColumns = this.customReport.reportColumns;
      this.selectedReportColumns = this.customReport.reportColumns;
      this.pageOptions = this.customReport.pageOptions;
      this.initPageOptions = this.customReport.pageOptions;
      this.initCalculatedMetrics = this.customReport.calculatedMetrics;
      this.calculatedMetrics = this.customReport.calculatedMetrics;

      if (this.customReport.productSegmentId) {
        this.productSegment.id = this.customReport.productSegmentId;
      }
      if (this.customReport.defaultRange) {
        this.defaultRange = this.customReport.defaultRange;
      }
      if (this.customReport.filterTimerange) {
        this.dates = this.customReport.filterTimerange;
      }
      if (
        !this.customReport.filterViews ||
        this.customReport.filterViews.length === 0
      ) {
        this.filterViews = [];
      } else {
        this.filterViews = this.customReport.filterViews;
      }
      if (
        !this.customReport.columnViews ||
        this.customReport.columnViews.length === 0
      ) {
        this.columnViews = [];
      } else {
        this.columnViews = this.customReport.columnViews;
      }
    }
  }

  public get getReportName() {
    //I want to make the report name with the first letter in uppercase
    //and make all the other letters in lowercase
    if (!this.reportName || this.reportName === undefined) {
      return "";
    }
    this.reportName = this.reportName.toLowerCase();
    return this.reportName.charAt(0).toUpperCase() + this.reportName.slice(1);
  }

  public async loadReportFromScheduleData() {
    if (
      this.$route.params.schedule_id &&
      this.$route.params.schedule_id !== undefined
    ) {
      this.schedule.id = Number(this.$route.params.schedule_id);
      const reportIndex = Number(this.$route.params.report_index);
      const reportData: any = this.schedule.options[reportIndex];
      this.reportName = "Update Schedule: " + this.schedule.title;
      for (const dimension of this.measure.dimensions.items) {
        if (dimension.code === reportData.dimensionCode) {
          this.dimension = dimension;
        }
      }
      let analyticsTypeCode = "top_high";
      if (reportData.analyticsType) {
        analyticsTypeCode = reportData.analyticsTypeCode;
      }
      for (const analyticsType of this.measure.analyticsTypes.items) {
        if (analyticsType.code === analyticsTypeCode) {
          this.analyticsType = analyticsType;
          this.analyticsTypeCode = analyticsType.code;
        }
      }

      await this.initAvailableParams();
      if (reportData.reportParams && reportData.reportParams.length > 0) {
        const scheduleParams: ParamList = new ParamList();
        scheduleParams.mapData(reportData.reportParams);
        this.loadReportParamFromSavedParams(scheduleParams);
        this.initReportParams = this.reportParams;
      }

      if (reportData.filterColumns && reportData.filterColumns.length > 0) {
        const scheduleFilterColumns: FilterColumnList = new FilterColumnList();
        scheduleFilterColumns.mappData(reportData.filterColumns);
        this.filterColumns = scheduleFilterColumns;
        this.initFilterColumns = scheduleFilterColumns;
      } else {
        this.filterColumns = new FilterColumnList();
        this.initFilterColumns = new FilterColumnList();
      }
      this.reportFilters = this.initFilterColumns;
      if (reportData.pivotOption) {
        this.pivotOption = reportData.pivotOption;
        this.initPivotOption = this.pivotOption;
      }

      if (reportData.reportColumns && reportData.reportColumns.length > 0) {
        this.savedReportColumns = reportData.reportColumns;
        this.selectedReportColumns = reportData.reportColumns;
      }

      if (reportData.pageOptions) {
        this.pageOptions = reportData.pageOptions;
        this.initPageOptions = reportData.pageOptions;
      }
      if (
        reportData.calculatedMetrics &&
        reportData.calculatedMetrics.length > 0
      ) {
        this.initCalculatedMetrics = reportData.calculatedMetrics;
        this.calculatedMetrics = reportData.calculatedMetrics;
      }

      if (reportData.productSegmentId) {
        this.productSegment.id = reportData.productSegmentId;
      }
      if (reportData.timeRange && reportData.timeRange.length === 1) {
        this.defaultRange = reportData.timeRange[0];
      } else if (reportData.timeRange && reportData.timeRange.length >= 2) {
        this.defaultRange = reportData.timeRange[2];
        this.dates = reportData.timeRange;
      }
    }
  }

  public async loadReportFromReportCard() {
    if (
      this.$route.params.card_id &&
      this.$route.params.card_id !== undefined
    ) {
      this.reportName = this.reportCardModel.name;
      for (const dimension of this.measure.dimensions.items) {
        if (
          this.reportCardModel.dimension &&
          dimension.code === this.reportCardModel.dimension.code
        ) {
          this.dimension = dimension;
        }
      }
      let analyticsTypeCode = "top_high";
      if (this.reportCardModel.analyticsType && this.reportCardModel.analyticsType.code) {
        analyticsTypeCode = this.reportCardModel.analyticsType.code;
      }
      for (const analyticsType of this.measure.analyticsTypes.items) {
        if (analyticsType.code === analyticsTypeCode) {
          this.analyticsType = analyticsType;
          this.analyticsTypeCode = analyticsType.code;
        }
      }

      await this.initAvailableParams();
      if (this.reportCardModel.reportParams && this.reportCardModel.reportParams.items.length > 0) {
        const params: ParamList = new ParamList();
        this.loadReportParamFromSavedParams(this.reportCardModel.reportParams);
        this.initReportParams = this.reportParams;
      }

      if (
        this.reportCardModel.filterColumns &&
        this.reportCardModel.filterColumns.items.length > 0
      ) {
        this.filterColumns = this.reportCardModel.filterColumns;
        this.initFilterColumns = this.reportCardModel.filterColumns;
      } else {
        this.filterColumns = new FilterColumnList();
        this.initFilterColumns = new FilterColumnList();
      }
      this.reportFilters = this.initFilterColumns;
      if (this.reportCardModel.pivotOption) {
        this.pivotOption = this.reportCardModel.pivotOption;
        this.initPivotOption = this.pivotOption;
      }

      // if (reportCard.reportColumns && reportCard.reportColumns.length > 0) {
      //   this.savedReportColumns = reportCard.reportColumns;
      //   this.selectedReportColumns = reportCard.reportColumns;
      // }

      if (this.reportCardModel.pageOptions) {
        this.pageOptions = this.reportCardModel.pageOptions;
        this.initPageOptions = this.reportCardModel.pageOptions;
      }
      if (
        this.reportCardModel.calculatedMetrics &&
        this.reportCardModel.calculatedMetrics.items.length > 0
      ) {
        this.initCalculatedMetrics = this.reportCardModel.calculatedMetrics;
        this.calculatedMetrics = this.reportCardModel.calculatedMetrics;
      }

      if (this.reportCardModel.productSegmentId) {
        this.productSegment.id = this.reportCardModel.productSegmentId;
      }
      if (
        this.reportCardModel.filterTimerange &&
        this.reportCardModel.filterTimerange.length === 1
      ) {
        this.defaultRange = this.reportCardModel.filterTimerange[0];
      } else if (
        this.reportCardModel.filterTimerange &&
        this.reportCardModel.filterTimerange.length >= 2
      ) {
        this.defaultRange = this.reportCardModel.filterTimerange[2];
        this.dates = this.reportCardModel.filterTimerange;
      }
    }
  }

  public loadReportParamFromSavedParams(params: ParamList) {
    const newParams: ParamList = new ParamList();
    for (const param of params.items) {
      for (const item of this.availableParams.items) {
        if (param.code === item.code) {
          param.valueType = item.valueType;
          param.listValues = item.listValues;
          param.dataType = item.dataType;
          newParams.add(param);
        }
      }
    }
    this.reportParams = newParams;
  }

  public loadAnalyticTypeFromCustomReport() {
    if (
      this.customReport.analyticsType &&
      this.customReport.analyticsType.code
    ) {
      for (const analyticsType of this.measure.analyticsTypes.items) {
        if (analyticsType.code === this.customReport.analyticsType.code) {
          this.analyticsType = analyticsType;
          this.analyticsTypeCode = analyticsType.code;
        }
      }
    }
  }

  public loadDimensionFromCustomReport() {
    if (this.customReport.dimension) {
      for (const dimension of this.measure.dimensions.items) {
        if (dimension.code === this.customReport.dimension.code) {
          this.dimension = dimension;
        }
      }
    }
  }

  public canShowInventoryOptimization(measureCode: string) {
    const restrictMeasureList: any = [
      "sell_through_rate",
      "inventory_to_sales_ratio",
      "stock_availability",
      "inventory_turrnover",
      "aging_stock",
      "aging_stock_summary",
      "lost_revenue",
      "inventory_movement",
      "historical_inventory",
    ];
    if (!restrictMeasureList.includes(measureCode)) {
      return true;
    }
    const now_timestamp: number = this.toTimestamp("");
    const installed_timestamp: number = this.toTimestamp(
      this.store.installedAt
    );
    if (this.daysToShowInventoryOptimization <= 0) {
      return true;
    }
    return false;
  }

  public toTimestamp(strDate: string = "") {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return datum / 1000;
  }

  public async loadReportFromTemplateReport() {
    // load data from report template
    if (this.$route.params.template_report_id) {
      this.reportName = this.templateReport.name;
      this.isLocked = this.templateReport.isLocked || "available";
      if (!this.canShowInventoryOptimization(this.measure.code)) {
        this.showLockInventoryOptimization = true;
        return;
      }
      this.loadDimensionFromTemplateReport();
      this.loadAnalyticTypeFromTemplateReport();
      await this.initAvailableParams();
      if (this.templateReport.filterTimerange) {
        this.dates = this.templateReport.filterTimerange;
      }
      this.initFilterColumns = this.templateReport.filterColumns;
      this.filterColumns = this.templateReport.filterColumns;
      this.reportFilters = this.initFilterColumns;
      if (this.templateReport.pivotOption) {
        this.pivotOption = this.templateReport.pivotOption;
        this.initPivotOption = this.pivotOption;
      }
      if (this.templateReport.reportParams) {
        this.loadReportParamFromSavedParams(this.templateReport.reportParams);
      }
      this.initReportParams = this.templateReport.reportParams;
      if (
        !this.templateReport.filterViews ||
        this.templateReport.filterViews.length === 0
      ) {
        this.filterViews = [];
      } else {
        this.filterViews = this.templateReport.filterViews;
      }
      if (
        !this.templateReport.columnViews ||
        this.templateReport.columnViews.length === 0
      ) {
        this.columnViews = [];
      } else {
        this.columnViews = this.templateReport.columnViews;
      }
      this.pageOptions = this.templateReport.pageOptions;
      this.initPageOptions = this.templateReport.pageOptions;
      this.initCalculatedMetrics = this.templateReport.calculatedMetrics;
      this.calculatedMetrics = this.templateReport.calculatedMetrics;
    }
  }

  public loadAnalyticTypeFromTemplateReport() {
    if (
      this.templateReport.analyticsType &&
      this.templateReport.analyticsType.code
    ) {
      for (const analyticsType of this.measure.analyticsTypes.items) {
        if (analyticsType.code === this.templateReport.analyticsType.code) {
          this.analyticsType = analyticsType;
          this.analyticsTypeCode = analyticsType.code;
        }
      }
    }
  }

  public loadDimensionFromTemplateReport() {
    if (this.templateReport.dimension) {
      for (const dimension of this.measure.dimensions.items) {
        if (dimension.code === this.templateReport.dimension.code) {
          this.dimension = dimension;
        }
      }
    }
  }

  public sendMixpanelEvent(name: string = "") {
    const eventName = name ? name : "View report";
    let reportName = this.reportName;
    let isCustomReport: boolean = false;
    let isTempalteReport: boolean = false;
    if (!reportName) {
      reportName = this.measure.name + " - " + this.dimension.name;
    }
    if (this.$route.params.custom_report_id) {
      isCustomReport = true;
    }
    if (this.$route.params.template_report_id) {
      isTempalteReport = true;
    }
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(eventName, {
        report_name: reportName,
        dimension: this.dimension.code,
        measure: this.measure.code,
        analytic: this.analyticsType.code,
        is_template_teport: isTempalteReport,
        is_custom_report: isCustomReport,
      });
    } catch (e) {
      // ignore this catch
    }
  }

  public async defineTemplateReport() {
    await this.templateReport.fetch();
    return this.templateReport;
  }

  public async defineMeasure() {
    this.measure = new MeasureModel();
    if (this.$route.params.custom_report_id) {
      this.customReport.id = Number(this.$route.params.custom_report_id);
      await this.customReport.fetch();
      this.measure.code = this.customReport.measure.code;
    } else if (
      this.$route.params.measure_code &&
      this.$route.params.measure_code !== undefined
    ) {
      this.measure.code = this.$route.params.measure_code;
    } else if (
      this.$route.params.template_report_id &&
      this.$route.params.template_report_id !== undefined
    ) {
      this.templateReport.id = Number(this.$route.params.template_report_id);
      this.templateReport = await this.defineTemplateReport();
      this.measure.code = this.templateReport.measure.code;
    } else if (
      this.$route.params.schedule_id &&
      this.$route.params.schedule_id !== undefined
    ) {
      this.schedule.id = Number(this.$route.params.schedule_id);
      const reportIndex = Number(this.$route.params.report_index);
      await this.schedule.fetch();
      const reportData: any = this.schedule.options[reportIndex];
      this.measure.code = reportData.measureCode;
    } else if (
      this.$route.params.card_id &&
      this.$route.params.card_id !== undefined
    ) {
      this.reportCardModel = new ReportCardModel();
      this.reportCardModel.id = Number(this.$route.params.card_id);
      await this.reportCardModel.fetch();

      this.measure = this.reportCardModel.measure;
    } else {
      this.error(new MyErrorHandler("Invalid report"));
    }


    for (const measure of this.measures.items) {
      if (measure.code === this.measure.code) {
        this.measure = measure;
        return;
      }
    }
    await this.measure.fetch();
  }

  public async setDefaultTimerange() {
    if (this.defaultRange) {
      return;
    }
    if (!this.measure.hasTimerange) {
      return;
    }
    if (this.templateReport.predefinedTimeRange) {
      this.defaultRange = this.templateReport.predefinedTimeRange;
      return;
    }
    if (
      this.customReport.defaultRange &&
      this.customReport.defaultRange !== "custom"
    ) {
      this.defaultRange = this.customReport.defaultRange;
      return;
    }
    if (this.dates && this.dates.length > 2) {
      this.defaultRange = "custom";
      return;
    }
    const timeFilters = [
      "by_day",
      "by_week",
      "by_month",
      "by_quarter",
      "by_year",
    ];
    switch (this.dimension.code) {
      case "by_week":
        this.defaultRange = "30d";
        break;
      case "by_month":
        this.defaultRange = "6m";
        break;
      case "by_quarter":
        this.defaultRange = "1y";
        break;
      case "by_year":
        this.defaultRange = "3y";
        break;
      case "by_day":
        this.defaultRange = "7d";
        break;
      default:
        this.defaultRange = "30d";
        break;
    }
  }

  public async createCustomReport(keepName: boolean = true) {
    this.sendMixpanelEvent("create custom report");
    this.loadingSaveAs = true;
    if (!keepName) {
      this.newCustomReport.name = "Copy of " + this.newCustomReport.name;
    }
    this.newCustomReport.dimension = this.dimension;
    this.newCustomReport.measure = this.measure;
    this.newCustomReport.analyticsType = this.analyticsType;
    this.newCustomReport.filterDimensions = this.filterDimensions;
    this.newCustomReport.filterColumns = this.filterColumns;
    this.newCustomReport.reportParams = this.reportParams;
    this.newCustomReport.defaultRange = this.defaultRange;
    this.newCustomReport.pivotOption = this.pivotOption;
    this.newCustomReport.reportColumns = this.selectedReportColumns;
    this.newCustomReport.filterTimerange = this.dates;
    this.newCustomReport.pageOptions = this.pageOptions;
    this.newCustomReport.productSegmentId = this.productSegment.id;
    this.newCustomReport.calculatedMetrics = this.calculatedMetrics;
    this.prepareViewBeforeDuplicate();
    this.newCustomReport.filterViews = this.filterViews;
    this.newCustomReport.columnViews = this.columnViews;
    try {
      await this.newCustomReport.save();
      this.loadingSaveAs = false;
      this.dialogSaveAs = false;
      EventBus.$emit('show-snackbar', 'You have saved a new report');
      this.$router.push(`/my-reports/${this.newCustomReport.id}`);
    } catch (e: any) {
      this.loadingSaveAs = false;
      this.dialogSaveAs = false;
      EventBus.$emit('show-snackbar', { message: 'Error: ' + e.message, color: 'error'});
      //this.error(e);
    }
  }

  public async saveCustomReport() {
    this.sendMixpanelEvent("update custom report");
    this.loadingSaveReport = true;
    this.customReport.dimension = this.dimension;
    this.customReport.measure = this.measure;
    this.customReport.analyticsType = this.analyticsType;
    this.customReport.filterDimensions = this.filterDimensions;
    this.customReport.filterColumns = this.filterColumns;
    this.customReport.reportParams = this.reportParams;
    this.customReport.defaultRange = this.defaultRange;
    this.customReport.filterTimerange = this.dates;
    this.customReport.pivotOption = this.pivotOption;
    this.customReport.reportColumns = this.selectedReportColumns;
    this.customReport.filterViews = this.filterViews;
    this.customReport.columnViews = this.columnViews;
    this.customReport.pageOptions = this.pageOptions;
    this.customReport.productSegmentId = this.productSegment.id;
    this.customReport.calculatedMetrics = this.calculatedMetrics;
    try {
      await this.customReport.update();
      this.loadingSaveReport = false;
      EventBus.$emit('show-snackbar', 'Your report is saved');
    } catch (e: any) {
      this.loadingSaveReport = false;
      EventBus.$emit('show-snackbar', { message: 'Error: ' + e.message, color: 'error'});
      //this.error(e);
    }
    this.dialogSaveAs = false;
  }

  public async renameCustomReport() {
    this.sendMixpanelEvent("rename custom report");
    this.loadingRename = true;
    this.reportName = this.customReport.name;
    try {
      this.customReport.name = this.reportName;
      await this.customReport.update();
      EventBus.$emit('show-snackbar', 'Your report is renamed');
      this.loadingRename = false;
      this.dialogRename = false;
    } catch (e: any) {
      this.loadingRename = false;
      this.dialogRename = false;
      EventBus.$emit('show-snackbar', { message: 'Error: ' + e.message, color: 'error'});
      //this.error(e);
    }
  }

  public async deleteCustomReport() {
    this.sendMixpanelEvent("delete custom report");
    this.loadingDelete = true;
    try {
      await this.customReport.del();
      this.loadingDelete = false;
      EventBus.$emit('show-snackbar', 'Your report is deleted');
      this.$router.push(`/my-reports`);
    } catch (e: any) {
      this.loadingDelete = false;
      EventBus.$emit('show-snackbar', { message: 'Error: ' + e.message, color: 'error'});
      //this.error(e);
    }
  }

  public notResetColumnFilter(e: any) {
    this.isResetFilterColumns = false;
  }

  public changeDimension(dimension: DimensionModel) {
    this.dimension = dimension;
  }

  public showFilterColumnMenu() {
    this.columnFilterMenu = true;
  }

  public closeColumnFilterMenu() {
    this.columnFilterMenu = false;
  }

  public showGroupByDimensionMenu() {
    this.groupByDimensionMenu = true;
  }

  public closeGroupByDimensionMenu() {
    this.groupByDimensionMenu = false;
  }

  public showDateRangeMenu() {
    this.dateRangeMenu = true;
  }

  public showReportGuideMenu() {
    this.reportGuideMenu = true;
  }

  public closeColumnSelectorMenu() {
    this.columnSelectorMenu = false;
  }

  public showColumnSelectorMenu() {
    this.columnSelectorMenu = true;
  }

  public closeReportGuideMenu() {
    this.reportGuideMenu = false;
  }

  public closeDateRangeMenu() {
    this.dateRangeMenu = false;
  }

  public showSaveAsFavoriteReportMenu() {
    this.dialogSaveAs = true;
  }

  public canViewReport() {
    const validateValues: any = [
      {
        code: this.dimension.code,
      },
      {
        code: this.measure.code,
      },
      {
        code:
          "measure_" + this.measure.code + "_dimension_" + this.measure.code,
      },
    ];
    if (this.measure.measureTags && this.measure.measureTags.length > 0) {
      for (const tag of this.measure.measureTags) {
        if (
          tag.code === "essential_inventory_optimize" ||
          tag.code === "measure_essential_sales_analytics"
        ) {
          validateValues.push({
            code: tag.code,
            value: "unlocked",
          });
        }
      }
    }
    const validateResult = this.store.validateRule(validateValues);
    this.resourceLockedCode = validateResult.resource;
    return validateResult.isValidate;
  }

  public canCanAddCustomReport() {
    const validateValues: any = [
      {
        code: "favorite_reports_count",
        value: this.customReports.countAll + "",
      },
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }

  public dimensionChanged(dimension: DimensionModel) {
    this.savedReportColumns = [];
  }

  public updateReportOption(pageOptions: any) {
    this.pageOptions = pageOptions;
  }

  public updateReportColumn(data: any) {
    if (data.reportColumnList && data.reportColumnList.length > 0) {
      this.initReportColumns = data.reportColumnList;
      this.reportColumns = data.reportColumnList;
    } else {
      this.initReportColumns = data.reportColumns;
      this.reportColumns = data.reportColumns;
    }
    this.initReportFilterColumns = this.reportColumns;
    if (
      !this.selectedReportColumns ||
      this.selectedReportColumns.length === 0 ||
      this.isDimensionChanged
    ) {
      this.selectedReportColumns = data.reportColumns;
      this.isDimensionChanged = false;
    }
    if (this.initReportColumns && this.initReportColumns.length > 0) {
      if (!this.isCreated) {
        this.isCreated = true;
      }
    }
  }

  public saveReportHeaders(reportHeaders: any) {
    // build new list of columns
    const columns: any = [];
    for (const header of reportHeaders) {
      for (const c of this.reportColumns) {
        if (header.value === c.code) {
          const column = {
            name: header.text,
            code: c.code,
            dataType: c.dataType,
            luisMapping: c.luisMapping,
          };

          columns.push(column);
        }
      }
    }
    this.selectedReportColumns = columns;

    if (this.activeColumnViewIndex !== -1) {
      // has active Column view
      // save the edited header into the current active column view
      const newColumnView = {
        name: this.activeColumnView.name,
        isDefault: this.activeColumnView.isDefault,
        reportColumns: columns,
      };
      this.activeColumnView = newColumnView;
      this.columnViews[this.activeColumnViewIndex] = newColumnView;
      this.saveReportOptions();
    } else if (
      this.customReport &&
      this.customReport.id &&
      this.customReport.id !== undefined
    ) {
      this.customReport.reportColumns = columns;
      this.customReport.updateOptions();
    }
  }

  private sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  private rebuildSaveAsName() {
    let saveAsName = "";
    if (this.analyticsType.name) {
      saveAsName += this.analyticsType.name + " ";
    }
    saveAsName += this.measure.name;
    if (this.dimension.name) {
      saveAsName += " break-down by " + this.dimension.name;
    }
    this.newCustomReport.name = saveAsName;
  }

  private autoDetectTimerangeLimit() {
    this.timeRangeLimit = [];
    const dailyResources = [
      'locations',
      'tender_transactions',
      'customers',
      'smart_collections',
      'custom_collections',
      'collects',
      'product_metafields',
      'payouts',
      'transactions',
      'disputes',
      'variant_metafields',
      'suppliers',
      'purchase_orders',
      'stock_transfers',
      'mimoran_purchase_orders',
      'olap_historical_inventory_levels',
    ];
    if (this.measure.dataResources && this.measure.dataResources.length > 0) {
      for (const dataResource of this.measure.dataResources) {
        if (
          [
            'olap_current_inventory_levels',
            'inventory_level_quantities'
          ].includes(dataResource.code)
          && this.store.currentPackage.price === 0
        ) {
          dataResource.code = 'inventory_levels';
        }
        for (const etlStatus of this.store.etlStatuses.items) {
          if (dataResource.code === etlStatus.resource) {
            let startTime = etlStatus.startTime;
            let lastUpdatedAt = etlStatus.lastUpdatedAt;
            if (new Date(lastUpdatedAt) < new Date(this.timeRangeLimit[1])) {
              lastUpdatedAt = this.timeRangeLimit[1];
            }
            if (new Date(startTime) > new Date(this.timeRangeLimit[0])) {
              startTime = this.timeRangeLimit[0];
            }
            this.timeRangeLimit = [
              startTime,
              lastUpdatedAt,
              dailyResources.includes(etlStatus.resource) ? 'daily' : 'hourly'
            ];
          }
        }
      }
    }
  }

  private getDatesFromDefaultTimeRange(timerange: string) {
    const now = moment();
    let startDate;
    let endDate;
    switch (timerange) {
      case "today":
        startDate = now;
        endDate = now;
        break;
      case "yesterday":
        const yesterday = now.clone().subtract(1, "days");
        startDate = yesterday;
        endDate = yesterday;
        break;
      case "7d":
        startDate = now.clone().subtract(7, "days");
        endDate = now.clone().subtract(1, "days");
        break;
      case "14d":
        startDate = now.clone().subtract(14, "days");
        endDate = now.clone().subtract(1, "days");
        break;
      case "3m":
        startDate = now.clone().subtract(3, "months");
        endDate = now.clone().subtract(1, "days");
        break;
      case "6m":
        startDate = now.clone().subtract(6, "months");
        endDate = now.clone().subtract(1, "days");
        break;
      case "1y":
        startDate = now.clone().subtract(1, "years");
        endDate = now.clone().subtract(1, "days");
        break;
      case "3y":
        startDate = now.clone().subtract(3, "years");
        endDate = now.clone().subtract(1, "days");
        break;
      case "30d":
        startDate = now.clone().subtract(30, "days");
        endDate = now.clone().subtract(1, "days");
        break;
      case "week-to-date":
        startDate = now.clone().startOf("week");
        endDate = now.clone().endOf("day");
        break;
      case "month-to-date":
        startDate = now.clone().startOf("month");
        endDate = now.clone().endOf("day");
        break;
      case "year-to-date":
        startDate = now.clone().startOf("year");
        endDate = now.clone().endOf("day");
        break;
      case "last-week":
        startDate = now.clone().subtract(1, "week").startOf("week");
        endDate = now.clone().subtract(1, "week").endOf("week");
        break;
      case "last-month":
        startDate = now.clone().subtract(1, "month").startOf("month");
        endDate = now.clone().subtract(1, "month").endOf("month");
        break;
      case "last-year":
        startDate = now.clone().subtract(1, "year").startOf("year");
        endDate = now.clone().subtract(1, "year").endOf("year");
        break;
      case "custom":
        startDate = new Date(this.dates[0]);
        endDate = new Date(this.dates[1]);
        break;
    }
    const range: Range = {
      start: startDate,
      end: endDate,
    };
    if (range.start && range.end) {
      if (timerange === "custom") {
        this.dates = [
          dateFormat(range.start, "yyyy-mm-dd"),
          dateFormat(range.end, "yyyy-mm-dd"),
          timerange,
        ];
      } else {
        this.dates = [
          dateFormat(range.start, "yyyy-mm-dd"),
          dateFormat(range.end, "yyyy-mm-dd"),
          this.defaultRange,
        ];
      }
    } else {
      this.dates = [];
    }
  }

  public generateReportQuery() {
    let reportQuery: any = this.prepareReportOptions();
    //convert the reportQuery to string
    reportQuery = JSON.stringify(reportQuery);

    reportQuery = Buffer.from(reportQuery);
    //compress the reportQuery
    reportQuery = zlib.deflateSync(reportQuery);

    //encode the reportQuery using hash
    reportQuery = reportQuery.toString("base64");
    return reportQuery;
  }

  public prepareReportOptions() {
    let reportQuery: any = {};
    reportQuery.title = this.reportName;
    reportQuery.chartType = this.chartType;
    reportQuery.hasChart = this.hasChart;
    reportQuery.showChart = this.showChart;
    reportQuery.selectedCardColumns = this.getSelectedCardColumns;
    reportQuery.measure = this.measure.code;
    reportQuery.dimension = this.dimension.code;
    reportQuery.analyticsType = this.analyticsType.code;
    reportQuery.start_time = this.dates[0];
    reportQuery.end_time = this.dates[1];
    reportQuery.default_range = this.defaultRange;
    reportQuery.filter = this.filterColumns.items;
    reportQuery.pivot_option = this.pivotOption;
    reportQuery.params = this.reportParams.items;
    if (this.selectedReportColumns && this.selectedReportColumns.length > 0) {
      reportQuery.selected_report_columns = this.selectedReportColumns;
    } else {
      reportQuery.selected_report_columns = this.reportColumns;
    }
    reportQuery.page_options = this.pageOptions;
    reportQuery.product_segment_id = this.productSegment.id;
    reportQuery.calculated_metrics = this.calculatedMetrics.items;
    reportQuery.comparison_date = this.comparisonDateModel;
    return reportQuery;
  }

  public async assignReportQuery() {
    try {
      let reportQueryStr: any = this.$route.query.report_query + '';
      if (reportQueryStr && reportQueryStr !== 'undefined') {
        //decode the reportQuery using base64
        const bufferQuery = Buffer.from(reportQueryStr, 'base64');
        //decompress the reportQuery
        let reportQuery = zlib.inflateSync(bufferQuery).toString('ascii');
        reportQuery = JSON.parse(reportQuery);
        if (reportQuery.measure) {
          this.measure = new MeasureModel();
          this.measure.code = reportQuery.measure;
          for (const measure of this.measures.items) {
            if (measure.code === reportQuery.measure) {
              this.measure = measure;
            }
          }
        }
        for (const dimension of this.measure.dimensions.items) {
          if (dimension.code === reportQuery.dimension) {
            this.dimension = dimension;
          }
        }
        for (const analyticsType of this.measure.analyticsTypes.items) {
          if (analyticsType.code === reportQuery.analyticsType) {
            this.analyticsType = analyticsType;
          }
        }

        this.defaultRange = reportQuery.default_range;
        this.dates = [reportQuery.start_time, reportQuery.end_time, reportQuery.default_range];

        this.filterColumns = new FilterColumnList();
        if (reportQuery.filter && reportQuery.filter.length > 0) {
          this.filterColumns.mappData(reportQuery.filter);
        }
        this.initFilterColumns = this.filterColumns;
        this.reportFilters = this.initFilterColumns;

        if (reportQuery.pivot_option) {
          this.pivotOption = reportQuery.pivot_option;
          this.initPivotOption = this.pivotOption;
        } else {
          this.pivotOption = "";
          this.initPivotOption = "";
        }

        if (reportQuery.selected_report_columns && reportQuery.selected_report_columns.length > 0) {
          this.selectedReportColumns = reportQuery.selected_report_columns;
          this.savedReportColumns = reportQuery.selected_report_columns;
        } else {
          this.selectedReportColumns = [];
          this.savedReportColumns = [];
        }

        if (reportQuery.page_options) {
          this.pageOptions = reportQuery.page_options;
          this.initPageOptions = this.pageOptions;
        }

        if(reportQuery.product_segment_id) {
          this.productSegment.id = reportQuery.product_segment_id;
        } else {
          this.productSegment.id = 0;
        }

        this.calculatedMetrics = new MetricList();
        if (reportQuery.calculated_metrics && reportQuery.calculated_metrics.length > 0) {
          this.calculatedMetrics.items = reportQuery.calculated_metrics;
        }
        this.initCalculatedMetrics = this.calculatedMetrics;

        this.comparisonDateModel = new ComparisonDateModel();
        if (reportQuery.comparison_date) {
          this.comparisonDateModel = reportQuery.comparison_date;
        }
        this.initComparisonDate = this.comparisonDateModel;

        if (reportQuery.title) {
          this.reportName = reportQuery.title
        } else {
          this.reportName = this.measure.name + " - " + this.dimension.name;
        }
        document.title = this.reportName;

        this.reportParams = new ParamList();
        if (reportQuery.params && reportQuery.params.length > 0) {
          this.reportParams.mapData(reportQuery.params);
          this.loadReportParamFromSavedParams(this.reportParams);
          this.initReportParams = this.reportParams;
          this.isInitReportParamFromParam = true;
        } else {
          this.reportParams = new ParamList();
          this.initReportParams = this.reportParams;
        }
        if (reportQuery.chartType) {
          this.chartType = reportQuery.chartType;
        }
        if (reportQuery.hasChart) {
          this.hasChart = reportQuery.hasChart;
        }
        if (reportQuery.showChart) {
          this.showChart = reportQuery.showChart;
        }
        if (reportQuery.selectedCardColumns) {
          this.selectedCardColumns = reportQuery.selectedCardColumns;
        }
      }
    } catch(e: any) {
      // ignore this catch
    }
  }

  public isReportQueryChange() {
    const currentReportQuery = this.generateReportQuery();
    //now get the reportQuery from the url
    const reportQuery = this.$route.query.report_query;
    if (reportQuery === currentReportQuery) {
      return false;
    }
    return true;
  }

  public resetReportQuery() {
    this.dates = [];
    this.templateReport = new TemplateReportModel();
    this.customReport = new CustomReportModel();
    this.filterDimensions = new FilterDimensionList();
    this.filterColumns = new FilterColumnList();
    this.initFilterColumns = new FilterColumnList();
    this.reportFilters = this.initFilterColumns;
    this.reportParams = new ParamList();
    this.initReportParams = new ParamList();
    this.reportColumns = [];
    this.savedReportColumns = [];
    this.measure = new MeasureModel();
    this.dimension = new DimensionModel();
    this.analyticsType = new AnalyticsTypeModel();
    this.pivotOption = "";
    this.initPivotOption = "";
    this.filterViews = [];
    this.columnViews = [];
    this.activeFilterView = {
      name: "",
      isDefault: false,
      filter: new FilterColumnList(),
    };
    this.activeColumnView = { name: "", isDefault: false, reportColumns: [] };
  }

  public replaceReportQuery() {
    try {
      if (this.isReportQueryChange()) {
        this.$router.replace({
          query: {
            report_query: this.generateReportQuery(),
          },
        });
      }
    } catch(e: any) {
      // ignore this catch
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  private async onUrlChange(newVal: any) {
    if (!this.readyToGetReport) {
      return;
    }
    if (this.isReportQueryChange()) {
      this.resetReportQuery();
      await this.fullLoadData();
    }
  }

  @Watch("productSegment", { immediate: true, deep: true })
  private onProductSegmentChanged(newVal: any, oldVal: any) {
    if (!this.readyToGetReport) {
      return;
    }

    if (!oldVal && !newVal) {
      return;
    }
    this.replaceReportQuery();
  }

  @Watch("filterColumns", { immediate: true, deep: true })
  private onFilterColumnsChanged(newVal: any, oldVal: any) {
    if (!this.readyToGetReport) {
      return;
    }
    if (!oldVal && !newVal) {
      return;
    }
    this.replaceReportQuery();
  }

  @Watch("dates", { immediate: true, deep: true })
  private async onDatesChanged(newVal: any) {
    if (this.dates.length < 3) {
      return;
    }
    this.defaultRange = this.dates[2];
    if (this.readyToGetReport) {
      this.replaceReportQuery();
    }
  }

  @Watch("reportParams", { immediate: true, deep: true })
  private onReportParamsChanged(newVal: any) {
    if (this.reportParams && this.reportParams.items.length > 0 && this.readyToGetReport) {
      this.replaceReportQuery();
    }
  }

  @Watch("pageOptions", { immediate: true, deep: true })
  private onPageOptionsChanged(newVal: any) {
    if (this.pageOptions && this.pageOptions.sortBy && this.readyToGetReport) {
      this.replaceReportQuery();
    }
  }

  @Watch("pivotOption", { immediate: true, deep: true })
  private onPivotOptionChanged(newVal: any) {
    if (this.pivotOption && this.readyToGetReport) {
      this.replaceReportQuery();
    }
  }

  @Watch("comparisonDateModel", { immediate: true, deep: true })
  private onComparisonDateModelChanged(newVal: any) {
    if (this.comparisonDateModel && this.comparisonDateModel.code && this.readyToGetReport) {
      this.replaceReportQuery();
    }
  }

  @Watch("calculatedMetrics", { immediate: true, deep: true })
  private onCalculatedMetricsChanged(newVal: any) {
    if (!this.readyToGetReport) {
      return;
    }
    if (this.calculatedMetrics && this.calculatedMetrics.items.length > 0) {
      this.replaceReportQuery();
    }
  }

  @Watch("selectedReportColumns", { immediate: true, deep: true })
  private onSelectedReportColumnsChanged(newVal: any) {
    if (this.selectedReportColumns && this.selectedReportColumns.length > 0 && this.readyToGetReport) {
      const columns: any = [];
      for (const c of this.selectedReportColumns) {
        columns.push(c);
      }
      this.savedReportColumns = columns;
      this.sendMixpanelEvent("add or remove report column");
      if (this.readyToGetReport) {
        this.replaceReportQuery();
      }
    }
  }

  @Watch("dimension", { immediate: true, deep: true })
  private async onDimensionChanged(newVal: any) {
    if (this.dimension && this.dimension.code && this.readyToGetReport) {
      this.initReportParams = new ParamList();
      this.reportParams = new ParamList();
      this.selectedReportColumns = [];
      this.savedReportColumns = [];
      this.isDimensionChanged = true;
      await this.initAvailableParams();
      this.initParamFromDefaultValue();
      //this.replaceReportQuery();
    }
  }

  @Watch("reportFilters", { deep: true })
  private onReportFiltersChanged() {
    if (!this.readyToGetReport) {
      return;
    }
    this.readyToGetReport = false;
    if (this.reportFilters && this.reportFilters.items.length > 0) {
      this.filterColumns = new FilterColumnList();
      for (const filter of this.reportFilters.items) {
        this.filterColumns.add(filter);
      }
      this.readyToGetReport = true;
    } else {
      this.filterColumns = new FilterColumnList();
      this.readyToGetReport = true;
    }
  }

  @Watch("searchData", { deep: true })
  private onSearchDataChange() {
    if (!this.readyToGetReport) {
      return;
    }
    this.readyToGetReport = false;
    if (this.reportFilters && this.reportFilters.items.length > 0) {
      this.readyToGetReport = false;
      this.filterColumns = new FilterColumnList();
      for (const item of this.reportFilters.items) {
        this.filterColumns.add(item);
      }
      this.filterColumns.add(this.searchData);
      this.readyToGetReport = true;
    }
  }
}
