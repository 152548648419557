
import { Component, Watch, Prop, Vue } from "vue-property-decorator";

import ActionButton from "@/components/ActionButton.vue";
import ReplenishmentSimulator from "@/components/ReplenishmentSimulator.vue";
import { InventoryParam } from "@/models/inventory_param";
import { SupplierModel } from "@/models/supplier";
import { SupplierList } from "@/collections/suppliers";
import { EventBus } from "@/main";

@Component({
  components: {
    ActionButton,
    ReplenishmentSimulator,
  },
})
export default class ReplenishmentParam extends Vue {
  public settingDialog: boolean = false;
  @Prop({ default: false })
  public isPrimaryButton!: boolean;
  @Prop({ default: "Inventory Parameters" })
  public buttonTitle!: string;
  @Prop({ default: "mdi-cog" })
  public buttonIcon!: string;
  @Prop({ default: "small" })
  public buttonSize!: string;
  @Prop({ default: () => new InventoryParam() })
  public initInventoryParam!: InventoryParam;
  public inventoryParam: InventoryParam = new InventoryParam();
  public suppliers: SupplierList = new SupplierList();
  @Prop({ default: false })
  public saveLoading!: boolean;
  public supplierLoading: boolean = false;
  public supplier: SupplierModel = new SupplierModel();
  public supplierRule: any = [this.isSupplierValid];
  public numberRule: any = [
    (v: string) => !v || !isNaN(Number(v)) || "Value must be a number",
  ];
  public mustSelectVariantRule: any = [
    (v: any) => !v || !v.id || "Must select a variant",
  ];
  public isFormValid: boolean = false;
  public limitStockByDays: boolean = false;
  public async created() {
    this.supplier = new SupplierModel();
    this.supplier.name = "";
    this.inventoryParam = new InventoryParam();
    try {
      this.supplierLoading = true;
      await this.suppliers.fetch();
      this.supplierLoading = false;
    } catch (e) {
      this.supplierLoading = false;
    }
  }

  public isSupplierValid(v: any) {
    if (!v) {
      return "Supplier is required";
    }
    return true;
  }

  public async saveNewSupplier() {
    if (!this.supplier.name) {
      return;
    }
    let exist = false;
    for (const item of this.suppliers.items) {
      if (item.name === this.supplier.name) {
        exist = true;
        return;
      }
    }
    if (!exist) {
      const newSupplier: SupplierModel = new SupplierModel();
      newSupplier.name = this.supplier.name;
      await newSupplier.save();
      EventBus.$emit('show-snackbar', 'Supplier added successfully!');
      this.suppliers.items.push(newSupplier);
    }
  }

  public resetForm() {
    this.inventoryParam = new InventoryParam();
  }

  public async applySetting() {
    if (this.$refs.form) {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    }

    if (this.isFormValid) {
      await this.saveNewSupplier();
      for (const item of this.suppliers.items) {
        if (item.name === this.supplier.name) {
          this.inventoryParam.supplierId = item.id;
        }
      }
      this.$emit("input", this.inventoryParam);
      this.$emit("apply-setting");
      this.settingDialog = false;
    }
  }
  // Watch initInventoryParam if it changed then update inventoryParam
  @Watch("initInventoryParam")
  private onInitInventoryParamChanged() {
    if (this.initInventoryParam) {
      if (this.initInventoryParam.maxStockCoverDays || this.initInventoryParam.minStockCoverDays) {
        this.limitStockByDays = true;
      } else {
        this.limitStockByDays = false;
      }
      this.inventoryParam = this.initInventoryParam;
    }
  }
}
