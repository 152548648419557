
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import HeaderTitle from '../components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { EventBus } from '@/main';
import { FunnelEventModel } from '@/models/funnelEvent';
import InteractiveGuides from '@/components/InteractiveGuides.vue';

const toCamelCase = (text: string) => {
  const result = text.toLowerCase().replace(
    /([-_][a-z])/g,
    (group) =>
    group
      .toUpperCase()
      .replace('-', ' ')
      .replace('_', ' '),
  );
  return result.substring(0, 1).toUpperCase() + result.substring(1);
};

const datetimeToString = (datestring: Date) => {
    const dd = String(datestring.getDate()).padStart(2, '0');
    const mm = String(datestring.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = datestring.getFullYear();
    const H = String(datestring.getHours()).padStart(2, '0');
    const M = String(datestring.getMinutes()).padStart(2, '0');
    const S = String(datestring.getSeconds()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd} ${H}:${M}:${S}`;
};

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    InteractiveGuides,
  },
})
export default class EtlStatusPage extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({default: null})
  public app!: any;
  public headers: any[] = [];
  public items: any[] = [];
  public sortBy: string = 'interval';
  public sortDesc: boolean = true;
  public loading: boolean = false;
  public triggerDataDialog: boolean = false;
  public syncResource: string = 'orders';
  public resourceUpToDate: boolean = false;
  public dataUptodateDialog: boolean = false;
  public loadingImportCount: boolean = false;

  public async created() {
    this.headers = [
      {
        text: 'Resource',
        value: 'resource',
      },
      {
        text: 'Schedule',
        value: 'interval',
      },
      {
        text: 'Last Importing',
        value: 'lastUpdatedAt',
      },
      {
        text: 'Next Time Importing',
        value: 'nextTimeEtl',
      },
      {
        text: 'Imported',
        value: 'count',
      },
      {
        text: 'Status',
        value: 'isUpToDate',
      },
      {
        text: 'Sync Data',
        value: 'actions',
      },
    ];

    this.loading = true;
    await this.store.getStoreData();
    this.renderETLData();
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'VIEWED_STATUS_PAGE',
      });
      funnelEvent.create();
    } catch (error) {
      // skipped
    }
    this.loading = false;
    await this.loadETLImportedCount();
  }

  public renderETLData() {
    console.log('renderETLData');
    const dailyResources = [
      'locations',
      //'tender_transactions',
      'customers',
      'smart_collections',
      'custom_collections',
      //'collects',
      'product_metafields',
      //'payouts',
      'transactions',
      //'disputes',
      //'variant_metafields',
      //'suppliers',
      //'purchase_orders',
      //'stock_transfers',
      //'mimoran_purchase_orders',
    ];

    const nextTimeEtlMessage = 'Next time when you access Assisty!';
    this.items = [];
    const items: any = {};
    for (const item of this.store.etlStatuses.items) {

      if (item.resource === 'orders' && item.etlType === 'etl_data_period') {
        if (!Object.keys(items).includes('orders')) {
          items.orders = {
            resource: 'Orders',
          };
        }
        if (this.store.currentPackage.price > 0) {
          items[item.resource].nextTimeEtl = this.getNextTimeETL(item, 1)
        } else {
          items[item.resource].nextTimeEtl = nextTimeEtlMessage;
        }
        if (this.store.currentPackage.price > 0) {
          items[item.resource].interval = 'Hourly';
        } else {
          items[item.resource].interval = 'Paid plan only';
        }
        items.orders['count'] = `${this.store.orderCount.modelCount} / ${this.store.orderCount.apiCount}`;
        items.orders.limitStartTime = item.limitStartTime;
        items.orders.startTime = item.startTime;
      }

      if (item.resource === 'order_daily_sales_olap' && item.etlType === 'transform_request') {
        if (!Object.keys(items).includes('orders')) {
          items.orders = {
            resource: 'Orders',
          };
        }
        if (item.lastUpdatedAt) {
          items.orders.lastUpdatedAt = datetimeToString(
            new Date(item.lastUpdatedAt),
          );
        }
      }

      if (item.resource === 'products' && item.etlType === 'etl_data_period') {
        if (!Object.keys(items).includes('products')) {
          items.products = {
            resource: 'Products',
          };
        }
        if (item.lastUpdatedAt) {
          const lastUpdatedAt = new Date(item.lastUpdatedAt);
          const currentLastUpdatedAt = new Date(items.products.lastUpdatedAt);
          if (!items.products.lastUpdatedAt
            || lastUpdatedAt.getTime() > currentLastUpdatedAt.getTime()
          ) {
              items.products.lastUpdatedAt = datetimeToString(lastUpdatedAt);
          }
        }
        if (this.store.currentPackage.price > 0) {
          items[item.resource].nextTimeEtl = this.getNextTimeETL(item, 1);
        } else {
          items[item.resource].nextTimeEtl = nextTimeEtlMessage;
        }
        if (this.store.currentPackage.price > 0) {
          items[item.resource].interval = 'Hourly';
        } else {
          items[item.resource].interval = 'Paid plan only';
        }

        items.products['count'] = `${this.store.productCount.modelCount} / ${this.store.productCount.apiCount}`;
        items.products.limitStartTime = item.limitStartTime;
        items.products.startTime = item.startTime;        
      }

      if (item.resource === 'products' && item.etlType === 'transform_request') {
        if (!Object.keys(items).includes('products')) {
          items.products = {
            resource: 'Products',
          };
        }
        if (item.lastUpdatedAt) {
          const lastUpdatedAt = new Date(item.lastUpdatedAt);
          const currentLastUpdatedAt = new Date(items.products.lastUpdatedAt);
          if (!items.products.lastUpdatedAt
            || lastUpdatedAt.getTime() > currentLastUpdatedAt.getTime()
          ) {
              items.products.lastUpdatedAt = datetimeToString(lastUpdatedAt);
          }
        }
        if (this.store.currentPackage.price > 0) {
          items[item.resource].interval = 'Hourly';
        } else {
          items[item.resource].interval = 'Paid plan only';
        }
      }

      /** inventory on hand */
      let inventoryOnHandResource = 'inventory_levels'
      if (item.resource === inventoryOnHandResource && item.etlType === 'etl_data_period') {
        if (!Object.keys(items).includes('inventory_on_hand')) {
          items.inventory_on_hand = {
            resource: 'Inventory On Hand',
          };
        }
        if (item.lastUpdatedAt) {
          const lastUpdatedAt = new Date(item.lastUpdatedAt);
          const currentLastUpdatedAt = new Date(items.inventory_on_hand.lastUpdatedAt);
          if (!items.inventory_on_hand.lastUpdatedAt
            || lastUpdatedAt.getTime() > currentLastUpdatedAt.getTime()
          ) {
              items.inventory_on_hand.lastUpdatedAt = datetimeToString(lastUpdatedAt);
          }
        }
        if (this.store.currentPackage.price > 0) {
          items.inventory_on_hand.nextTimeEtl = this.getNextTimeETL(item, 1);
        } else {
          items.inventory_on_hand.nextTimeEtl = nextTimeEtlMessage;
        }
        if (this.store.currentPackage.price > 0) {
          items.inventory_on_hand.interval = 'Hourly';
        } else {
          items.inventory_on_hand.interval = 'Paid plan only';
        }

        items.inventory_on_hand['count'] = `${this.store.inventoryItemCount.modelCount} / ${this.store.inventoryItemCount.apiCount}`;
        items.inventory_on_hand.limitStartTime = item.limitStartTime;
        items.inventory_on_hand.startTime = item.startTime;          
      }

      if (this.store.currentPackage.price > 0) {
        inventoryOnHandResource = 'olap_current_inventory_levels'     
      }
      if (item.resource === inventoryOnHandResource && item.etlType === 'transform_request' ) {
        if (!Object.keys(items).includes('inventory_on_hand')) {
          items.inventory_on_hand = {
            resource: 'Inventory On Hand',
          };
        } else {
          items.inventory_on_hand.lastUpdatedAt = null;
        }
        if (item.lastUpdatedAt) {
          const lastUpdatedAt = new Date(item.lastUpdatedAt);
          const currentLastUpdatedAt = new Date(items.inventory_on_hand.lastUpdatedAt);
          if (!items.inventory_on_hand.lastUpdatedAt
            || lastUpdatedAt.getTime() > currentLastUpdatedAt.getTime()
          ) {
              items.inventory_on_hand.lastUpdatedAt = datetimeToString(lastUpdatedAt);
          }
        }
        if (this.store.currentPackage.price > 0) {
          items.inventory_on_hand.interval = 'Hourly';
        } else {
          items.inventory_on_hand.interval = 'Paid plan only';
        }
      }

      /** incoming inventory */
      if (item.resource === 'inventory_level_quantities' && item.etlType === 'transform_request') {
        if (!Object.keys(items).includes('inventory_level_quantities')) {
          items.inventory_level_quantities = {
            resource: 'Incoming Inventory',
          };
        }
        if (item.lastUpdatedAt) {
          const lastUpdatedAt = new Date(item.lastUpdatedAt);
          const currentLastUpdatedAt = new Date(items.inventory_level_quantities.lastUpdatedAt);
          if (!items.inventory_level_quantities.lastUpdatedAt
            || lastUpdatedAt.getTime() > currentLastUpdatedAt.getTime()
          ) {
              items.inventory_level_quantities.lastUpdatedAt = datetimeToString(lastUpdatedAt);
          }
          items.inventory_level_quantities.nextTimeEtl = this.getNextTimeETL(item, 24);
        }
        if (this.store.currentPackage.price > 0) {
          items[item.resource].interval = 'Daily';
        } else {
          items[item.resource].interval = 'Paid plan only';
        }
        items.inventory_level_quantities.limitStartTime = item.limitStartTime;
        items.inventory_level_quantities.startTime = item.startTime;         
      }

      /** footfall_data */
      if (item.resource === 'footfall_data' && item.etlType === 'etl_data_period') {
        if (!Object.keys(items).includes('footfall_data')) {
          items.footfall_data = {
            resource: 'Footfall Data',
          };
        }
        if (this.store.currentPackage.price > 0) {
          items[item.resource].nextTimeEtl = this.getNextTimeETL(item, 24);
        } else {
          items[item.resource].nextTimeEtl = nextTimeEtlMessage;
        }
        items.footfall_data.lastUpdatedAt = item.lastUpdatedAt;
        items[item.resource].interval = 'Hourly';
      }

      /** daily ETL data */
      if (dailyResources.includes(item.resource)) {
        if (item.etlType === 'etl_data_period') {
          if (!Object.keys(items).includes(item.resource)) {
            items[item.resource] = {
              resource: toCamelCase(item.resource),
            };
          }
          if (this.store.currentPackage.price > 0) {
            items[item.resource].interval = 'Daily';
          } else {
            items[item.resource].interval = 'Paid plan only';
          }
          if (item.lastUpdatedAt) {
            items[item.resource].lastUpdatedAt = datetimeToString(
              new Date(item.lastUpdatedAt),
            );
          }
          if (this.store.currentPackage.price > 0) {
            items[item.resource].nextTimeEtl = this.getNextTimeETL(item, 24);
          } else {
            items[item.resource].nextTimeEtl = nextTimeEtlMessage;
          }
        }
      }

      if (item.resource === 'customers' && items[item.resource]) {
        items[item.resource]['count'] = `${this.store.customerCount.modelCount} / ${this.store.customerCount.apiCount}`;              
      }
      if (item.resource === 'locations' && items[item.resource]) {
        items[item.resource]['count'] = `${this.store.locationCount.modelCount} / ${this.store.locationCount.apiCount}`;        
      }
      if (item.resource === 'custom_collections' && items[item.resource]) {
        items[item.resource]['count'] = `${this.store.customCollection.modelCount} / ${this.store.customCollection.apiCount}`;        
      }
      if (item.resource === 'smart_collections' && items[item.resource]) {
        items[item.resource]['count'] = `${this.store.smartCollection.modelCount} / ${this.store.smartCollection.apiCount}`;        
      }
      if (items[item.resource]) {
        items[item.resource].limitStartTime = item.limitStartTime;
        items[item.resource].startTime = item.startTime;         
      }

    }

    for (const key of Object.keys(items)) {
      if (items[key].lastUpdatedAt) {
        items[key].isUpToDate = this.checkUpToDateData(items[key]);
        if (!items[key].count) {
          items[key].count = '-';
        }
        items[key].resource_key = key;
        if (items[key].resource === 'Inventory On Hand' || items[key].resource === 'Incoming Inventory') {
          items[key].resource_key = 'locations';
        }
        this.items.push(items[key]);
      }
    }
  }

  public checkUpToDateData(item: any) {
    if ( !this.store.isAthenaFinished
        && item.startTime 
        && item.limitStartTime
        && (new Date(item.startTime)) > (new Date(item.limitStartTime)) ) {
      return 0;
    }
    const delayTime = item.interval === 'Hourly' ? 2 : 25;
    if((new Date((new Date(item.lastUpdatedAt)).setMinutes(0,0,0) + delayTime * 3600000)) > (new Date())) {
      return 1;
    }
    return -1;
  }

  public getNextTimeETL(item: any, period: number) {
    let nextTimeEtl = item.nextTimeEtl;
    if (!nextTimeEtl) {
      nextTimeEtl = datetimeToString(new Date((new Date(item.lastUpdatedAt)).setMinutes(0,0,0) + period*3600000));
    }
    if ((new Date(nextTimeEtl)).getTime() < (new Date()).getTime()) {
      if (period < 24) {
        nextTimeEtl = datetimeToString(new Date((new Date().setMinutes(0,0,0)) + period*3600000));
      } else {
        nextTimeEtl = datetimeToString(new Date((new Date().setHours(0,0,0,0)) + (new Date(item.lastUpdatedAt)).getHours()*3600000));
      }
      if (new Date(nextTimeEtl) < new Date()) {
        nextTimeEtl = datetimeToString(new Date((new Date().setHours(0,0,0,0)) + (new Date(item.lastUpdatedAt)).getHours()*3600000 + 24*3600000));
      }
    }
    return datetimeToString(new Date(nextTimeEtl));
  }

  public async loadETLImportedCount() {
    this.loadingImportCount = true;
    await this.store.loadEtlStatus();
    this.loadingImportCount = false;
    console.log('load etl status done');
    console.log('store', this.store.inventoryItemCount);
    this.renderETLData();
  }

  public async forceSyncResource(item: any) {
    this.syncResource = item.resource;
    this.resourceUpToDate = item.isUpToDate > 0 ? true : false;
    if (item.isUpToDate < 1) {
      this.triggerDataDialog = true;
      item.isUpToDate = 0;
      const res = await this.store.forceSyncResource(item.resource_key);
      EventBus.$emit(
        'show-snackbar',
        `The ${item.resource} data is syncing right now. This process can take a few minutes.`,
      );
    } else {
      this.dataUptodateDialog = true;
    }
  }

  public async refreshPage() {
    this.items = [];
    this.loading = true;
    await this.store.getStoreData();
    this.renderETLData();
    this.loading = false;
    this.loadETLImportedCount();
  }
}
