
import { Component, Prop, Vue } from "vue-property-decorator";
import Config from "../models/config";
import { StoreModel } from "@/models/store";
import ActionButton from "@/components/ActionButton.vue";
import { MeasureList } from "@/collections/measures";
import BaseComponent from "@/components/BaseComponent.vue";
import { EventBus } from "@/main";
import HeaderTitle from "../components/HeaderTitle.vue";
import DataSyncTimeline from "@/components/DataSyncTimeline.vue";
import AddChatChannel from "@/components/AddChatChannel.vue";
import moment from 'moment';
import { FunnelEventModel } from '@/models/funnelEvent';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    DataSyncTimeline,
    AddChatChannel,
  },
})
export default class SetupGuide extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: null })
  public app!: any;
  public isExpandPageMenu: boolean = true;
  public totalSteps: number = 6;
  public created() {
    const now = moment();
    //save the lastAskedDate to local storage
    localStorage.setItem('lastAskedDate', now.format());
  }

  public openChat() {
    let message: string = 'I need assistance with configuring the report.';
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
    // @ts-ignore: Unreachable code error
    this.$crisp.do('message:send', ['text', message]);
  }


  public expandPageMenu() {
    this.isExpandPageMenu = !this.isExpandPageMenu;
    localStorage.setItem("isExpandPageMenu", this.isExpandPageMenu + "");
  }

  public get currentStep() {
    let step = this.$route.params.step;
    let steps: any = [];
    for (let i = 1; i <= this.totalSteps; i++) {
      steps.push(i + "");
    }
    if (!steps.includes(step)) {
      step = '1';
    }
    if (step === '1') {
      const calendly = document.createElement('script');
      calendly.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      calendly.id = 'calendly-script';
      document.head.appendChild(calendly);
    } else {
      const calendly = document.getElementById('calendly-script');
      if (calendly && calendly.parentNode) {
        calendly.parentNode.removeChild(calendly);
      }
      try {
        const funnelEvent = new FunnelEventModel({
          name: 'VIEWED_ONBOARDING_GUIDE',
        });
        funnelEvent.create();
      } catch (error) {
        // skipped
      }
    }
    return step;
  }

  public openDemoStore() {
    try {
      const funnelEvent = new FunnelEventModel({
        name: 'VIEWED_DEMO_STORE',
      });
      funnelEvent.create();
    } catch (error) {
      // skipped
    }
    window.open('https://demo.assisty.ai', '_blank');
  }

  public get nextStep() {
    let step = parseInt(this.currentStep) + 1;
    if (step > this.totalSteps) {
      step = this.totalSteps;
    }
    return step;
  }

  public get previousStep() {
    let step = parseInt(this.currentStep) - 1;
    if (step < 1) {
      step = 1;
    }
    return step;
  }
  public canShowNextButton() {
    return this.currentStep !== this.totalSteps + "";
  }

  public canShowPreviousButton() {
    return this.currentStep !== "1";
  }
}
