
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { FilterColumnList } from '@/collections/filter_columns';
import { FilterColumnModel } from '@/models/filter_column';
import { FilterDimensionList } from '@/collections/filter_dimensions';
import { DimensionList } from '@/collections/dimensions';
import dateFormat from 'dateformat';
import { EventBus } from '@/main';
import ActionButton from '@/components/ActionButton.vue';

interface FilterView {
  name?: string;
  filter: FilterColumnList;
  isDefault?: boolean;
}

@Component({
  components: {
    ActionButton,
  },
})
export default class FilterColumn extends Vue {
  @Prop({ default: () => new DimensionList() })
  public dimensions!: DimensionList;
  @Prop({ default: () => new DimensionModel() })
  public dimension!: DimensionModel;
  @Prop({ default: () => new AnalyticsTypeModel() })
  public analyticsType!: AnalyticsTypeModel;
  @Prop({ default: null })
  public value!: FilterColumnList;
  @Prop({ default: () => [] })
  public reportColumns!: any;
  public columnFilterMenu: boolean = false;
  @Prop({ default: false })
  public showColumnFilterMenu!: boolean;
  public operators: any = [];
  @Prop({ default: null })
  public initFilters!: FilterColumnList;
  public filters: FilterColumnList = new FilterColumnList();
  public filterForms: any = [];
  public errorMessage: string = '';
  public filterDimensionValues: any = [];
  public filterLoading: boolean = false;
  public search: string[] = [];
  public loading: boolean[] = [];
  public currentSearchIndex: number = -1;
  public currentSearchCode: string = '';
  public isCreatingFilterView: boolean = false;
  public newFilterViewName: string = '';
  public newFilterView: any = {name: '', isDefault: false};
  @Prop({default: null})
  public activeFilterView!: FilterView;
  @Prop({default: -1})
  public activeFilterViewIndex!: number;
  public nameRules: any = [
    this.isNameExist,
  ];
  @Prop({default: 0})
  public customReportId!: number;
  @Prop({default: 0})
  public templateReportId!: number;
  @Prop({default: true})
  public showSelectStore!: boolean;
  public created() {
    this.operators = [
      { name: this.$t('filter.equal'), value: 'eq' },
      { name: this.$t('filter.not equal'), value: 'ne' },
      { name: this.$t('filter.contains'), value: 'contains' },
      { name: this.$t('filter.not contains'), value: 'notContains' },
      { name: this.$t('filter.is blank'), value: 'null' },
      { name: this.$t('filter.is not blank'), value: 'notNull' },
      { name: this.$t('filter.is lower than'), value: 'lt' },
      { name: this.$t('filter.is lower than or equal to'), value: 'lte' },
      { name: this.$t('filter.is greater than'), value: 'gt' },
      { name: this.$t('filter.is greater than or equal to'), value: 'gte' },
    ];
    this.assignInitFilter();
    EventBus.$on('add-new-filter', async (filter: any) => {
      this.addNewFilter(filter);
    });

    EventBus.$on('open-create-filter-view-form', async (filter: any) => {
      this.columnFilterMenu = true;
      this.isCreatingFilterView = true;
    });
  }

  public isNameExist(v: any) {
    return !!v || 'Name is required';
  }

  public getShortText(selectedValue: string, maxLength: number = 25) {
    if (selectedValue && selectedValue.length > maxLength) {
      return `${selectedValue.substring(0, maxLength)}...`;
    } else {
      return selectedValue;
    }
  }

  public get isAddedStoreLocation() {
    for (const item of this.filterForms) {
      if (item.filter.code === 'Location[Location]') {
        return true;
      }
    }
    return false;
  }
  public getOperatorText(value: string) {
    for (const item of this.operators) {
      if (item.value === value) {
        return item.name;
      }
    }
    return '';
  }
  public assignInitFilter() {
    if (this.initFilters && this.initFilters.items && this.initFilters.items.length > 0) {
      const filterForms: any = [];
      let data: any;
      let index = 0;
      for (const item of this.initFilters.items) {
        const values: string[] = [];
        for (const v of item.value) {
          values.push(v);
        }
        data = { name: item.name, code: item.code, value: values, operator: item.operator, dataType: item.dataType };
        filterForms.push({filter: new FilterColumnModel(data), loading: false, search: '', dimensionValues: []});
        this.loadFilterDimensionListValue('', index, item.code);
        index = index + 1;
      }
      this.filterForms = filterForms;
    }
  }

  public get filtersCount() {
    let count: number = 0;
    if (this.value && this.value.items && this.value.items.length > 0) {
      count = this.value.items.length;
      if (count > 0) {
        return ' (' + count + ' ' + this.$t('filter.actives') + ')';
      }
    }
    return '';
  }

  public deleleFilter(index: number) {
    this.filterForms.splice(index, 1);
    this.currentSearchIndex = -1;
    if (this.filterForms.length === 0) {
      this.addDefaultFilter();
    }
  }

  public deleteAddedFilter(index: number) {
    this.deleleFilter(index);
    this.applyFilter();
  }

  public clearFilter() {
    this.errorMessage = '';
    this.columnFilterMenu = false;
    const selectedFilters: FilterColumnList = new FilterColumnList();
    this.filterForms = [];
    this.currentSearchIndex = -1;
    this.currentSearchCode = '';
    this.$emit('input', selectedFilters);
    this.$emit('not-reset-filter-column', false);
    this.addDefaultFilter();
  }

  public createNewFilterView() {

    if (this.newFilterView.name) {
      EventBus.$emit('create-filter-view', {
        name: this.newFilterView.name,
        isDefault: this.newFilterView.isDefault,
        index: -1,
        filters: this.getSelectedFilter(),
        customReportId: this.customReportId,
        templateReportId: this.templateReportId,
      });
    } else {
      this.applyFilter();
    }
    this.columnFilterMenu = false;
  }

  public UpdateFilterView() {
    if (this.newFilterView.name) {
      EventBus.$emit('create-filter-view', {
        name: this.newFilterView.name,
        isDefault: this.newFilterView.isDefault ,
        index: this.activeFilterViewIndex,
        filters: this.getSelectedFilter(),
        customReportId: this.customReportId,
        templateReportId: this.templateReportId,
      });
    } else {
      this.applyFilter();
    }
    this.columnFilterMenu = false;
  }

  public addEditingFilterInputValue() {
    if (this.currentSearchIndex !== -1) {
      if (this.filterForms[this.currentSearchIndex]) {
        const newValue = this.filterForms[this.currentSearchIndex].search;
        if (newValue !== null && newValue !== '') {
          this.filterForms[this.currentSearchIndex].filter.value.push(newValue);
        }
      }
    }
  }

  public applyFilter() {
    this.addEditingFilterInputValue();
    const selectedFilters: FilterColumnList = new FilterColumnList();
    let data: any;
    for (const item of this.filterForms) {
      const filter = item.filter;
      if (filter.code && item.filter.operator) {
        if (filter.operator === 'null' || filter.operator === 'notNull' || filter.value.length > 0 ) {
          const filterName = this.getFilterColumnName(item.filter.code);
          const filterDataType = this.getFilterColumnDataType(filter.code);
          const values: string[] = [];
          for (const v of filter.value) {
            values.push(v);
          }
          data = {
            name: filterName,
            code: filter.code,
            value: values,
            operator: filter.operator,
            dataType: filterDataType,
          };
          selectedFilters.add(new FilterColumnModel(data));
        }
      }
    }

    this.$emit('input', selectedFilters);
    EventBus.$emit('reset-active-filter-view');
    this.errorMessage = '';
    this.columnFilterMenu = false;
  }

  public getSelectedFilter() {
    const selectedFilters: FilterColumnList = new FilterColumnList();
    let data: any;
    for (const item of this.filterForms) {
      const filter = item.filter;
      if (filter.code && item.filter.operator) {
        if (filter.operator === 'null' || filter.operator === 'notNull' || filter.value.length > 0 ) {
          const filterName = this.getFilterColumnName(item.filter.code);
          const filterDataType = this.getFilterColumnDataType(filter.code);
          const values: string[] = [];
          for (const v of filter.value) {
            values.push(v);
          }
          data = {
            name: filterName,
            code: filter.code,
            value: values,
            operator: filter.operator,
            dataType: filterDataType,
          };
          selectedFilters.add(new FilterColumnModel(data));
        }
      }
    }
    return selectedFilters;
  }


  public getFilterColumnName(code: string) {
    for (const column of this.reportColumns) {
      if (column.code === code) {
        return column.name;
      }
    }
    return code;
  }

  public getFilterColumnDataType(code: string) {
    for (const column of this.reportColumns) {
      if (column.code === code) {
        return column.dataType;
      }
    }
    return 'text';
  }

  public removeFilterValue(index: number , item: string) {
    const newValues = this.filterForms[index].filter.value;
    if (newValues && newValues.length > 0) {
      const itemIndex = newValues.indexOf(item);
      if (itemIndex !== -1) {
        newValues.splice(itemIndex, 1);
      }
      this.filterForms[index].filter.value = newValues;
    }
  }

  public addBlankFilter() {
    this.errorMessage = '';
    for (const item of this.filterForms) {
      if (!item.filter.code) {
        return;
      }
    }
    const blankFilter = new FilterColumnModel({ name: '', code: '', operator: 'eq', value: [], dataType: 'text' });
    this.filterForms.push({filter: blankFilter, loading: false, search: '', dimensionValues: []});
  }

  public addDefaultFilter() {
    if (this.reportColumns.length === 0) {
      return;
    }
    if (this.filterForms.length > 0) {
      return;
    }
    const defaultFilters: any =
      [
      {
        name: 'Product',
        code: 'Item[Item Description]',
        value: [],
        operator: 'eq',
        dataType: 'text',
      },
      {
        name: 'Variant',
        code:'variant',
        dataType: 'text',
        value: [],
        operator: 'eq',
      },
      {
        name: 'Collections',
        code:'product_collections',
        dataType: 'text',
        value: [],
        operator: 'eq',
      },
      {
        name: 'Product Type',
        code: 'product_type',
        dataType: 'text',
        value: [],
        operator: 'eq',
      },

      {
        name: 'Vendor',
        code:'Vendor Name',
        dataType: 'text',
        value: [],
        operator: 'eq',
      },
      {
        name : 'Store Location',
        code: 'Location[Location]',
        value: [],
        operator: 'eq',
        dataType:'text',
      },
      {
        name: 'Product Tags',
        code: 'product_tags',
        dataType:'text',
        value: [],
        operator: 'eq',
      },
      {
        name: 'SKU',
        code:'product_sku',
        dataType: 'text',
        value: [],
        operator: 'eq',
      },
    ];

    for (const data of defaultFilters) {
      let hasColumn: boolean = false;
      for (const column of this.reportColumns) {
        if (data.code === column.code) {
          hasColumn = true;
        }
      }
      if (!hasColumn) {
        continue;
      }
      let exist: boolean = false;
      if (this.initFilters && this.initFilters.items && this.initFilters.items.length > 0) {
        for (const item of this.initFilters.items) {
          if (item.code === data.code) {
            exist = true;
          }
        }
      }
      if (!exist) {
        const filter = new FilterColumnModel(data);
        this.filterForms.push({filter: filter, loading: false, search: '', dimensionValues: []});
        break;
      }
    }
    let index: number = 0;
    if (this.reportColumns && this.reportColumns.length > 0) {
      for (const item of this.filterForms) {
        this.loadFilterDimensionListValue('', index, this.filterForms[index].filter.code, true)
        index = index + 1;
      }
    }
  }

  public addNewFilter(newFilter: FilterColumnModel) {
    const count: number = this.filterForms.length;
    if (count === 0) {
      return;
    }
    const lastFilter = this.filterForms[count - 1].filter;
    if (!lastFilter.code) {
      this.filterForms = this.filterForms.splice(this.filterForms.length - 1, 0);
    }
    this.filterForms.push({filter: newFilter, loading: false, search: '', dimensionValues: []});
    this.applyFilter();
  }

  public async loadFilterDimensionListValue(search: string, index: number, code: string, resetValue: boolean = false) {
    if (!this.filterForms[index]) {
      return;
    }
    const dimension =  this.getDimensionByCode(code);
    if (!dimension || !dimension.code) {
      return;
    }
    if (resetValue) {
      this.filterForms[index].filter.value = [];
    }

    this.currentSearchCode = code;
    this.currentSearchIndex = index;

    const dimensionValues = new FilterDimensionList();
    this.filterForms[index].filter.dataType = dimension?.dataType;
    this.filterForms[index].filter.name = dimension?.name;
    this.filterForms[index].loading = true;
    this.filterForms[index].dimensionValues = [];
    if (dimension && dimension.dataType) {
      this.filterForms[index].filter.dataType = dimension.dataType;
    }
    if (dimension && dimension.code) {
      try {
        dimensionValues.dimension = this.getDimensionByCode(code);
        await dimensionValues.fetch({ keyword: search });
        const values: any = [];
        for (const item of dimensionValues.items) {
          if (item && item.name) {
            values.push({name: item.name});
          }
        }
        this.filterForms[index].dimensionValues = values;
      } catch {
        this.filterForms[index].loading = false;
      }
    }
    this.filterForms[index].loading = false;
  }

  public getDimensionByCode(code: string) {
    for (const column of this.reportColumns) {
      if (code === column.code) {
        const dimension = new DimensionModel();
        dimension.code = column.luisMapping;
        dimension.fieldCode = column.code;
        dimension.name = column.name;
        dimension.dataType = column.dataType;
        return dimension;
      }
    }
  }

  @Watch('initFilters', { immediate: true, deep: true })
  private async onInitFiltersChange(newVal: any) {
    if (this.initFilters && this.initFilters.items && this.initFilters.items.length > 0) {
      this.assignInitFilter();
    } else {
      //reset all filters
      this.filterForms = [];
      this.addDefaultFilter();
    }
  }
  @Watch('showColumnFilterMenu', { immediate: true, deep: true })
  private async onShowColumnFilterMenuChange(newVal: any) {
    if (newVal && newVal === true) {
      this.columnFilterMenu = true;
    }
  }

  @Watch('activeFilterView', { immediate: true, deep: true })
  private async onActiveFilterViewChanged(newVal: any) {
    if (this.activeFilterView && this.activeFilterView.name) {
      this.newFilterView.name = this.activeFilterView.name;
      this.newFilterView.isDefault = this.activeFilterView.isDefault;
      this.isCreatingFilterView = true;
    }
  }
  @Watch('reportColumns', { immediate: true, deep: true })
  private async onReportColumnsChanged(newVal: any) {
    if (this.reportColumns && this.reportColumns.length > 0) {
      this.addDefaultFilter();
    }
  }

}
