import { ReportCardModel } from '@/models/report_card';
import { MeasureList } from '@/collections/measures';
import dateFormat from 'dateformat';
import { ReportModel } from '@/models/report';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { DimensionModel } from '@/models/dimension';
import { MeasureModel } from '@/models/measure';
import { FilterColumnList } from '@/collections/filter_columns';
import { ParamList } from '@/collections/params';
import { ComparisonDateModel } from '@/models/comparison_date';
import { MetricList } from '@/collections/metrics';
import { ProductSegmentModel } from '@/models/product_segment';
import moment from 'moment';
import { EventBus } from '@/main';
import { StoreModel } from "@/models/store";

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

@Component
export default class ReportCardBase extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public detectTypeReport: boolean = true;
  @Prop({default: false})
  public fullWidth!: boolean;
  @Prop({default: false})
  public canChangeCardSize!: boolean;
  @Prop({default: true})
  public displayResizeAction!: boolean;
  @Prop({default: false})
  public canChangeChartType!: boolean;
  @Prop({default: false})
  public canChangeCardHeight!: boolean;
  @Prop({default: false})
  public canDoActions!: boolean;
  @Prop({default: false})
  public canMovePosition!: boolean;
  @Prop({default: false})
  public canMoveLeft!: boolean;
  @Prop({default: false})
  public canMoveRight!: boolean;
  @Prop({default: true})
  public loadDataByDefault!: boolean;
  @Prop({default: ''})
  public reportName!: string;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public description: string = '';
  @Prop({default: () => new MeasureList()})
  public measures!: MeasureList;
  @Prop({default: () => new ReportCardModel()})
  public initReportCardModel!: ReportCardModel;
  public reportCardModel: ReportCardModel = new ReportCardModel();
  public isCreated: boolean = false;
  public page: number = 1;
  public perPage: number = 0;
  public headers: any = [];
  public rows: any = [];
  @Prop({default: () => []})
  public initSelectedReportColumns!: any[];
  public selectedReportColumns: any = [];
  public cardWidthSize: number = 8;
  public cardHeightSize: number = 1;
  public typeReport: string = '';
  public defaultRange: string = '';
  @Prop({default: 3})
  public initCardWidthSize!: number;
  @Prop({default: 1})
  public initCardHeightSize!: number;
  @Prop({default: 'BIG_NUMBER'})
  public initTypeReport!: string;
  @Prop({default: '7d'})
  public initDefaultRange!: string;
  public maxCardSize: number = 12;
  public minCardSize: number = 2;
  public maxCardHeightSize: number = 6;
  public minCardHeightSize: number = 1;
  public chart?: any;
  @Prop({default: true})
  public useDefaultConfig!: boolean;
  public needReloadTable: boolean = false;
  public needReloadBarChart: boolean = false;
  public needReloadLineChart: boolean = false;
  public needReloadBigNumber: boolean = false;
  public needReloadPieChart: boolean = false;
  public needReloadDoughnutChart: boolean = false;
  public needReloadSummary: boolean = false;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  public measure: MeasureModel = new MeasureModel();
  @Prop({ default: '' })
  public measureCode!: string;
  @Prop({ default: () => new DimensionModel() })
  public dimension!: DimensionModel;
  @Prop({ default: () => new AnalyticsTypeModel() })
  public analyticsType!: AnalyticsTypeModel;
  @Prop({ default: () => new ComparisonDateModel() })
  public comparisonDate!: ComparisonDateModel;
  @Prop({ default: () => [] })
  public filterTimerange!: string[];
  public report: ReportModel = new ReportModel();
  public isLoading: boolean = false;
  public errorData: boolean = false;
  public needCount: boolean = false;
  public sortBy!: any;
  public sortDesc!: any;
  @Prop({ default: () => new FilterColumnList() })
  public filterColumns!: FilterColumnList;
  @Prop({ default: () => new ParamList() })
  public reportParams!: ParamList;
  @Prop({default: false})
  public isResetFilterColumns!: boolean;
  @Prop({default: ''})
  public pivotOption!: string;
  public id: number = 0;
  public isFixedHeight: boolean = true;
  public isDetail: boolean = false;
  public isMetric: boolean = false;
  @Prop({default: false})
  public needSaveCard!: boolean;
  public isEditingName: boolean = false;
  @Prop({default: false})
  public canChangeName!: boolean;
  public saveNameLoading: boolean = false;
  @Prop({default: false})
  public autoSaveCard!: boolean;
  @Prop({default: true})
  public autoRemoveTextColumn!: boolean;
  @Prop({default: false})
  public needReload!: boolean;
  @Prop({default: false})
  public needRefreshData!: boolean;
  @Prop({default: true})
  public isCard!: boolean;
  @Prop({default: () => new MetricList()})
  public calculatedMetrics!: MetricList;
  @Prop( {default: () => {}})
  public initPageOptions!: Option;
  public pageOptions: Option = {};
  @Prop({default: () => new ProductSegmentModel()})
  public productSegment!: ProductSegmentModel;
  public resourceLockedCode: string = "paid_measures";
  public defineMeasure() {
    for (const measure of this.measures.items) {
      if (measure.code === this.measureCode) {
        this.measure = measure;
        break;
      }
    }
  }

  public canViewReport() {
    const validateValues: any = [
      {
        code: this.dimension.code,
      },
      {
        code: this.measure.code,
      },
      {
        code:
          "measure_" + this.measure.code + "_dimension_" + this.measure.code,
      },
    ];
    if (this.measure.measureTags && this.measure.measureTags.length > 0) {
      for (const tag of this.measure.measureTags) {
        if (
          tag.code === "essential_inventory_optimize" ||
          tag.code === "measure_essential_sales_analytics"
        ) {
          validateValues.push({
            code: tag.code,
            value: "unlocked",
          });
        }
      }
    }
    const validateResult = this.store.validateRule(validateValues);
    this.resourceLockedCode = validateResult.resource;
    return validateResult.isValidate;
  }

  public async getData() {
    if (this.isLoading || !this.isCreated) {
      return;
    }
    if (!this.canViewReport()) {
      this.isLoading = false;
      return;
    }
    
    if (!this.isAthenaFinished) {
      return;
    }
    try {
      if (!this.measure || !this.measure.code || this.measure.code === undefined) {
        this.defineMeasure();
      }
      this.isLoading = true;
      this.autoDetectTypeReport();
      this.assisgnPerPage();
      this.report.measure = this.measure;
      this.report.dimension = this.dimension;
      this.report.analyticsType = this.analyticsType;
      this.report.filterTimerange = this.getFilterTimerange();
      this.report.filterColumns = this.filterColumns;
      this.report.reportParams = this.reportParams;
      this.report.reportColumns = this.initSelectedReportColumns;
      this.report.typeReport = this.typeReport;
      this.report.comparisonDate = this.comparisonDate;
      this.report.isMetric = this.isMetric;
      this.report.isDetail = this.isDetail;
      this.report.page = this.page;
      this.report.perPage = this.perPage;
      this.report.sortBy = this.sortBy;
      this.report.sortDesc = this.sortDesc;
      this.report.pivotOption = this.pivotOption;
      this.report.calculatedMetrics = this.calculatedMetrics;
      this.report.productSegment = this.productSegment;

      await this.report.getReport(!this.needRefreshData);
      if (this.needCount) {
        this.report.isMetric = false;
        this.report.isDetail = true;
        this.report.page = 1;
        this.report.page = 10;
        this.report.getCount(!this.needRefreshData);
      }
      this.autoSelecctDefaultPivotColumn();
      this.generateReportColumns();
      this.reload();
      this.isLoading = false;
      this.isCreated = true;
    } catch (error: any) {
      if (error.code !== 'ERR_CANCELED') {
        // this.$emit('error', error);
        this.errorData = true;
        this.isLoading = false;
        EventBus.$emit('show-snackbar', {message: 'Error loading data', color: 'error'});
      }
    }
    this.isLoading = false;
  }

  public autoSelecctDefaultPivotColumn() {
    if (this.initSelectedReportColumns && this.initSelectedReportColumns.length > 0) {
      this.selectedReportColumns = [];
      for (const item of this.initSelectedReportColumns) {
        this.selectedReportColumns.push(item);
      }
    }
    let hasSelectedPivot: boolean = false;
    for (const item of this.selectedReportColumns) {
      if (item.code.includes('_pivot_')) {
        hasSelectedPivot = true;
      }
    }
    if (hasSelectedPivot) {
      return;
    }
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      for (const c of this.report.columns) {
        if (c !== undefined && !c.code.includes('_pivot_')) {
          this.selectedReportColumns.push({
            name: c.name,
            code: c.code,
            dataType: c.dataType,
            luisMapping: c.luisMapping,
          });
        }
      }
    }
    // if there is no selected pivot columns then add 3 pivot columns into the selected list
    const maxPivot: number = 3;
    let addedPivot: number = 0;
    if (this.report && this.report.columns && this.report.columns.length > 0) {
      for (const c of this.report.columns) {
        if (c !== undefined && c.code.includes('_pivot_') && addedPivot < maxPivot) {
          this.selectedReportColumns.push({
            name: c.name,
            code: c.code,
            dataType: c.dataType,
            luisMapping: c.luisMapping,
          });
          addedPivot++;
        }
      }
    }
  }

  public generateReportColumns() {
    const reportColumns: any = [];
    const reportColumnList: any = [];
    if (this.report && this.report.columns && this.report.columns.length > 0) {
      for (const c of this.report.columns) {
        reportColumns.push({
          name: c.name,
          code: c.code,
          dataType: c.dataType,
          luisMapping: c.luisMapping,
        });
      }
    }
    if (this.report && this.report.columnList && this.report.columnList.length > 0) {
      for (const c of this.report.columnList) {
        reportColumnList.push({
          name: c.name,
          code: c.code,
          dataType: c.dataType,
          luisMapping: c.luisMapping,
        });
      }
    }
    this.$emit('update-report-column', {reportColumns, reportColumnList});

  }

  public assisgnPerPage() {
    this.needCount = false;
    for (const param of this.reportParams.items) {
      if (param.code === 'topn') {
        for (const value of param.value) {
          if (!isNaN(value)) {
            this.perPage = Number(value);
          } else {
            this.perPage = 0;
          }
        }
      }
    }
    switch (this.typeReport) {
      case 'TABLE': {
        if (!this.page) {
          this.page = 1;
        }
        if (!this.perPage) {
          this.perPage = 100;
        }
        this.isMetric = false;
        this.isDetail = true;
        break;
      }
      case 'SUMMARY': {
        this.page = 1;
        this.perPage = 0;
        this.isMetric = true;
        this.isDetail = false;
        this.needCount = true;
        break;
      }
      case 'LINE_CHART': {
        this.page = 1;
        if (!this.perPage) {
          this.perPage = 50;
        }
        this.isMetric = false;
        this.isDetail = false;
        break;
      }
      case 'BAR_CHART': {
        this.page = 1;
        if (!this.perPage) {
          this.perPage = 30;
        }
        this.isMetric = false;
        this.isDetail = false;
        break;
      }
      case 'BIG_NUMBER': {
        this.page = 1;
        this.perPage = 0;
        this.isMetric = true;
        this.isDetail = false;
        break;
      }
      case 'PIE_CHART': {
        this.page = 1;
        if (!this.perPage) {
          this.perPage = 7;
        }
        this.isMetric = false;
        break;
      }
      case 'DOUGHNUT_CHART': {
        this.page = 1;
        if (!this.perPage) {
          this.perPage = 7;
        }
        this.isMetric = false;
        break;
      }
    }
    
  }
  public getFilterTimerange() {
    let ranges: any = [];
    if (this.useDefaultConfig) {
      if (this.defaultRange === 'custom') { 
        ranges = this.filterTimerange;
      } else {
        ranges = this.convertTimeRange(this.defaultRange);
      }

    } else {
      for (const time of this.filterTimerange) {
        ranges.push(time);
      }
    }
    
    return ranges;
  }

  public getPastDate(date: string = '', days: number = 0) {
    const installedDate = new Date(date);
    const year = installedDate.getFullYear();
    const month = installedDate.getMonth();
    const day = installedDate.getDate() - days;
    return new Date(year, month, day).toDateString();
  }

  public toTimestamp(strDate: string = '') {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return datum / 1000;
  }

  public isGreaterDate(firstDateStr: string, secondDateStr: string) {
    if (this.toTimestamp(firstDateStr) - this.toTimestamp(secondDateStr) > 0) {
      return true;
    } else {
      return false;
    }
  }

  public convertTimeRange(timerange: string) {
    const now = moment();
    let startDate;
    let endDate;
    switch (timerange) {
      case 'today':
        startDate = now;
        endDate = now;
        break;
      case 'yesterday':
        const yesterday = now.clone().subtract(1, 'days');
        startDate = yesterday;
        endDate = yesterday;
        break;
      case '7d':
        startDate = now.clone().subtract(7, 'days');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '14d':
      startDate = now.clone().subtract(14, 'days');
      endDate = now.clone().subtract(1, 'days');
      break;
      case '3m':
        startDate = now.clone().subtract(3, 'months');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '6m':
        startDate = now.clone().subtract(6, 'months');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '1y':
        startDate = now.clone().subtract(1, 'years');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '3y':
        startDate = now.clone().subtract(3, 'years');
        endDate = now.clone().subtract(1, 'days');
        break;
      case '30d':
        startDate = now.clone().subtract(30, 'days');
        endDate = now.clone().subtract(1, 'days');
        break;
      case 'week-to-date':
        startDate = now.clone().startOf('week');
        endDate = now.clone().endOf('day');
        break;
      case 'month-to-date':
        startDate = now.clone().startOf('month');
        endDate = now.clone().endOf('day');
        break;
      case 'year-to-date':
        startDate = now.clone().startOf('year');
        endDate = now.clone().endOf('day');
        break;
      case 'last-week':
        startDate = now.clone().subtract(1, 'week').startOf('week');
        endDate = now.clone().subtract(1, 'week').endOf('week');
        break;
      case 'last-month':
        startDate = now.clone().subtract(1, 'month').startOf('month');
        endDate = now.clone().subtract(1, 'month').endOf('month');
        break;
      case 'last-year':
        startDate = now.clone().subtract(1, 'year').startOf('year');
        endDate = now.clone().subtract(1, 'year').endOf('year');
        break;
      case 'this-fiscal-year':
        const fiscalYear = this.getFiscalYearTimestamps();
        startDate = fiscalYear.start;
        endDate = fiscalYear.end;
        break;
      case 'custom':
        break;
    }
    return [
    dateFormat(startDate?.toLocaleString(), 'yyyy-mm-dd'),
        dateFormat(endDate?.toLocaleString(), 'yyyy-mm-dd'),
        this.defaultRange,
    ];
  }

  public getFiscalYearTimestamps() {
    const startDate = "2024-02-04";
    const endDate = "2025-02-01";

    // Convert the start and end dates to moment date objects
    const start = moment(startDate, 'YYYY-MM-DD');
    const end = moment(endDate, 'YYYY-MM-DD');

    // Return the start and end date objects
    return { start, end };
  }

  public processAutoSaveCard() {
    if (this.autoSaveCard) {
      this.saveCard();
    }
  }

  public changeChartType(chartType: string) {
    this.detectTypeReport = false;
    this.typeReport = chartType;
    this.currentReportCardChanged();
    this.processAutoSaveCard();
    this.$emit('card-config-changed');
  }

  public reload() {
    this.errorData = false;
    switch (this.typeReport) {
      case 'TABLE': {
        this.needReloadTable = true;
        this.needReloadBarChart = false;
        this.needReloadLineChart = false;
        this.needReloadBigNumber = false;
        this.needReloadPieChart = false;
        this.needReloadDoughnutChart = false;
        this.needReloadSummary = false;
        break;
      }
      case 'LINE_CHART': {
        this.needReloadTable = false;
        this.needReloadBarChart = false;
        this.needReloadLineChart = true;
        this.needReloadBigNumber = false;
        this.needReloadPieChart = false;
        this.needReloadDoughnutChart = false;
        this.needReloadSummary = false;
        break;
      }
      case 'BAR_CHART': {
        this.needReloadTable = false;
        this.needReloadBarChart = true;
        this.needReloadLineChart = false;
        this.needReloadBigNumber = false;
        this.needReloadPieChart = false;
        this.needReloadDoughnutChart = false;
        this.needReloadSummary = false;
        break;
      }
      case 'BIG_NUMBER': {
        this.needReloadTable = false;
        this.needReloadBarChart = false;
        this.needReloadLineChart = false;
        this.needReloadBigNumber = true;
        this.needReloadPieChart = false;
        this.needReloadDoughnutChart = false;
        this.needReloadSummary = false;
        break;
      }
      case 'PIE_CHART': {
        this.needReloadTable = false;
        this.needReloadBarChart = false;
        this.needReloadLineChart = false;
        this.needReloadBigNumber = false;
        this.needReloadPieChart = true;
        this.needReloadDoughnutChart = false;
        this.needReloadSummary = false;
        break;
      }
      case 'DOUGHNUT_CHART': {
        this.needReloadTable = false;
        this.needReloadBarChart = false;
        this.needReloadLineChart = false;
        this.needReloadBigNumber = false;
        this.needReloadPieChart = false;
        this.needReloadDoughnutChart = true;
        this.needReloadSummary = false;
        break;
      }

      case 'SUMMARY': {
        this.needReloadTable = false;
        this.needReloadBarChart = false;
        this.needReloadLineChart = false;
        this.needReloadBigNumber = false;
        this.needReloadPieChart = false;
        this.needReloadDoughnutChart = false;
        this.needReloadSummary = true;
        break;
      }
    }
    this.currentReportCardChanged();
  }

  public increaseCardWidth() {
    if (this.cardWidthSize < this.maxCardSize) {
      this.cardWidthSize = this.cardWidthSize + 1;
      this.currentReportCardChanged();
      this.processAutoSaveCard();
      this.$emit('card-config-changed');
    }
  }

  public decreaseCardWidth() {
    if (this.cardWidthSize > this.minCardSize) {
      this.cardWidthSize = this.cardWidthSize - 1;
      this.currentReportCardChanged();
      this.processAutoSaveCard();
      this.$emit('card-config-changed');
    }
  }

  public increaseCardHeight() {
    if (this.cardHeightSize < this.maxCardHeightSize) {
      this.cardHeightSize = this.cardHeightSize + 1;
      this.currentReportCardChanged();
      this.processAutoSaveCard();
      this.$emit('card-config-changed');
    }
  }

  public decreaseCardHeight() {
    if (this.cardHeightSize > this.minCardHeightSize) {
      this.cardHeightSize = this.cardHeightSize - 1;
      this.currentReportCardChanged();
      this.processAutoSaveCard();
      this.$emit('card-config-changed');
    }
  }

  public get getChangeCardWithIcon() {
    if (this.cardWidthSize < this.maxCardSize) {
      return 'mdi-plus';
    }
    return 'mdi-minus';
  }

  public get getCardSize() {
    return 'report-card-width-' + this.cardWidthSize + ' report-card-height-' + this.cardHeightSize;
  }

  public get getCardHeighResizePosition() {
    return  'report-card-height-resize-position-' + this.cardHeightSize;
  }
  public get getCardHeightSize() {
    if (this.isCard) {
      if (this.fullWidth) {
        if (this.typeReport === 'BIG_NUMBER') {
          return 'pb-15';
        } else {
          return 'report-card-height-4';
        }
      } else {
        return 'report-card-height-' + this.cardHeightSize;
      }
    } else {
      if (this.typeReport === 'BIG_NUMBER' || this.typeReport === 'SUMMARY') {
        return '';
      } else {
        return 'report-card-height-2';
      }
    }
  }

  public get getLoadingHeightSize() {
    if (this.isCard) {
      if (this.fullWidth) {
        if (this.typeReport === 'BIG_NUMBER') {
          return 'pb-15';
        } else {
          return 'report-card-loading-height-4';
        }
      } else {
        return 'report-card-loading-height-' + this.cardHeightSize;
      }
    } else {
      if (this.typeReport === 'BIG_NUMBER') {
        return '';
      } else {
        return 'report-card-loading-height-4';
      }
    }
  }

  public isSelectedColumn(code: string) {

    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      return true;
    }
    for (const c of this.selectedReportColumns) {
      if (c.code === code) {
        return true;
      }
    }
    return false;
  }

  public get customRangeText() {
    if (!this.measure.hasTimerange) {
      return 'No Time Range';
    }
    if (this.useDefaultConfig) {
      return this.getDefaultRangeText(this.defaultRange);
    }
    if (this.filterTimerange.length >= 2) {
      if (this.filterTimerange[2] !== 'custom') {
        return this.getDefaultRangeText(this.filterTimerange[2]);
      }
      return `${dateFormat(this.filterTimerange[0], 'mmm d, yyyy')} - ${dateFormat(
        this.filterTimerange[1],
        'mmm d, yyyy',
      )}`;
    }
    return 'No Time Range';
  }

  public get breakdownText() {
    let breakdown = '';
    if (this.dimension && this.dimension.name) {
      breakdown = ' break-down by ' + this.dimension.name;
    } else {
      breakdown = ' ' + this.dimension.code;
    }
    return breakdown;
  }

  public getDefaultRangeText(rangeValue: string) {
    const defaultRanges = [
    {
      text: 'Today',
      value: 'today',

    },
    {
      text: 'Yesterday',
      value: 'yesterday',
    },
    {
      text: 'Week to date',
      value: 'week-to-date',
    },
    {
      text: 'Last week',
      value: 'last-week',
    },
    {
      text: 'Month to date',
      value: 'month-to-date',
    },
    {
      text: 'Last month',
      value: 'last-month',
    },
    {
      text: 'Last 7 days',
      value: '7d',
    },
    {
      text: 'Last 14 days',
      value: '14d',
    },
    {
      text: 'Last 30 days',
      value: '30d',
    },
    {
      text: 'Last 90 days',
      value: '3m',
    },
    {
      text: 'Last 180 days',
      value: '6m',
    },
    {
      text: 'Last year',
      value: 'last-year',
    },
    {
      text: 'This year',
      value: 'year-to-date',
    },
    {
      text: 'Last 3 years',
      value: '3y',
    },
    {
      text: 'Custom',
      value: 'custom',
    },
    {
      text: 'This fiscal year',
      value: 'this-fiscal-year',
    },
  ];
    for (const range of defaultRanges) {
      if (rangeValue === range.value) {
        return range.text;
      }
    }
    return this.filterTimerange[0] + ' to ' + this.filterTimerange[1];
  }

  public autoDetectTypeReport() {
    if (!this.detectTypeReport || this.typeReport) {
      return;
    }
    this.isDetail = false;
    if (this.typeReport === 'TABLE' && this.pivotOption) {
      this.isDetail = true;
    }
    if (this.dimension.code === 'by_day') {
      this.typeReport = 'LINE_CHART';
    } else if (['by_week', 'by_month', 'by_quarter', 'by_year'].includes(this.dimension.code)) {
      this.typeReport = 'BAR_CHART';
    }
    if (!this.typeReport) {
      if (this.isMetric) {
        this.typeReport = 'BIG_NUMBER';
      } else {
        this.typeReport = 'TABLE';
      }
    }
  }

  public async saveCard() {
    const reportCardModel: ReportCardModel = new ReportCardModel();
    reportCardModel.name = this.reportCardModel.name;
    reportCardModel.measure = this.measure;
    reportCardModel.analyticsType = this.analyticsType;
    reportCardModel.dimension = this.dimension;
    reportCardModel.cardHeightSize = this.cardHeightSize;
    reportCardModel.cardWidthSize = this.cardWidthSize;
    if (this.filterTimerange.length > 2) {
      reportCardModel.defaultRange = this.filterTimerange[2];
    }
    reportCardModel.dashboardIds = this.initReportCardModel.dashboardIds;
    reportCardModel.id = this.initReportCardModel.id;
    reportCardModel.filterTimerange = this.filterTimerange;
    reportCardModel.filterColumns = this.filterColumns;
    reportCardModel.reportParams = this.reportParams;
    reportCardModel.reportColumns = this.initSelectedReportColumns;
    reportCardModel.typeReport = this.typeReport;
    reportCardModel.productSegmentId = this.productSegment.id;
    reportCardModel.pageOptions = this.pageOptions;
    reportCardModel.calculatedMetrics = this.calculatedMetrics;
    this.saveNameLoading = true;
    try {
      await reportCardModel.update();
      this.saveNameLoading = false;
      this.isEditingName = false;
      this.$emit('save-card-completed');
      EventBus.$emit('show-snackbar', {message: 'Card saved', color: 'success'});
    } catch (error: any) {
      this.saveNameLoading = false;
      EventBus.$emit('show-snackbar', {message: 'Error saving card', color: 'error'});
    }
  }

  public async removeCardFromDashboard() {
    await this.reportCardModel.removeDashboard();
  }

  public async deleteCard() {
    const id = this.reportCardModel.id;
    await this.reportCardModel.delete(id);
    this.$emit('delete-card', id);
  }

  public currentReportCardChanged() {
    const currentReportCardModel = new ReportCardModel();
    currentReportCardModel.cardHeightSize = this.cardHeightSize;
    currentReportCardModel.cardWidthSize = this.cardWidthSize;
    currentReportCardModel.typeReport = this.typeReport;
    currentReportCardModel.reportColumns = this.initSelectedReportColumns;
    currentReportCardModel.filterColumns = this.filterColumns;
    currentReportCardModel.pivotOption = this.pivotOption;
    this.$emit('input', currentReportCardModel);
  }



  @Watch('filterTimerange', { immediate: true, deep: true  })
  private async onFilterTimerangeChanged(newVal: any) {
    if (!this.isCreated && this.loadDataByDefault) {
      return;
    }
    if (this.filterTimerange.length < 2) {
      return;
    }
    await this.getData();
  }

  @Watch('typeReport', { immediate: true, deep: true  })
  private async onTypeReportChanged(newVal: any, oldVal: any) {
    if (!oldVal || !newVal) {
      return;
    }
    await this.getData();
  }

  @Watch('dimension', { immediate: true, deep: true  })
  private async onDimensionChanged(newVal: any) {
    if (!this.dimension) {
      return;
    }
    if (this.isCreated) {
      this.detectTypeReport = true;
      await this.getData();
    }
  }

  @Watch('analyticsType', { immediate: true, deep: true  })
  private async onAnalyticsTypeChanged(newVal: any) {
    if (this.isCreated) {
      this.autoDetectTypeReport();
      await this.getData();
    }
  }

  @Watch('comparisonDate', { immediate: true, deep: true  })
  private async onComparisonDateChanged(newVal: any) {
    if (this.isCreated) {
      this.autoDetectTypeReport();
      await this.getData();
    }
  }

  @Watch('useDefaultConfig', { immediate: true, deep: true  })
  private async onUseDefaultConfigChanged(newVal: any, oldVal: any) {
    if (!this.isCreated) {
      return;
    }
    if (oldVal === false && newVal === true) {
      await this.getData();
    }
  }

  @Watch('filterColumns', { immediate: true, deep: true  })
  private async onFilterColumnsChanged(newVal: any) {
    if (this.isCreated) {
      await this.getData();
    }
  }

  @Watch('needSaveCard', { immediate: true, deep: true  })
  private async onneedSaveCardChanged(newVal: any, oldVal: any) {
    if (newVal === false && oldVal === true) {
      return;
    }
    if (this.needSaveCard && this.needSaveCard === true) {
      await this.saveCard();
    }
  }

  @Watch('needReload', { immediate: true, deep: true  })
  private async onNeedReloadTableChanged(newVal: any, oldVal: any) {
    if (newVal === true) {
      this.reload();
      this.$emit('refresh-data-completed');
    }
  }

  @Watch('needRefreshData', { immediate: true, deep: true  })
  private async onneedRefreshDataChanged(newVal: any, oldVal: any) {
    if (newVal === true) {
      await this.getData();
      this.$emit('refresh-metric-data-completed');
    }
  }

  @Watch('reportParams', { immediate: true, deep: true  })
  private async onReportParamsChanged(newVal: any) {
    if (this.isCreated) {
      await this.getData();
    }
  }

  @Watch('initDefaultRange', { immediate: true, deep: true  })
  private async onInitDefaultRangeChanged(newVal: any) {
    if (this.isCreated && this.initDefaultRange) {
      this.defaultRange = this.initDefaultRange;
      await this.getData();
    }
  }

  @Watch('productSegment', { immediate: true, deep: true })
  private async onProductSegmentChange(newVal: any) {
    if (this.isCreated) {
      await this.getData();
    }
  }

  @Watch('initPageOptions', { immediate: true, deep: true })
  private async onInitPageOptionsChange(newVal: any) {
    if (this.initPageOptions && this.initPageOptions.sortBy && this.initPageOptions.sortDesc) {
      this.pageOptions = this.initPageOptions;
      this.sortBy = this.pageOptions.sortBy;
      this.sortDesc = this.pageOptions.sortDesc;
    }
  }
}
