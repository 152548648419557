import { get, put, del, post } from '@/services/http';

export class ReplenishmentSettingHistory {
  public key: string = 'replenishment_setting_history';
  public values: any = [];

  constructor(data: any = {}) {
    
  }

  public async fetch() {
    const res: any = await get(`/users/config`, {key: this.key});
    if (res.data && res.data[0] && res.data[0].options) {
      this.values = res.data[0].options;
    }
  }

  public async save(values: any) {
    const res: any = await post(`/users/config`, { 
      key: this.key, 
      values: '', 
      options: values 
    });
  }
}
