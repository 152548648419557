
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import HeaderTitle from './HeaderTitle.vue';
import ActionButton from './ActionButton.vue';
import { StoreModel } from '@/models/store';
import { EventBus } from '@/main';
import { avatarColor } from '@/services/configs';
import { Title } from 'chart.js';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class VerticalMenuBar extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public isEmbeddedMode!: boolean;
  public mini: boolean = true;
  public search: string = '';
  public menuPurpose: string = '';
  public canExpandOnHover: boolean = true;

  public get getMenuItems() {
    const storeId: number = this.store.id;
    if (this.store.shopName === 'assisty-demo.myshopify.com') {
      return this.replenishmentMenu;
    }
    if (storeId > 9683) {
      return this.replenishmentMenu;
    }
    if (this.store.shopName === 'assisty-demo.myshopify.com') {
      this.menuPurpose = 'replenishment';
    }
    if (this.menuPurpose === 'replenishment') {
      return this.replenishmentMenu;
    }
    return this.FullMenu;
  }
  public pinMenu() {
    EventBus.$emit('pin-menu', this.canExpandOnHover);
    this.canExpandOnHover = !this.canExpandOnHover;
  }
  public replenishmentMenu: any[] = [
    {
      section: 'replenishment',
      title: 'Replenishment',
      items: [
        {
          title: 'Demand forecasting',
          icon: 'mdi-chart-timeline-variant-shimmer',
          type: 'group',
          items: [
            {
              title: 'Historical sales-based',
              link: '/reports/38/demand-forecasting/609',
            },
            {
              title: 'Inventory movement-based',
              link: '/reports/38/demand-forecasting/661',
            },
          ],
        },
        {
          title: 'Inventory replenishment',
          icon: 'mdi-clock-outline',
          type: 'group',
          items: [
            {
              title: 'Replenishment metrics',
              link: '/board/1394',
            },
            {
              title: 'Reorder suggestion',
              link: '/reports/36/inventory-replenishment/672',
            },
            {
              title: 'Replenish from warehouse',
              link: '/reports/measures/inventory_transfer_suggestion?dimension=by_variant',
            },
            {
              title: 'Replenishment params',
              link: '/settings/inventory-replenishment-parameters',
            },
            {
              title: 'Incoming inventory',
              link: '/settings/incoming-inventory',
            },

          ],
        },
        {
          title: 'Replenishment by model',
          icon: 'mdi-details',
          type: 'group',
          items: [
            {
              title: 'Just-in-Time model',
              link: '/reports/36/inventory-replenishment/622',
            },
            {
              title: 'Fixed time period',
              link: '/reports/36/inventory-replenishment/627',
            },
            {
              title: 'Fixed order quantity',
              link: '/reports/36/inventory-replenishment/632',
            },
            {
              title: 'Min/max inventory quantity',
              link: '/reports/36/inventory-replenishment/602',
            },
            {
              title: 'Min/max stock cover days',
              link: '/reports/36/inventory-replenishment/601',
            },
            {
              title: 'Replenishment for bundle',
              link: '/reports/36/inventory-replenishment/672?report_query=eJztmN9v2zYQx%2F8VQcCeZhWSbNlx3tLsZeiGZk2alyAQaIlJuFKiyh%2FZjMD%2F%2B46UbJ5ou3W7V%2FvBsI4f3h35FXmXvMWaaU7jy%2Fj39pW2Wsh19Il2nLZMvTRgiCdx9UKkvlt3loLHF6KurSW%2B1NLQSaxexD%2BD4YlwZS2U00rT%2BprI%2Blpw07Qqvnx4i1vSWB83UtSmcp5F7UJr2jzYr%2Bg3qirJOs1E%2BwjjNdFkCKzpv3YGN0z9SbqOtc9gXK3LbnC2mez83xPJSIv8v%2B4M33O4JZG32w%2BfvachWqm%2BmBO8YRp5%2FESFrKmMbgTDWT7I3l521h4sv6YVawjfCzGag4N4Of8ysCKm1yjSVaUN4dE%2Bc2LUo%2FPHGVSiAf5QAmwYK9nWRfn1x1L4hgecxOeWaRXdCl5Hq3V0CxM4VSgRY8dLBeOlcoMnht%2Bbdzzoe9PW46Bu2jbbsgP5Vo455ZU%2FNhfHHyJGlj281qMxT1jv4Zh%2BzccXSiRpTox3YOZ%2BNAtFkE5UE%2FxykVcqyTMta8L4usSZ%2F0AC33aCc7mq%2FzYKbrvI%2FFRSioBUpZa0rf9vasjV%2BL5B9zk6tNdCja8fhKFjVQF28m103EW8eZzEDSXKSOrO73ZQBuWmZvBDQQXoL3h%2FeSsNRabUzC0qT%2FNZks6TbAEjsOCxfZlkc%2BuKPhHDdSlJ%2B2wHpw0YnxjXVEJBgnw69ip0KVzB6aubk3dUrnb63rmN3W0Y3u0JVA5uwPoQZ%2FHj8DRsFmcqKD2taVaQwcQN3Vu0D8jq%2BPJiEv9BVpQD9muWpr8Add%2B7jvN3qZPVYkuELUdU9m65o7IUYRcBduGxDGGLAFt4LEfYPMDmHpsirAiwwmMzhM0CbOaxAmHTAJt6bI6wPMByjy1G2zvGMo9hFcaUZ5AEydhViiTIkQRJHmBeghxJkEwDzEuQIwmSWYB5CXIkQVIEmJcgRxIk8wDzEuRIgmQRYF6CHEmQXASYlyBHEiTLAMvcLaGE1B9tWwO2WWrPiSC1u2Fcg4mbM%2Bg9ozvR%2BVOpRdfi43jFeXggh4J9sNbuH8gCLatAyfptLNCKshS%2FLP68FuiNyjGTIwa9UQVmCs%2FM0etkl%2BYh%2BxRu3tJ%2B9vfv0bfo9vaFCXBDn9v0c5t%2BbtPPbfq5TT%2B36Z3Nu%2B%2BKoSS8uWfoeCYxg9tf3VB54wxF2peb9%2Bu%2Bm7a%2FbWmAJ1dowPQshem24%2B5hAOwfA6DHrUD%2FN2qgV2fIsoFVE14ZTmylaqiWrFL9XLhVQGGmRAv7qyGXt83mP0dPRbU%3D',
            },
          ],
        },
      ],
    },
    {
      section: 'Analytics',
      title: 'Analytics',
      icon: 'mdi-chart-bar',
      items: [
        {
          title: 'Reports library',
          icon: 'mdi-library',
          type: 'single',
          link: '/reports/0/all-categories',
        },
        {
          title: 'Inventory tracking',
          icon: 'mdi-store',
          type: 'group',
          items: [
            {
              title: 'Inventory tracking overview',
              link: '/board/1460/public',
            },
            {
              title: 'Inventory on-hand',
              link: '/reports/31?report_query=eJztWEtPGzEQ%2FiurPRUpB0AKbXOD9FBUqGgD9BBFq4nXJBZee%2FEjaYTy3zvep5PdhKVV4ZILwuNvvhmPZ2fGeQ4NM5yGg3BkJHkMpAjmIOJgugpulIwtMcE9KAbChL2QzEGZ21Xq4Licgx46STgwytJeqOdyWQgegGsnoZwSQ%2BMhqHgouU2EDgfj51BA4jgKC45Zxk5waWgydn%2BCL1QTxVLDpJjgfgwGCsOG%2FnYa3DJ9DWnKxAyF01WUFmTrXsXveZ7zLyrBS4Ql0mMbfburmQprkX60Hdh8tMd4ASqja7BOq42uzKWGx17e38iAsbrF9VLePbilTouVjKBhw%2BTSV1jINPw7pCKWqmbO18F3t9uJeJETeJR3gplgKHUzMSKLWxHJtzxuYpWigqx2pYmn5ycM5Rxh%2BCEx4sUmw6aFrIsRT8FjvxR4MiPVKvhh0QVmVrWJ8TkxFnjQxGx9TDElLAHeMLlTf9MDIhN3wBYHWLEXPb3OcEPPNziUScIM1pM2i6TcfK3JpmJ7mO%2BBW7ovxi6lJh3vtFXZt%2FuTGmC8YdQXd7W1QbU3g4Ibqgj1KyZeZAGqohOlOWjLfFqpbt%2FoLoI9Yd7ryMIh%2FtqLDW3fhWu8BKuNTIIrScB1nuBD0%2Fmj2qUSNi7%2FmUQpW0gT7fn8CgSaj9rsYRNWtFofdUvgN%2FDDr2pzmQb8TSK0Zeo9gvOCC35chFwGQ%2FcR%2FQJF59Jq%2Bp%2Bj02bwXWLUxZH1pBcmFLRVdG9%2FQYdZQoVGpaJ%2FVzMbCOArw4gujnOLV%2FOVzeZOhz6A5SZSIGbFdPrAuKGqZdx8eSDKSgRqhkAMW9AQXZd4UEBPEU6fWgYPd7w8IjIbWlEeKbnUUUtsieR6X2iRPgUFycasjIm4DPDEtdtGpsJ39pxz52m2LlzTeL98x5zEtMlKbW6FxeGgf9YLr2BKOaL6CMkbhvvfpXmG%2BFgjTo49CC4qzKcac%2BpjTj3MZ8%2BSj%2BnXmLPjGuOOVoPcysWbS4izBM5eG05SvjciRVOp3ER2eHNsvTne5lVwGE4Pw%2BlhOP3n4dQ1FZjRoqdgHXvO1uHgpBcyLFkaPbnJBH2slhpL3sXKtYJ9n9gkB7pih1D3yw1KZkraNNMtF8W%2B69o4HI6k96tOgp2WeZJ1r64edIbN20SugKNHBDixHFz6JtTgC1bnlJjV2OGYliLC8KD7z%2Bv1Hx53hc4%3D',
            },
            {
              title: 'Low stock',
              link: '/card/view/1276?report_query=eJzdWE1v2zgQ%2FSuETlvAAeykcWLfUvdSNF1km7Z7MAyCkWibKCWq%2FHDWCPzfd0aftKXIcoGkRS9BNHzz3mgozgz9FFhhJQ%2Bmwa16JPdWhd%2FJLd9wSe60ilxoTTAIwjXT9ss2RRg8rpmZoSWYWu34IDBr9VgYlkwatHDJQ8ujGdPRTEkXJyaYzp%2BChMXIUVAjs4rQ8MHyeI5%2FyHtuQi1SK1SygPWIWVYIW%2F4fekgnzCeWpiJZgfFhS9OCbDeo%2BL8xLVji8W8qwzHCEumx3X%2F8WjMVatR8dz3YfLTH%2BI7pjK7B%2BlAt9GUuPTz2Iruwmcw60xJ6ae%2Bf3NKnRSUjaGjY3HqCQubh7yFPIqVr5vyZ%2FI2rvYg3OYFH%2BTURlsyUaX4Y1MESDfMljzt0WvMk3D73mXh%2B%2FgfDpQQYnCARernJsGlh6yPiOXjsHxJ4M6v0lvzjIARht7XE%2FCa0jknSxBwcpoiHImayIfms%2F34EoYrxBVsCEMUa%2FXGacMPPF5ypOBYW6kmbYlgunirZdGxP8zcmHe%2FKMX5Si5572urs637mlgnZEPXNfbX2qDq%2FIHLHdcj9igkbWYCq7NA0Bx3Ip5Xr4Y4%2BR9CR5s5ANoj46Sj2vP0QPsEmOGNVTG5VyLDzkL%2Bawb%2BpQyph8%2FKfBU3FRlnacfwKBMjTNj1ovJpXz2%2F6fcCvEIdf1dYqJfJVMnQg9SuScyQEPy8JDE4zPET%2FMs3Xyhn%2BwtlpE%2FwlOeoTyG4xCGLOjNNZJGxrqFpS49JUYsWKRMwTA8j9YQ5GSAsDJbUiy%2FH58Pzt2fD6bHQNK9DX9%2B2Ts9EYqfiSOWmpZskKFy%2BGEViXQlqu%2FenzPcRA1NJ7uc88ZiLBnNRlx%2BAoTDFerDRZ8QCS4CqA91Hw9gw8AQfk%2B7lOXPzAG2OHIfdKRkQyY8lkSJDW08JGb6gBBEUEnQyjPdHhgejqYEwh5QZjsvP9UdkInc%2FrKdMs3hvA4et%2BJF9UWsdgVZr4kjdSomj2XKgYSJB8ZvgSxmb1O1cRUTC9HA%2BCW%2FbAJaAuAZJ3IfwfU5MhrmrEaOhB4KHCXNeYcx9z7mEmnpKPuawx42GNwVerQfiEaZOKRdmpyK4waCkvMVTzVGkc8179IlMO2eZFR%2FnyQFRFi%2BijB6JHdTk467%2FnCFvBXnqGvdnA8V1x4lUDONBYCTxltllBhoWEtgHjEhaEntK1Y11LumrQ1bESNHrsqVwNd75vl%2FTF0fJ3Mez72i2eXdLjo9LjhnRRz48pj7uVe9f805UnTeWDS%2B68uqX2vTmccq2d79l7KiROyj%2FqspW9EHZfOONF84VO8ZQ9B9PRIBDQFAzcbu4ywyX0IwNN5d0Wm23ruV%2FkCOwjgMFf2sCy0sqlmVP5UKzjlAXD%2FL3yfoWLYRoSnmU3qBsHX8HcZSn2RgglZDJ0kmGzi7mFfTQ5JdQzGB6EUQmEB5PO9Gm3%2Bx8WhAmv',
            },
            {
              title: 'Out-of-stock',
              link: '/reports/288?report_query=eJztWG1v2zYQ%2FisEP62AAzjZkiX%2B1noFFqxdszrtMASBwFCMTYQiFb44NQL%2F9x4pSqIt2VEKLAXW5UNgHp%2B753gk7456xJZbwfAEf3AWqVs0s4reoQutckct%2Bkw0J9IaPMJ0QbS9XJUeC8MFMVMvwROrHRths1APUXBLhPESJhi1LJ8SnU%2BVcIU0eHL1iCUpvI1I4S2r3AvOLSuu%2FD%2F0GzNU89JyJa9hPieWRGLLvngN4bh5T8qSyzkIb1ZZGY2tR4396Hprf9kInjJYIxNrsz8%2BtZYiW2bu3ABrKTqx%2BIboYK5j9aaZGGq51kis1xs4s8Q60%2BN6LR8e3FqnhyUY6HDYSvoMhqCR7iGTudKt5WqM%2FvSzgwwvKwOJyU%2BSWzRVpnswMgdTGa2mEtvUac0kXe06JoleemCYEACDm8RpEpuALaNsCEmikFg%2Fl7Ayq%2FQK%2FeXABW5XLcXVa2odEaiL2bpMOaO8IKJDuVN%2F0wOqCr%2FAHgd4nMvun0fc0UsJp6oouIV80sdI68nnUnYV%2B8P8mQjH9sXYH6nrgXvaq5zyfmSWcNEhTcVDuTZM7T1B6IJpytKMCRsZQU10srICbdGXjer2ju4ysCfMex1ZesQ3e7GhnbrwHjbBGasK9E5R4isP%2Bqnr%2FKvWpRp2Vf%2B4zkq%2BVDbbc%2F0iAuizPj4ovZo141fDDvAL%2BJFmtYUqkXiRCG1RfY%2FgPOFCGhepHtDUX6K%2FiWYL5Qz7l6PTR%2FhdYjTEkfX1CBeMGKfZ3voCDvOCSQNKsX43PRuRRKwspyYu5xK25nc%2BX8AU9CUaegceduJofPTLwfj04PAUZqD6b8rPDg5PPAu7JU7YTBM595M%2Fj3OQ3nJhmU571NbF%2B%2BEVNuQYsILHGJatIEgEMKAG1vu3pDcVXmpC7%2FwWNbTxPEEOte1cw%2FbP29kWH7vvaZK%2BpUdsOAi1fMkG0QCmOiQq9PHVo6EkmhQbrwC4Yg8I9rJlt6qUKedrITxhGEcGA0sXOzpAbmwoIhULz%2FHk%2BGSE35EbJgB1DJCqFPrfPhYB8WuLOBwnEBg0mNMWc5RijhLMWcKUYo5bzMm4xfiltSA%2F8mEzStsPOvcnEZ%2F5P78sRfJwXcPbKqDi6yrTrAQF6D93vbDQeUHmSRvB4zAJXi3aKptB2j0wP%2ByT7WUeVc9OAU%2Bx9Sht9NWQuSR0XSa%2BWB4WTCJVfQ0w%2FmtAS08jNovYDGBZjXnKjT3K%2F79s%2FtsvG18MIJfEWgBJ6jGM8eRwhDmkDwMd%2F0UQHI%2BrBPhmBZksJkOfW2AUUh%2BI5lq5sp4PgwjwTQb0sjOVfIQqoMzzRLIetbeVzaHXsJnPysBKiaBOEB%2Bwgll4cJvKJMQRyhY3SmYQAXDxcb3%2BCp51tHs%3D',
            },
            {
              title: 'Inventory remaining days',
              link: '/reports/212?report_query=eJzVWNtu2zgQ%2FRVCT1vAAexk48Z%2BS92XYtPdbNN2HwxDoCXaJkqJWl6cNQL%2Fe2eoG23JshJsb3kIzOGZOePhZQ79FBhuBAumwVu600SuyLt0y1Ij1Y58YAnlKU%2FXZLkj90rGNjLkM1WcpiYYBNGGKvNxl6EzDDdUz9ASTI2ybBDojXwsDCsqNFqYYJFh8YyqeCaFTVIdTOdPQUoTjFEwYGQZo%2BGdYckc%2F5G3TEeKZ4bLdAHzMTW0IDbsP%2FQQluv3NMsgWTAud2FWBNsPqvhe5nn8bWU4F7BEetEe%2FvhURyrYQv3F9ojmo72Ib6hy4RpRl9VE38ilhxe9XL8HQ43VLamX9v7FLX1aWFyABofJrc9gcB7%2BGrI0lqqOnI%2FJnzjbK%2FA2D%2BCF%2FJRyQ2ZSNzdGaGEqjPIpL3ZklWJptDu1TTw%2Ff8MwIfAs3SseebVx2Kyw9SHxHLzo9an920IK3OxqivltZCwVpIk5Okwxi3hCRYPypP9hBpFM8Au2JMCLufDf5xE3%2FHzCmUwSbuA%2BaWOMysnnUjYd28v8mQrLumqMW2rRc01bnX3eD8xQLhqkvrkv10Gozh1E7pmKmH9jwkIWoKo6YZaDjuizyvV4RU8F6ChzZyJbRLw4iwNvP4X3sAhWG5mQOxlR7Dzkt2byr%2BqUSti8%2FLAIM76VJuw4fgUC6MM2vgdAs2r8qt8G%2Fg55%2BLfaRmZEfJcKHVH9iOKcScGvSyofyQwP0T9UsY20mn3j6rQR%2FpAa9UlkvxgECaPaKpcJSM9QrkJts0zgjRXzhKUakEXTroQaKA4FqoC7Gl8OL3%2B%2FGN5cjG5gBvr6oX1yMRpjKLaiVphQ0XSNk1fDGKwrLgxToD4hjzx16dRlLmUzqmhyoE1h4R%2FJR5nVy2VklsLI3R%2BADG6FCBbFuCiwhnqKE7qEa%2BOutpyFx8H0ejwI7uiSCUBdAyS%2FoPEzbiuHeF0jRkMPAoMKc1NjLn3MpYeZeEw%2B5rrGjIc1Br9aDcIRLp%2BWyvylYqxiMME%2F%2FFqSxm4TOcXvUIXmDxXLwAFU0S%2Bk%2B1MrxP8j%2BX3RfKj6y2dXdTcQVT67vHYHF3L0JcRz0lPGHB2pn1MpVrBvLRVvt0zRNSOo9TV5kCImcFURKJLHTLdrqDAXcDuDKtEA6kldO6I21871%2BIVRsAqqDXmNxN4TbF67hQgIR489mSsN5ft2UV8Nz3HD%2FdiTvMWzi3p8lnrcoE5tsmTqLPO4m3lylnnyUuZJN%2FPo5iw1QF5acHTtXOzzJb9q1rz3cp%2Bu%2Bh1fMdeLvQsM%2FartKgpAT%2Bp2Z9eFMjjWRe%2BGpvLkxsF0NAg49A8N74Z7Z7ge5g3rzQ57detRL1oathzA4G9YYFkraTPnVA6KedQvIJMfpPf7VgI6g3uW%2FaDuEmwNisaE2FohlYiKyAqKfTFhBt7yOg8JVxhoD65lCukZyPtpv%2F8Kp9PZkg%3D%3D',
            },
            {
              title: 'Inventory movement',
              link: '/reports/291?report_query=eJzNWG1v2zYQ%2FisCPwwr4ABOtmSJv6XugA1rt2xuuw%2BGIdAUY3OjSI0vTo3A%2F31HipKoF7vysKXLh0A8PnfP8UjeHf2MDDOcohn6UeyoMFLtk1zuaA7fyXqf7LBiWBg0QWSLlXm%2FLxwWhlus506CZkZZOkF6K5%2BC4BFz7SSUU2JoNscqm0tuc6HRbPmMBM6djQclM0u8ZZl5BwzNl%2B5f8oZqolhhmBQrmM%2BwwYHY0E9Og1um3%2BGiYGIDwvU%2BLYKxw6S2%2F7HxvLTfLOVzBitkZG3x04fGUmBL9Z92hLUYHVl8jZU317O6rifGWq40IushusnCYGP1gOuVfHxwK50BFm%2Bgx2FK6RkMXiPeQyoyqRrL5Tj52c2OMrwrDUQmPwhmkrnU%2FYORWphKSTkV2SZWKSrI%2FtgxifTiA0M5B1jyoBiJYuOxRZCNIYkUIuvNZf3VggvM7BuK5T0xFvOkj%2BlcpowSlmPeozyq3%2FaAyNwtcMABFubSv84j7unFhHOZ58xAPhliJNXkuZR9xeEwf8Tc0lMxdkdqNXJPB5Vj3t%2BowYz3SGPxWK6WqZMnKHmgitA4Y8JGBlAdnbQoQR36olbt7ugxAyfCfNKRnUP8Yy9a2rEL72ATrDYyT95Kgl3lSb7uO%2F%2BqcamCLauPVVqwnTTpiesXEECfDvEtAE3r8atxB%2FgF%2FIiz2lYWCX%2BRCHWovkRwPuNCHBchn5K5u0S%2FY0W30mr6H0dniPCLxGiMI4fVBOUUa6to6zpWnabzlMGHBnQo3HWzhgXme8OIDut4D3vyA9tsYQoaEgVNA%2FNbcDW9%2BvZientxeQszUPbb8ruLyxvHQh%2Bx5SZVWGzc5DfTDKSPjBuqoDkFN8uVSd98lp1ugRXOW60rnIunBPxodtPIQsDIpxdAonvO0SqMg98aws2PtC1MG5%2F5ShaWodn1zQS9xWvKAXUNkDJ%2Fu2936jziuwZxOY0gMKgxtw3mKsZcRZi7iCnGXDeYm2mDcUtrQG7kdldLZX5RmYsiunN%2FblkSZ%2F6M%2BQeBR4UnQapoAQrQNPWeBW9wVNcLqIuuH4CQdSIXJJ1%2BOIb329T%2F4VNDWM7%2FnVdG3Ke3Hxrty3gqA42jqRJi6zFDN0yII%2B3gupo8tznrK3Y7eZ0sJM8iLtcu61SDcCRHo9C3Db2TVYLG9lUQnbuUnt4QG6FsB%2F3tV8l99gcU5RZx3T8ZyF36kUK7N3aJQ6ox%2FfciO7Jz1M%2BcudbOmfYPLtJ6cC3rF9PYLvacJ9ayJR%2FJ0HF67mFm6A30Iu8sV4jwhoY6BAny2Y%2FR7HKCGOQtDS3ygxdcT8vk%2B3pf1i%2F37ZIajHzaBdFGSVtU834QAK4qwzlbyOhXmxzKI4skh0mTiOjGFevUVQRgJZgTy7FL5zk1EGtdmoQnFZRMpqUoE%2FHs%2BXD4GzZEbLE%3D',
            },
            {
              title: 'Month-end inventory',
              link: '/reports/350?report_query=eJztV01vGjEQ%2FSsrH3oiEtCSJtyStIeoiZqWpBcUrcyuAbdee%2BsPWoT475nxevHCQgVSj%2BQQ4Zk388Yzg2dYEcutYGRIHpW08%2BSzzJN7uWDSKr1MvjkqQb9M3iU%2FqHAsmSyTJ61yl1kQaA5a0iHZnGr7vCzRCxzn1NyhhAytdqxDzFz9CYIpFQYlTLDMsvyO6vxOCVdIQ4bjFZG0QB%2BBAT2rHAX3lhVj%2FJd8YibTvLRcyVfQ59TSQGzZX7QQjptHWpZczkA4WaZlcLbubPyHyJNnf%2FMNy2JzoSPcml%2Bu6XL05SU6CpQec0qMLadQA5Y8qIzifaP%2FWjKuPxyZClF7anDcshmXEiCbWkee8aRWpr%2BDcocpZxkvqGiTtQybnC%2BSW5OMlMgbXA6FqQHhkRzRoO37O8sYX7Ac%2BvYm%2F%2BkM9FqDqo4ptZpKM2Vas2NJ95nuo7dOyy1OHUSnZrJl12SDL%2BuByjGvOZFMOiF2WwP9x%2FfAPwJ7G4TXmDRTxu7wZQ7yJLPlP%2Fpk235%2Ft7ToEZqq6b6uOUjZtDlcuYNUVT1OJausjurTFnPMTN1z6QIhx8ZwyH5PIx0udOin6OuUEHb6KpBBqikXh5i0155O89ohBaPGaUTOuYFgeUZFjBx98YJJgw%2Bhfxbju28sTKnUch9mv9v%2FcNG9uuhdgQai2pZfX%2FQu0RWbUidsCpmdofJ9F7%2F0Uy4s0zDSIJqSLxT0gB9Z1XwsqabF1sAbwYRMnlUZ02BViQ9%2BVajhmNwIQV7DOaTBwKX9%2BNrz8sO9fV4rFp6T4eCyQx7ohAlADQBSpR0%2FY2U84mNE9LoNCBw2mKuI6Tcx%2FQbmusHUxAwi5rIbMXi1CMITFtEobb%2FqHLNIrvEPr6Vo7ivt1wiPCosEtEsJBvB2nJeJ8zJxXibOy8R5mTgvE%2F9jmSjpjIXpDWNl5c9k2OsQDhPEPDH95AWDbjWybpfV1MfPOF7g5IcViGZaubLW%2B0MA4MoC1Rqpxi%2FkApYK3pCs4U5UZE5QnHYFs7DVmMo2UwVsFNwomcK9IJbVev0G7KN7aQ%3D%3D',
            },
            {
              title: 'Inventory value',
              link: '/reports/595?report_query=eJztV99vGjEM%2FldQnnloK9GtvHX0ZdqmdWu7F1SdQs6l0XJJljhsCPG%2Fz7kLd%2BH4UbZneEDE%2FuzPdhxbrBhKVMDG7KNegEbjloMfXAUYzJaDe2fKIJAETnKNng2ZeOUOH5c2WtDxlftJlLAxugBD5l%2FN7yR44cpHCSgQCOWEu3JiVKi0Z%2BPpimleRR%2BJIno2ZR0GQjWNX4M78MJJi9LoZ9KXHHkiRvgTLVSQ%2Fgu3Vuo5CWfLwiZn62HrP4Xe%2BV%2B0grccbpCZt4dPT52nxFb4n%2BEEbzk689ireud9eiswcDXoABPjsVcIEZwDLZY7bHuNc97vgFyqHdJcfCrXlqu9qX0LVEeJ1FDgBOS3MZUbUPErgQrbgHr0tjXdZj%2Fs4EiZjwayiIj%2FjmLLOg%2FhSUtsrqHfi0UgVSEa1SkV37XLexSUIhg9Ximyq62xNslOIckMjl7qsZbdYHpVLEHIiqu3u7bl2IpAmComuCcAmXRtL5xIvGOXE05MVUmkEbaPUWyU%2F0q5a8jWz0NWAffBwcEnTL5lBdrTUGxmXjbP4IUHhYXjep7G84tUCI7mLXm2cmGwMPU8bbSWO15tTeMHGt%2BDR2O7BNFYTae6qwnJbpViz%2BmcMvSUkOp11WYESo%2F1g2tYZMnGo%2Bsh%2B8xnoAg1IkgzNuLvWPIa8a5DXF5kEDq0mPcd5irHXGWYm4wpx4w6zPVFh4mpdaB4ihfijcOvroxVZDfxE9MyvKxvsd5xNSptucKBJQN6lOdNd95050133nTnTXdg01k%2Bh7SOaE6u6jMbXw6ZpJHoqW3va8HoopnBH5Zx%2FfSebZrPcX6SNv4FIcncmWBr%2BOaQ9HG9Bo8PJvt7UtHGlJlkTQlzJYLiMeAKkNrCN7aUB21M6Y0uKGsKbbVe%2FwXiGpaj',
            },
          ],
        },
        {
          title: 'Inventory optimization',
          icon: 'mdi-cellphone-cog',
          type: 'group',
          items: [
            {
              title: 'Inventory metrics',
              link: '/board/337',
            },
            {
              title: 'Slow-moving product',
              link: '/reports/56',
            },
            {
              title: 'Dead stock',
              link: '/reports/207',
            },
            {
              title: 'Aging stock',
              link: '/reports/562',
            },
            {
              title: 'Sell-through rate',
              link: '/reports/186',
            },
            {
              title: 'Inventory turnover rate',
              link: '/reports/345',
            },
            {
              title: 'Inventory to sales',
              link: '/reports/642',
            },
            {
              title: 'Stock availability',
              link: '/reports/668',
            },
            {
              title: 'Stockout revenue analysis',
              link: '/board/372',
            },
          ],
        },
        {
          title: 'Sales performance',
          icon: 'mdi-chart-bar',
          type: 'group',
          items: [
            {
              title: 'Business overview',
              link: '/board/117/public',
            },
            {
              title: 'Sales overview',
              link: '/board/64/public',
            },
            {
              title: 'Daily sales',
              link: '/reports/measures/sales_overview?report_query=eJztVl1r2zAU%2FStGMOjAYUm2tGnethTKYP2AlL6EYlT7xhXIktBHuhDy33ev7NZKs47svXkIuUfnniNZvodsmRdeApuxCy7kJltwCY7lrHzi1t9tDK1g%2BcTdnBA28zZAztyTfu6AFZeOEJBQeqjm3FZzLUOjHJstt0zxhjRura5C6UlZVwT89NAs6Su7AFdaYbzQ6gHXK%2B55Z%2BzhN3XIINwVN0aoGsHHTWE6sV3%2BVj9beO6D6206auFe8OPlX3r%2B4hIFDjx8i%2F6HQ%2BxI9O9BVdr2ym2dXdPqUcLrVmB%2Fyyvhsytua6Gyk0urnSta8Et2Db6IN%2F6591zud3x6cycGbAnq0NzErqKJXekGomPWiZ6gY%2FuOZYNsfnO5eNf4jW0ZrAVVbg5895pS31enxOEVO1a9F0mUcd%2BpaKnro%2FWIy3YPOWuAu2CJ60i%2B0GuwawHPpCMaUA6nob3SipMMvosW3xcRNzAejr8NhtPBaIoreOP7%2BPlgdEoysOJB%2BsJyVdPi12GF6EpIDxYnE%2FdgxFr7QsfJQ0JQ2CIUEM1wy5u9AV7gxGd32vQH99oorNZcBiyX7LuU7KGru%2Bfg8NTynVdXOH9P1NZFVGw2Oc3ZL%2F4IElkTpNy3yvibHn5knPWM0TChYPHKmfacccoZJ5zzxCnlTHrO6bDn0NF6ElV0h05bf2MrepzsnD50LM2reNUxFiOrC8bCgsGGovwIx49w%2FAjHf4Wj4TV0uYRzso01m41yJnAk3C3Y2whMhu0M%2Fti0eUa%2FaV6witOHUG11MC%2FrsegIFMHB%2BYVO%2FsI0GJciQXZ5PyFQYyj7goIBXUsuyyA5TXUD3orStZKlbjA5hdMKY9vjFre73R8EUyGD',
            },
            {
              title: 'Sales by geography',
              link: '/reports/measures/sales_overview?report_query=eJztVl1r2zAU%2FStGMOjAYUm2dG3etgzKYP2AlL6EYlT5xhXIktBHuhDy33ev7NROso7svXkIvkfnnnNl6V68YUEGBWzK5lyBz1jOxDN34X5tCcTwmfsZIWwaXISc%2BWfz0gJLrjwhoEAEKGfclTOjYq09my42TPOaNO6cKaMIpGxKAn4GqBf0l%2F0AL5y0QRr9iOslD7w1DvCbMlSU%2FppbK3WF4NO6sK3YNj%2FUz%2BaBh%2Bg7m5Za%2BB1%2Buvwu5y8uSeDIIzTofzikjJ7%2BA%2BjSuE65ibMbWj1JeNUI7Je8lCG75q6SOju7csb7ogE%2FZTcQinTkHzvPxX7Gh4MzseAE6GNzm7KKOmX1C0iOWSt6ho5Zc8kG2ez2av6m8YGtiM6BFusj372kvu%2BrU8%2FhFTtVvRPpKWPdfVFhqpP1iMu2jzmrgfvoiOtJvjArcCsJL6Qja9Aeu6E5UiED6eBldHhhZKpgPBx%2FGQwvBqMLXMEj38cvB6Nz0oEljyoUjuuKFj8PS0SXUgVw2JpYhJUrEwqTWg8JUWOK1EA0yx2v9zp4ji2f3Rvb7TwYqzFacRUxXLBvSrHHNm5fhMdtqzfurvThgaiNiyzZdHKes1%2F8CRSyJkh5aJTxmd5%2BYnztGKNhj4LBK%2Bei44z7nHGPc9lz6nMmHed82HFoax2JIjpEb1y4dSW9TnZJP9qW4WU66zQXE6udjIUDiwmFeJ%2BO79PxfTr%2BazpaXkE7l7BPNilm01HOJLaEvwN3l4DJsOnB7%2BtmntEz9QtGqfsQqpyJdreegpZAMzj6MDe9b5gax6XsIdu86xCocCqHggYDugquRFScurqG4KTwjaQwNU5O6Y0ucMNY4ma7%2FQPA3yAL',
            },
            {
              title: 'Product sales',
              link: '/reports/measures/total_sales?report_query=eJztVttqGzEQ%2FZVFUEhhTW23TmO%2FtS6kheYCDnkxYVG08lqglYQuaY3xv3dGq2Rluynue%2FxgPKMz58yuZg7eEi%2B85GRG7rQpvotmXSyo5K64tboOzDtSEram1t9tDKIgXFM3xwyZeRt4Sdxa%2F0qJFZUOM1xy5nk9p7aeaxla5chsuSWKtsiRqJFZ15j44Xm7xK%2FiG3fMCuOFVg9wXlNPk7Dnv7FCBuGuqDFCNZB83FQmke3KQ%2F5i4akPrpdJ0Mo950%2Bnf675i0okONLwXfY%2FFGJFxn%2FPVa1tz9zFxTWenkT81BHst7wSvriithGqOLu02rmqS34orrmv4tW%2F7zWX%2BxXvDu7EcMu4OhY3sapqY1XeQFQsEukZKKZhGxTzm8vFq8IHsixYyxXbHOnuFeW6L0qZwkvuVPaeJGOGvnNSppuT%2BRBLdg8laTl1wcar1J7KyqVOa9Fy5WAV9ie9JFRRufGCuSQBq4ubCycwphZGScTexsPxp8HwYjC6gBMYhv38dDA6RxG%2BokH6ylLV4OHHYQ3ZlZCeW1haaM%2BIJ%2B0rHZcSAEFBiVAcYYZa2u7t9gLMoIB%2B%2BnfitVEQPVEZIFySL1KShxSn%2Fh28EPnKVAvn7xHaqYiazCbnJflJH7kE1AQg9x0z%2FMZ7iYjPPWI0zCAQvGAuesw4x4wzzDRTyjGTHnM%2B7DH4aD0II7xep62%2FsTW%2BTjLFDz6WpnWcguiYEZU8s7LcQEHF3nzzzTfffPNfvmlow5MvwZ5sY0xmo5IIWAl3y%2B1tTEyG3Q5%2B3XR%2Bhr9xXyCK2wepxupgns9jkADozsH5hc7%2B3bRglyLL7Mp%2BQ3gDlu0rNAZQZVSyICludcu9BcPuKJluwTmF06qCB4YWt7vdH6CeL1I%3D',
            },
            {
              title: 'Inventory sales matching',
              link: '/reports/measures/sales_on_hand_inventory?report_query=eJztVk1PGzEQ%2FSsrn4OUpA2FvUEqVahFpQpwiaKV2R0Sq157ZY%2FTRlH%2Be2e8G9Yk0I9TL%2BSAMs9v3nzYM2QrUKEGkYuZ1OCztc%2BuzBoMWrfJriWWK2WWYiDKlXR4u2mYSeZK%2BikjIkcXYCD8yv7ogEepPSOgoUSoptJVU6tDbbzI51thZM0aN85WocTsqpZLYH1bMaw6s5Iou2h7SAflr2XTcD65aFr%2Foj3dDQ6Fe8krhHrOf7KP4EunGlTWLJ7HQPiJRyEeNkUXJdW%2Fl05Jk%2Bivn4A%2FCJqgdSI0%2B3zXi%2BzL8d%2FDP2UWHRLRr64C57OpDWmKc8twUTJ6ULkJ9QO4owiJQyp%2Fa1HqLL6URP2Ts9636IF6GZwDU26O9JGFCh%2BFEv07o5CUrK4S%2BW%2BB2qtwE%2FGDABWU9AL0kT5Ts71jGqF%2F3E%2BnfaSLEgPVd8z5y6iv%2Br%2Bcwb3UAX4Xfmr94X292tEXncVuMRA1SB8cO8d%2BF9YUK2mqQu2pHEHVYDwNRvu6%2BjftkYa6QBVTHw%2FH70%2BGZyejMzoBkniGn5%2BMTlkKHmXQWDhplnz4bsiX%2Bag00jvL55RQo9YWCxsHkQjBkIsywLRGOlk%2FWxQz2izZrW36PqFtDFnr2L18Li60FovO7rrkqScaXp4k5TE2vo2iKpFPTgfii3wATawJUdp74e98bZHxoWeMhgmFjCfOWc8Zp5xxwjlPIqWcSc85HfYcLq0nscUX6q3DOOiEnfOHy7Kyig8hrt%2FI6hZw4aAhBxrmtyX8toTflvD%2FWcINTUi38mgEt9EW%2BWggFA2FvwF3E4HJsB3vyw1vtsOud7PPI0TH%2FMuLkKWzoYn8vdGd8%2BoPHmc2%2BVVW02ZWCbKjwqUug5a8KWpAp0rf%2Bpa2pm2sPP27oNIpt%2B1u9wtQs3BL',
            },
            {
              title: 'Product profitability',
              link: '/reports/measures/profit_margin?report_query=eJztVttuGjEQ%2FZWVpUqptKhASxp4a6iUVm2aSKR5QdHK7A6LVa9t%2BZIWIf49M2bDmqCoyXt4QMz4zDnjyxyxYV54CWzCbrTJvol6lV1bvRQ%2Bu%2BS2FoqiKpTesZyVK279zdoQGsMVd1PKsIm3AXLmVvpvm1hy6SgDEkoP1ZTbaqplaJRjk%2FmGKd4QR0tNzLqixHcPzZy%2Bsq%2FgSiuMF1rd4XrFPW%2BFPfyjChmEu%2BTGCFVjcrEuTEu2zff8t9wKrhL%2B%2B33iP4QqSJkQzX787khaocL9Ca%2FqLBYkpOfcRsYj4sV%2B4RXkj0WJwOE9nlxY7VyxS37IfoEvZlyCe981MD%2BsePfk5A3YEtRxFyZWFU2sShuIio%2FP6QQVs6iY9bLp1cXsWeEnsmWwFlS5PtI9KEp190qJwj73UvaOJGHGvlPSUtcv5iMs297lrAHugoWjg0Ma0YBy%2BOQPXzTOkcepKryIPQz7w0%2B9%2FllvcIYroKrD%2FLg3OCUqWPIgfWG5qmnxY7%2FC7FJIDxZHENsw4l77QscRQ0BQWCIUEMxwy5uDSZ3haGdoEN3evTbU8j2XAcM5%2ByIlu2vj9igcblw%2B84yF87cE3amIik1Gpzn7yRcgETVCyO2OGX%2FT%2BUfE5w4x6CcQDPaYsw4zTDHDBDNOlFLMqMOc9jsMba0DUUTX6LT1V7ai42Rj%2BtC2NK%2FibUf%2Fi6jWAQsLBguK8s0F31zwzQXJY2po3QenYRNjNhnkTODDd9dgr2Ni1N9N2vmaTOb4atpBpEFBAP0PwUxtdTCx4jFo18l9g%2FMznfxHadAmRZLZ5t3bhhoN2RdkCNhHyWUZJKdpbsBbUbodZakbdEzhtCrwCLDpzXb7AKM%2BG%2BE%3D',
            },
            {
              title: 'Product returns',
              link: '/board/347',
            },
          ],
        },
      ],
    },
    {
      section: 'My account',
      title: 'My account',
      icon: 'mdi-account',
      items: [
        {
          title: 'My reports',
          icon: 'mdi-chart-timeline-variant',
          type: 'single',
          link: '/my-reports',
        },
        {
          title: 'My dashboards',
          icon: 'mdi-chart-box-outline',
          type: 'single',
          link: '/my-dashboards',
        },
        {
          title: 'Product segmentation',
          icon: 'mdi-segment',
          type: 'group',
          items: [
            {
              title: 'Product segment listing',
              link: '/product-segment',
            },
            {
              title: 'Product segment builder',
              link: '/product/explore',
            },
          ],
        },
        {
          title: 'Report scheduling',
          icon: 'mdi-bell-ring',
          type: 'group',
          items: [
            {
              title: 'Scheduled reports',
              link: '/schedules',
            },
            {
              title: 'Schedule profiles',
              link: '/schedule/profiles',
            },
            {
              title: 'Schedule templates',
              link: '/schedule/templates',
            },
            {
              title: 'Email templates',
              link: '/email-templates',
            },
            {
              title: 'Email receivers',
              link: '/email-receivers',
            },
          ],
        },
        {
          title: 'Settings',
          icon: 'mdi-cogs',
          type: 'group',
          items: [
            {
              title: 'My subscription',
              link: '/subscription',
            },
            {
              title: 'Importing status',
              link: '/importing-status',
            },
            {
              title: 'Store setting',
              link: '/settings',
            },
            {
              title: 'Plugins',
              link: '/plugins',
            },
            {
              title: 'User management',
              link: '/users',
            },
            {
              title: 'Logout',
              link: '/logout',
            },
          ],
        }
      ],
    },

  ];

  public FullMenu: any[] = [
    {
      section: 'my-reports',
      title: 'My Reports',
      items: [
        {
          title: 'Reports library',
          icon: 'mdi-library',
          type: 'single',
          link: '/reports/0/all-categories',
        },
        {
          title: 'My reports',
          icon: 'mdi-chart-timeline-variant',
          type: 'single',
          link: '/my-reports',
        },
        {
          title: 'My dashboards',
          icon: 'mdi-chart-box-outline',
          type: 'single',
          link: '/my-dashboards',
        },
      ],
    },
    {
      section: 'Analytics',
      title: 'Analytics',
      items: [
        {
          title: 'Key metrics',
          icon: 'mdi-numeric',
          type: 'group',
          items: [
            {
              title: 'Business overview',
              link: '/board/117/public',
            },
            {
              title: 'Sales metrics',
              link: '/board/336',
            },
            {
              title: 'Finance metrics',
              link: '/board/335',
            },
            {
              title: 'Inventory metrics',
              link: '/board/337',
            },
          ],
        },
        {
          title: 'Inventory replenishment',
          icon: 'mdi-invoice-clock',
          type: 'group',
          items: [
            {
              title: 'Replenishment metrics',
              link: '/board/1394',
            },
            {
              title: 'Reorder suggestion',
              link: '/reports/36/inventory-replenishment/672',
            },
            {
              title: 'Just-in-time model',
              link: '/reports/36/inventory-replenishment/622',
            },
            {
              title: 'Fixed time period',
              link: '/reports/36/inventory-replenishment/627',
            },
            {
              title: 'Fixed order quantity',
              link: '/reports/36/inventory-replenishment/632',
            },
            {
              title: 'Min/Max inventory quantity',
              link: '/reports/36/inventory-replenishment/602',
            },
            {
              title: 'Min/max stock cover days',
              link: '/reports/36/inventory-replenishment/601',
            },
            {
              title: 'Replenishment for bundle',
              link: '/reports/36/inventory-replenishment/608',
            },
          ],
        },
        {
          title: 'Demand forecasting',
          icon: 'mdi-chart-timeline-variant-shimmer',
          type: 'group',
          items: [
            {
              title: 'Historical sales-based',
              link: '/reports/38/demand-forecasting/609',
            },
            {
              title: 'Inventory movement-based',
              link: '/reports/38/demand-forecasting/661',
            },
          ],
        },

        {
          title: 'Inventory tracking',
          icon: 'mdi-store',
          type: 'group',
          items: [
            {
              title: 'Inventory tracking overview',
              link: '/board/1460/public',
            },
            {
              title: 'Inventory on-hand',
              link: '/reports/31',
            },
            {
              title: 'Low stock',
              link: '/card/view/1276',
            },
            {
              title: 'Out-of-stock',
              link: '/reports/288',
            },
            {
              title: 'Inventory remaining days',
              link: '/reports/212',
            },
            {
              title: 'Inventory movement',
              link: '/reports/291',
            },
            {
              title: 'Month-end inventory',
              link: '/reports/350',
            },
            {
              title: 'Inventory value',
              link: '/reports/595',
            },
          ],
        },
        {
          title: 'Inventory optimization',
          icon: 'mdi-cellphone-cog',
          type: 'group',
          items: [
            {
              title: 'Slow-moving product',
              link: '/reports/56',
            },
            {
              title: 'Dead stock',
              link: '/reports/207',
            },
            {
              title: 'Aging stock',
              link: '/reports/562',
            },
            {
              title: 'Sell-through rate',
              link: '/reports/186',
            },
            {
              title: 'Inventory turnover rate',
              link: '/reports/345',
            },
            {
              title: 'Inventory to sales',
              link: '/reports/642',
            },
            {
              title: 'Stock availability',
              link: '/reports/668',
            },
            {
              title: 'Stockout revenue analysis',
              link: '/board/372',
            },
          ],
        },
        {
          title: 'Sales performance',
          icon: 'mdi-chart-bar',
          type: 'group',
          items: [
            {
              title: 'Sales overview',
              link: '/board/64/public',
            },
            {
              title: 'Daily sales',
              link: '/reports/measures/sales_overview?dimension=by_day&title=Daily%20Sales',
            },
            {
              title: 'Sales by geography',
              link: '/reports/measures/sales_overview?dimension=by_city&title=Sales%20by%20City',
            },
            {
              title: 'Sales by locations',
              link: '/reports/measures/sales_overview?dimension=by_location&title=Sales%20by%20Location',
            },
            {
              title: 'POS sales',
              link: '/reports/measures/total_sales?dimension=by_product&pivot_option=_rows_Location%5BLocation%5D_cols_%5BQuantitySold%5D&params=ShowTop__topn__text__single__All',
            },
          ],
        },
        {
          title: 'Product analytics',
          icon: 'mdi-tshirt-crew',
          type: 'group',
          items: [
            {
              title: 'Product sales',
              link: '/reports/measures/total_sales?dimension=by_product&analytic=TopHigh&title=Top%20High%20Sales%20Products',
            },
            {
              title: 'Inventory sales matching',
              link: '/reports/measures/sales_on_hand_inventory?dimension=by_variant&title=Sales%20vs%20Inventory%20Matching',
            },
            {
              title: 'Product profitability',
              link: '/reports/measures/ProfitMargin?dimension=by_product&analytic=TopHigh&title=Top%20High%20Profit%20Margin%20Products',
            },
            {
              title: 'Product returns',
              link: '/board/347',
            },
          ],
        },
      ],
    },

    {
      section: 'product-features',
      title: 'Product features',
      items: [
        {
          title: 'Product segmentation',
          icon: 'mdi-segment',
          type: 'group',
          items: [
            {
              title: 'Product segment listing',
              link: '/product-segment',
            },
            {
              title: 'Product segment builder',
              link: '/product/explore',
            },
          ],
        },
        {
          title: 'Report scheduling',
          icon: 'mdi-bell-ring',
          type: 'group',
          items: [
            {
              title: 'Scheduled reports',
              link: '/schedules',
            },
            {
              title: 'Schedule profiles',
              link: '/schedule/profiles',
            },
            {
              title: 'Schedule templates',
              link: '/schedule/templates',
            },
            {
              title: 'Email templates',
              link: '/email-templates',
            },
            {
              title: 'Email receivers',
              link: '/email-receivers',
            },
          ],
        },
        {
          title: 'Plugins',
          icon: 'mdi-toy-brick-search',
          type: 'single',
          link: '/plugins',
        },
        {
          title: 'User management',
          icon: 'mdi-account-multiple-plus',
          type: 'single',
          link: '/users',
        },
        {
          title: 'Recently downloaded files',
          icon: 'mdi-download',
          type: 'single',
          link: '/download-manager',
        }
      ],
    },
    {
      section: 'store-settings',
      title: 'Store settings',
      items: [
        {
          title: 'My subscription',
          icon: 'mdi-account-credit-card',
          type: 'single',
          link: '/subscription',
        },
        {
          title: 'Importing status',
          icon: 'mdi-autorenew',
          type: 'single',
          link: '/importing-status',
        },
        {
          title: 'Store setting',
          icon: 'mdi-cogs',
          type: 'single',
          link: '/settings',
        },
        {
          title: 'Book live demo',
          icon: 'mdi-calendar-account',
          type: 'single',
          link: '/live-demo',
        },
        {
          title: 'Logout',
          icon: 'mdi-logout',
          type: 'single',
          link: '/logout',
        },
      ],
    },
  ];
  public demoStoreName: string = 'assisty-demo.myshopify.com';

  public get avatarColor() {
    const s = this.store.information.name[0].toUpperCase();
    const value = s.charCodeAt(0);
    return avatarColor[value % avatarColor.length];
  }

  public get isTestingStore() {
    const testingStores = [
      'dariustest2.myshopify.com',
      'the-best-mega-store.myshopify.com',
      '2dba1f-4.myshopify.com',
    ];
    return testingStores.includes(this.store.shopName);
  }

  public created() {
    //this.checkIsMenuPinned();
  }
  public checkIsMenuPinned() {
    //get isPinMenu from local storage
    let isPinMenu = localStorage.getItem("isPinMenu") || "false";
    if (isPinMenu === "true") {
      this.canExpandOnHover = false;
      this.mini = false;
    } else {
      this.canExpandOnHover = true;
      this.mini = true;
    }
  }
  public searchReport() {
    this.$router.push('/reports/0/all-categories?keyword=' + this.search);
    this.search = '';
  }

  public doLogout() {
    this.$emit('logout');
  }

}
