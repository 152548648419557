import { get } from '@/services/http';
import { BundleItemModel } from '@/models/bundle_item';
import List from './list';

export class BundleItemList extends List<BundleItemModel> {

  public mapData(data: any = []) {
    for (const s of data) {
      const item: BundleItemModel = new BundleItemModel();
      item.mapData(s);
      this.add(item);
    }
  }

  public removeItem(item: BundleItemModel) {
    this.items = this.items.filter(i => i.variantId !== item.variantId);
  }

  public addItem(item: BundleItemModel) {
    //need to check if the item already exists
    const existingItem = this.items.find(i => i.variantId === item.variantId);
    if (existingItem) {
      existingItem.quantity = Number(existingItem.quantity) +  Number(item.quantity);
      return;
    }
    this.add(item);
  }
}
