
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ComparisonDateModel } from '@/models/comparison_date';
import { ComparisonDateList } from '@/collections/comparison_date';
import dateFormat from 'dateformat';

@Component({})
export default class ComparisonDate extends Vue {
  @Prop({ default: null })
  public value!: ComparisonDateModel | null;
  @Prop({ default: () => new ComparisonDateModel() })
  public initComparisonDate!: ComparisonDateModel;
  public comparisonDates: ComparisonDateList = new ComparisonDateList();
  public comparisonDate: ComparisonDateModel = new ComparisonDateModel();
  public comparisonDateName: string = 'None';
  public comparisonDateMenu: boolean = false;
  public keyword: string = '';
  @Prop({ default: true })
  public outlined!: boolean;
  public comparisonItems: any = [
    {name: 'None', code: ''},
    {name: 'Previous Period', code: 'last_period'},
    {name: 'Same day previous week', code: 'last_week'},
    {name: 'Same day previous month', code: 'last_month'},
    {name: 'Same day previous quarter', code: 'last_quarter'},
    {name: 'Same day previous year', code: 'last_year'},
  ];

  public created() {
    for (const item of this.comparisonItems) {
      const period = new ComparisonDateModel();
      period.mapData(item);
      this.comparisonDates.add(period);
    }
    this.comparisonDate = this.initComparisonDate;
  }

  public selectComparisonDate() {
    this.$emit('input', this.comparisonDate);
  }
}
