import axios from 'axios';
import router from '@/router';
import store from '../store';
import { Location } from 'vue-router';
// import * as Sentry from '@sentry/browser';

const host = process.env.VUE_APP_BACKEND_API_URL;

// build http header
function buildHeader(): { [key: string]: string } {
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Accept': 'application/json',
  };
}

function getCurrentUrl() {
  let currentUrl: string = document.location.href;
  if (window.location != window.parent.location) {
    currentUrl = document.referrer;
  }
  return currentUrl;
}

function isEmbbedApp() {
  return getCurrentUrl().includes('admin.shopify.com');
}

// function get Identity Token
export function verifyIdentityToken() {
  if (isEmbbedApp()) {
    // @ts-ignore: Unreachable code error
    const storeName = window.SHOP_NAME;
    let tokenOptions: any = localStorage.getItem('token_options');
    if (tokenOptions) {
      tokenOptions = JSON.parse(tokenOptions);
      if (Object.keys(tokenOptions).includes(storeName)) {
        setIdentityToken(
          tokenOptions[storeName].token,
          tokenOptions[storeName].shop_name,
        )
        return true;
      }
    }
    return false;
  }
  return true;
}

export function getIdentityToken() {
  // @ts-ignore: Unreachable code error
  if (isEmbbedApp() || window.SHOP_NAME) {
    // @ts-ignore: Unreachable code error
    const storeName = window.SHOP_NAME;
    let tokenOptions: any = localStorage.getItem('token_options');
    if (tokenOptions) {
      tokenOptions = JSON.parse(tokenOptions);
      if (Object.keys(tokenOptions).includes(storeName)) {
        setIdentityToken(
          tokenOptions[storeName].token,
          tokenOptions[storeName].shop_name,
        )
      }
    }
  }
  return localStorage.getItem('token') || '';
}

export function setIdentityToken(token: string, storeName: string) {
  localStorage.setItem('token', token);
  localStorage.setItem('shopify_store', storeName);

  // @ts-ignore: Unreachable code error
  window.SHOP_NAME = storeName;

  let tokenOptions: any = localStorage.getItem('token_options');
  if (tokenOptions) {
    tokenOptions = JSON.parse(tokenOptions);
  } else {
    tokenOptions = {};
  }
  tokenOptions[storeName] = { token, shop_name: storeName };
  localStorage.setItem('token_options', JSON.stringify(tokenOptions));
}

export function removeIdentityToken() {
  const storeName = localStorage.getItem('shopify_store') || '';
  let tokenOptions: any = localStorage.getItem('token_options');
  if (tokenOptions) {
    tokenOptions = JSON.parse(tokenOptions);
    if (Object.keys(tokenOptions).includes(storeName)) {
      delete tokenOptions[storeName];
    }
  } else {
    tokenOptions = {};
  }
  localStorage.setItem('token_options', JSON.stringify(tokenOptions));
  localStorage.removeItem('token');
}

export let ax = axios.create({
  baseURL: host,
  headers: buildHeader(),
  timeout: 50000,
  responseType: 'json',
  transformRequest: [
    (data) => {
      return data;
    },
  ],
  transformResponse: [
    (data) => {
      if (data) {
        return data;
      } else {
        return {};
      }
    },
  ],
});

ax.interceptors.response.use((config) => {
  return config;
}, (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.clear();
    store.commit('logoutSuccess');
    router.push('/');
  }
  // Sentry.captureException(error);
  return Promise.reject(error);
});

/* tslint:disable-next-line */
function processData(data?: any) {
  if (data instanceof FormData) {
    // data.append('token', token)
  } else {
    // data.token = token
  }

  return data;
}

export function get<T>(url: string, data?: any, cancelToken: any = null): Promise<T> {
  const identity: string = getIdentityToken();
  if (ax.defaults.cancelToken && cancelToken === null) {
    ax.defaults.cancelToken = undefined;
  }
  if (cancelToken) {
    ax.defaults.cancelToken = cancelToken;
  }
  return ax
    .get(url, {
      params: processData(data),
      headers: {
        'Authorization': identity ? `Bearer ${identity}` : '',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    })
    .then((res) => {
      if (typeof res.data === 'string') {
        return JSON.parse(res.data);
      } else {
        return res.data;
      }
    });
}

export function post<T>(url: string, data?: any, isForm: boolean = false, headers?: any): Promise<T> {
  const identity: string = getIdentityToken();
  data = processData(data);
  let contentType = 'application/json';
  if (isForm) {
    // contentType = 'application/form-data';
    contentType = 'multipart/form-data';
  } else {
    data = JSON.stringify(data);
  }
  return ax
    .post(url, data, {
      headers: {
        'Authorization': identity ? `Bearer ${identity}` : '',
        'Content-Type': contentType,
        ...headers,
      },
    })
    .then((res) => {
      return JSON.parse(res.data);
    });
}

export function put<T>(url: string, data?: any): Promise<T> {
  const identity: string = getIdentityToken();
  return ax
    .put(url, JSON.stringify(processData(data)), {
      headers: { Authorization: identity ? `Bearer ${identity}` : '' },
    })
    .then((res) => {
      return JSON.parse(res.data);
    });
}

export function del<T>(url: string, data?: any): Promise<T> {
  const identity: string = getIdentityToken();
  return ax
    .delete(url, {
      data: JSON.stringify(processData(data)),
      headers: { Authorization: identity ? `Bearer ${identity}` : '' },
    })
    .then((res) => {
      return JSON.parse(res.data);
    });
}
