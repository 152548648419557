
import { Component, Watch, Prop } from 'vue-property-decorator';
import Report from '@/components/Report';
import { PivotDataModel } from '@/models/pivot_data';
import ChatWithUs from '@/components/ChatWithUs.vue';
import LoadingSlider from '@/components/LoadingSlider.vue';
import ContentLoadResultMessage from '@/components/ContentLoadResultMessage.vue';
import DrilldownTableReport from '@/components/DrilldownTableReport.vue';
import { DimensionModel } from '@/models/dimension';
import { FilterColumnList } from '@/collections/filter_columns';
import { FilterColumnModel } from '@/models/filter_column';
import { EventHandler } from '@/modules/events';
import { StoreModel } from '@/models/store';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import { RuleModel } from '@/models/rule';
import FilterColumn from '@/components/FilterColumn.vue';
import Selector from '@/components/OptionSelector.vue';
import dateFormat from 'dateformat';
import Alert from '@/components/Alert.vue';
import { EventBus } from '@/main';
import { TemplateReportList } from '@/collections/template_reports';
import ActionButton from '@/components/ActionButton.vue';
import DTotal from '@/components/DTotal.vue';
import { MeasureList } from '@/collections/measures';
import ColumnViewListing from '@/components/ColumnViewListing.vue';
import FilterViewListing from '@/components/FilterViewListing.vue';
import { get } from '@/services/http';
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';
import ProductSegmentDialog from '@/components/ProductSegmentDialog.vue';
import DownloadFullData from '@/components/DownloadFullData.vue';
import { RelatedLinkList } from '@/collections/related_links';
import { ReportModel } from '@/models/report';


// Declare global objects
declare const gapi: any;
declare const google: any;

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

interface FilterView {
  name?: string;
  filter: FilterColumnList;
  isDefault?: boolean;
}

interface ColumnView {
  name?: string;
  reportColumns: [];
  isDefault?: boolean;
}

interface Header {
  text?: string;
  value: string;
  class?: string | string[];
  cellClass?: string | string[];
  sortable?: boolean;
  filterable?: boolean;
  description?: string;
  colorIndex?: boolean;
  width?: string;
  align?: string;
  dataType?: string;
}

@Component({
  components: {
    ChatWithUs,
    LoadingSlider,
    ContentLoadResultMessage,
    DrilldownTableReport,
    FeatureLockedNotify,
    FilterColumn,
    Selector,
    Alert,
    ActionButton,
    DTotal,
    ColumnViewListing,
    FilterViewListing,
    GoogleDriveSelector,
    ProductSegmentDialog,
    DownloadFullData,
  },
})
export default class TableReport extends Report {

  public get getTableHeight() {
    let tableHeight = document.documentElement.clientHeight;
    /* if (tableHeight < 700) { */
    /*   return tableHeight + 300; */
    /*   /1* return 'auto'; *1/ */
    /* } */

    return tableHeight;
  }

  public get dateRangeText() {
    return `${dateFormat(this.filterTimerange[0], 'mmm d, yyyy', true)} - ${dateFormat(this.filterTimerange[1], 'mmm d, yyyy', true)}`;
  }

  public get reportHeight() {
    let rowCount = this.report.rows.length;
    return rowCount * 100;
  }

  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public typeReport: string = 'TABLE';
  public headers: Header[] = [];
  public rows: any = [];
  public dialogPivot: boolean = false;
  public dialogReportWrongData: boolean = false;
  public wrongDataMessage: string = '';
  public pivotTable: PivotDataModel = new PivotDataModel();
  public pivotTableData: any[] = [];
  public pivotTableHeader: any[] = [];
  @Prop({default: {}})
  public initOptions!: Option;
  public options: Option = {};
  public tableLoading: boolean = false;
  public enablePivot: boolean = false;
  public fisrtTimeInitOption: boolean = false;
  public columnNames: any = {
    productName: 'Item[Item Description]',
  };
  public error: boolean = false;
  @Prop({ default: [] })
  public filterReportColumns!: any;
  @Prop({ default: [] })
  public selectedReportColumns!: any;
  @Prop({default: () => new MeasureList()})
  public measures!: MeasureList;
  public loadingSlides: string[] = [
    'Connecting to analytic services',
    'Fetching data',
    'Building report',
    'Downloading data',
    'Generating datatable',
  ];
  public notEmptyRules: any = [
    this.isNameExist,
  ];
  public needCount: boolean = true;
  public isResetOptions: boolean = false;
  public dialogDrilldown: boolean = false;
  public dialogDrilldownDatatable: boolean = false;
  public drilldownItem: any = {};
  public drilldownDimension: any = '';
  public drilldownFilterColumns: FilterColumnList = new FilterColumnList();
  public selectedFilterColumn: FilterColumnModel = new FilterColumnModel();
  public selectedDrilldownDimension: DimensionModel = new DimensionModel();
  public selectedCellItem: any;
  public rule: RuleModel = new RuleModel({ code: 'tracked_metrics_count' });
  public popularDimensions: any = [];
  public selectingDimensions: any = [];
  public itemFilterPosition: string = '';
  public showItemFilterMenu: boolean = false;
  public menuX: number = 0;
  public menuY: number = 0;
  public headerMenuY: number = 0;
  public headerMenuX: number = 0;
  public showHeaderOptionMenu: boolean = false;
  public selectingFilterValue: any = { columnCode: '', item: {} };
  public selectingFilterItem: any = {};
  public downloadFileExt: string = 'csv';
  public operatorButtons: any = {
    textOperators: [
      {
        value: 'eq',
        icon: 'mdi-equal',
      },
      {
        value: 'ne',
        icon: 'mdi-not-equal-variant',
      },
      {
        value: 'contains',
        icon: 'mdi-contain',
      },
    ],
    numberOperators: [
      {
        value: 'eq',
        icon: 'mdi-equal',
      },
      {
        value: 'ne',
        icon: 'mdi-not-equal-variant',
      },
      {
        value: 'gt',
        icon: 'mdi-greater-than',
      },
      {
        value: 'lt',
        icon: 'mdi-less-than',
      },
    ],
  };
  public itemTooltipMenu: boolean = false;
  public selectingDrilldownDashboardAction: any = {};
  public drilldownDashboardActions: any = {
    'Vendor Name': {
      report: '/dashboard/vendor',
      label: 'Vendor Detailed Dashboard',
      filterCode: 'Vendor Name',
      idParam: '',
    },
    'Location[Location]': {
      report: '/dashboard/store',
      label: 'Product Detailed Dashboard',
      filterCode: 'Location[Location]',
      idParam: '',
    },
    'Item[Item Description]': {
      report: '/dashboard/product',
      label: 'Product Detailed Dashboard',
      filterCode: 'Item[Item Description]',
      idParam: 'product_id',
    },
    'Item[Item Category]': {
      report: '/dashboard/collection',
      label: 'Collection Detailed Dashboard',
      filterCode: 'Item[Item Category]',
      idParam: '',
    },
  };
  public breakdownDimensionTree: any = [
    {
      dimension: {
        name: 'Product',
        code: 'by_product',
        columnCode: 'Item[Item Description]',
        filterCode: 'Item[Item Description]',
      },
      childDimension: [
        {
          name: 'Variant',
          code: 'by_variant',
          columnCode: 'variant',
        },
      ],
    },
    {
      dimension: {
        name: 'Vendor',
        code: 'by_vendor',
        columnCode: 'Vendor Name',
        filterCode: 'Vendor Name',
      },
      childDimension: [
        {
          name: 'Product',
          code: 'by_product',
          columnCode: 'Item[Item Description]',
        },
        {
          name: 'Variant',
          code: 'by_variant',
          columnCode: 'variant',
        },
      ],
    },
    {
      dimension: {
        name: 'Store Location',
        code: 'by_location',
        columnCode: 'Location[Location]',
        filterCode: 'Location[Location]',
      },
      childDimension: [
        {
          name: 'Collection',
          code: 'by_category',
          columnCode: 'Item[Item Category]',
        },
        {
          name: 'Product',
          code: 'by_product',
          columnCode: 'Item[Item Description]',
        },
        {
          name: 'Variant',
          code: 'by_variant',
          columnCode: 'variant',
        },
      ],
    },
    {
      dimension: {
        name: 'Collection',
        code: 'by_category',
        columnCode: 'Item[Item Category]',
        filterCode: 'product_collections',
      },
      childDimension: [
        {
          name: 'Product',
          code: 'by_product',
          columnCode: 'Item[Item Description]',
        },
        {
          name: 'Variant',
          code: 'by_variant',
          columnCode: 'variant',
        },
      ],
    },
    {
      dimension: {
        name: 'Sales Channel',
        code: 'sales_channel_name',
        columnCode: 'sales_channel_name',
        filterCode: 'sales_channel_name',
      },
      childDimension: [
        {
          name: 'Collection',
          code: 'by_category',
          columnCode: 'Item[Item Category]',
        },
        {
          name: 'Product Type',
          code: 'by_product_type',
          columnCode: 'product_type',
        },
        {
          name: 'Product',
          code: 'by_product',
          columnCode: 'Item[Item Description]',
        },
        {
          name: 'Variant',
          code: 'by_variant',
          columnCode: 'variant',
        },
      ],
    },
    {
      dimension: {
        name: 'Product Type',
        code: 'by_product_type',
        columnCode: 'product_type',
        filterCode: 'product_type',
      },
      childDimension: [
        {
          name: 'Product',
          code: 'by_product',
          columnCode: 'Item[Item Description]',
        },
        {
          name: 'Variant',
          code: 'by_variant',
          columnCode: 'variant',
        },
      ],
    },
  ];
  public selectingBreakdownTreeItem: any = {};
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  public selectedDrilldownReportColumns: any = [];
  public fullscreenTable: boolean = false;
  public datatableClass: string = '';
  public isCopied: boolean = false;
  @Prop({default: () => []})
  public filterViews!: FilterView[];
  @Prop({default: () => []})
  public columnViews!: ColumnView[];
  @Prop({default: null})
  public activeFilterView!: FilterView;
  @Prop({default: null})
  public activeColumnView!: ColumnView;
  @Prop({default: false})
  public canCreateViewForTemplateReport!: boolean;
  public downloadDialog: boolean = false;
  public generatePurchaseOrderDialog: boolean = false;
  public relatedLinks: RelatedLinkList = new RelatedLinkList();
  public loadingRelatedLinks: boolean = false;

  public async created() {
    EventBus.$on('show-download-dialog', async () => {
      this.downloadDialog = true;
    });

    EventBus.$on('show-generate-po-dialog', async () => {
      this.generatePurchaseOrderDialog = true;
    });
    for (const rule of this.store.currentPackage.rules.items) {
      if (rule.code === this.rule.code) {
        this.rule = rule;
        break;
      }
    }
    this.report.typeReport = this.typeReport;
    this.report.isDetail = this.isDetail;
    if (this.measure.dimensions.items.length > 0) {
      this.drilldownDimension = this.measure.dimensions.items[0].code;
    }
    this.report.pivotOption = this.pivotOption;
    this.initPopularDimensions();
  }

  public get isAbleToChangeColumnName() {
    return this.$route.name === 'customReport';
  }

  public turnFullscreenTable() {
    this.fullscreenTable = !this.fullscreenTable;
    if (this.fullscreenTable) {
      this.datatableClass = 'datatable_scroll';
    } else {
      this.datatableClass = '';
    }
    if (this.fullscreenTable) {
      this.sendMixPanelEvent('resize fullscreen table');
    }
    this.$emit('turn-fullscreen-table', this.fullscreenTable);
  }

  public reloadData() {
    EventBus.$emit('reload-report-data');
  }

  public isNoPrintColumn(header: any) {
    if (header.value === 'actions') {
      return true;
    }
    return false;
  }

  public hasBreakdownChild(childDimension: any) {
    if (childDimension) {
      for (const child of childDimension) {
        if (this.canDrilldownDimension(child.code)) {
          return true;
        }
      }
    }
    return false;
  }

  public getSelectingBreakdownTreeItem() {
    for (const item of this.breakdownDimensionTree) {
      if (item.dimension.columnCode === this.selectingFilterValue.columnCode) {
        this.selectingBreakdownTreeItem = item;
      }
    }
  }

  public getChildBreakdownLink(childDimension: any) {
    if (!this.canDrilldownDimension(childDimension.code)) {
      return '#';
    }
    let link = `/reports/measures/${this.measure.code}`;
    link = link + '?dimension=' + childDimension.code;
    if (this.analyticsType.code && this.analyticsType.code !== undefined) {
      link = link + '&analytic=' + this.analyticsType.code;
    }
    if (this.filterTimerange && this.filterTimerange.length >= 2) {
      link = link + '&start_time=' + this.filterTimerange[0];
      link = link + '&end_time=' + this.filterTimerange[1];
    }
    const filterParams = [];
    const currentItemFilterValue = this.getCurrentItemFilterValue();
    filterParams.push(currentItemFilterValue);
    if (this.filterColumns && this.filterColumns.items.length > 0) {
      for (const item of this.filterColumns.items) {
        filterParams.push(`${item.name}__${item.code}__${item.operator}__${item.value.join('_or_')}__${item.dataType}`);
      }
    }
    link = link + '&filter=' + filterParams.join('_col_');

    if (this.pivotOption && this.pivotOption !== undefined) {
      link = link + '&pivot_option=' + this.pivotOption;
    }
    if (this.reportParams && this.reportParams.items.length > 0) {
      const params = [];
      for (const item of this.reportParams.items) {
        params.push(`${item.name}__${item.code}__${item.dataType}__${item.valueType}__${item.value.join('_or_')}`);
      }
      if (params.length > 0) {
        link = link + '&params=' + params.join('_param_');
      }
    }
    return link;
  }

  public getCurrentItemFilterValue() {
    const name = this.selectingBreakdownTreeItem.dimension.name;
    const code = this.selectingBreakdownTreeItem.dimension.filterCode;
    const value = this.selectingFilterItem[this.selectingBreakdownTreeItem.dimension.columnCode];
    const operator = 'eq';
    const dataType = 'text';
    return `${name}__${code}__${operator}__${value}__${dataType}`;
  }

  public getDrilldownReportByDimension(columncode: string) {
    const dimensionCode: string = this.getDimensionCode(columncode);
    const shopify_store = localStorage.getItem('shopify_store') || '';
    const storeName = shopify_store.replace('.myshopify.com', '');
    let link = `/reports/measures/${this.measure.code}`;
    link = link + '?dimension=' + dimensionCode;
    if (this.analyticsType.code && this.analyticsType.code !== undefined) {
      link = link + '&analytic=' + this.analyticsType.code;
    }
    if (this.filterTimerange && this.filterTimerange.length >= 2) {
      link = link + '&start_time=' + this.filterTimerange[0];
      link = link + '&end_time=' + this.filterTimerange[1];
    }
    if (this.filterColumns && this.filterColumns.items.length > 0) {
      const filterParams = [];
      for (const item of this.filterColumns.items) {
        filterParams.push(`${item.name}__${item.code}__${item.operator}__${item.value.join('_or_')}__${item.dataType}`);
      }
      if (filterParams.length > 0) {
        link = link + '&filter=' + filterParams.join('_col_');
      }
    }

    if (this.pivotOption && this.pivotOption !== undefined) {
      link = link + '&pivot_option=' + this.pivotOption;
    }
    if (this.reportParams && this.reportParams.items.length > 0) {
      const params = [];
      for (const item of this.reportParams.items) {
        params.push(`${item.name}__${item.code}__${item.dataType}__${item.valueType}__${item.value.join('_or_')}`);
      }
      if (params.length > 0) {
        link = link + '&params=' + params.join('_param_');
      }
    }
    return link;
  }

  public async getRelatedLinks(columnCode: string, item: any) {
    const enableGettingRelatedLinks = [
      'variant',
      'Item[Item Description]',
      'Location[Location]',
      'Document No',
    ]
    this.relatedLinks = new RelatedLinkList();
    if (enableGettingRelatedLinks.includes(columnCode)) {
      const params: any = { code: columnCode, value: item[columnCode] };
      if (columnCode === 'variant') {
        for (const colName in item) {
          if (colName === 'Item[Item Description]') {
            params.extra = item[colName];
            break;
          }
        }
      }
      this.loadingRelatedLinks = true;
      await this.relatedLinks.fetch(params);
      this.loadingRelatedLinks = false;
    }
  }

  public addFilter(operator: string) {
    const item = this.selectingFilterValue.item;
    const columnCode = this.selectingFilterValue.columnCode;
    const filter: FilterColumnModel = new FilterColumnModel();
    filter.code = columnCode;
    filter.dataType = 'number';
    filter.name = this.getSelectingColumnName();
    filter.operator = operator;
    filter.value = [item[columnCode]];
    EventBus.$emit('add-new-filter', filter);
    this.showItemFilterMenu = false;
  }

  public async showFilterOption(columnCode: string, item: any) {
    if (columnCode === 'actions' || columnCode === 'image') {
      this.showItemFilterMenu = false;
      return;
    }

    await this.getRelatedLinks(columnCode, item);
    this.selectingFilterValue = { columnCode, item };
    this.selectingFilterItem = item;
    this.getSelectingBreakdownTreeItem();
  }

  public isActiveSort(header: any, isDesc: boolean = true) {
    if (!this.sortDesc || this.sortDesc.length === 0 || !this.sortBy || this.sortBy.length === 0) {
      return false;
    }
    let isSorted: boolean = false;
    let sortDesc: boolean = false;
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === header.value) {
        sortDesc = this.sortDesc[index];
        isSorted = true;
        break;
      }
      index++;
    }
    if (isSorted && sortDesc === isDesc) {
      return true;
    }
    return false;
  }

  public getSortIndex(headerCode: string) {
    if (!this.sortDesc || this.sortDesc.length < 2 || !this.sortBy || this.sortBy.length < 2) {
      return 0;
    }
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === headerCode) {
        return index + 1;
      }
      index++;
    }
    return 0;
  }

  public showSortArrow(headerCode: string, isDesc: boolean = true) {
    if (!this.sortDesc || this.sortDesc.length === 0 || !this.sortBy || this.sortBy.length === 0) {
      return false;
    }
    let isSorted: boolean = false;
    let sortDesc: boolean = false;
    let index: number = 0;
    for (const sortBy of this.sortBy) {
      if (sortBy === headerCode) {
        sortDesc = this.sortDesc[index];
        isSorted = true;
        break;
      }
      index++;
    }
    if (isSorted && sortDesc === isDesc) {
      return true;
    }
    return false;
  }

  public removeHeader(value: string) {
    let removeIndex: number = -1;
    let index: number = -1;
    for (const header of this.headers) {
      index++;
      if (header.value === value) {
        removeIndex = index;
      }
    }
    if (removeIndex !== -1) {
      this.headers.splice(removeIndex, 1);
    }
    this.saveReportHeaders();
  }

  public applySortColumn(header: any, sortDesc: boolean = true) {
    // check if the selecting column is sorted or not
    let isSorted: boolean = false;
    let index: number = 0;
    let isSortDesc: boolean = false;
    if (!this.options.sortBy || this.options.sortBy.length === 0) {
      this.options.sortBy = [];
      this.options.sortDesc = [];
    }
    for (const item of this.options.sortBy) {
      if (item === header.value) {
        isSorted = true;
        isSortDesc = this.options.sortDesc[index];
        break;
      }
      index++;
    }
    if (isSorted && isSortDesc === sortDesc) {
      // remove sort for this columns
      this.options.sortBy.splice(index, 1);
      this.options.sortDesc.splice(index, 1);
    } else if (isSorted && isSortDesc !== sortDesc) {
      // change direction of the sort for this column
      this.options.sortDesc[index] = sortDesc;
    } else {
      // add new sort for this columns
      this.options.sortBy.push(header.value);
      this.options.sortDesc.push(sortDesc);
    }
    this.optionChanged();
    this.sendMixPanelEvent(
      'Sort Table Column', {
        columnName: header.text, columnCode: header.value
      }
    );
  }

  public async copyCellValue() {
    const item = this.selectingFilterValue.item;
    const columnCode = this.selectingFilterValue.columnCode;
    const value = item[columnCode];
    this.isCopied = true;
    navigator.clipboard.writeText(value);
    EventBus.$emit('show-snackbar', 'Copied to clipboard');
  }

  public canDrilldownColumn(columnCode: string) {
    const dimensionCode = this.getDimensionCode(columnCode);
    if (this.dimension.code === dimensionCode) {
      return false;
    }
    for (const dimension of this.measure.dimensions.items) {
      if (dimension.code === dimensionCode) {
        return true;
      }
    }
    return false;
  }

  public canDrilldownDimension(dimensionCode: string) {
    if (dimensionCode === this.dimension.code) {
      return false;
    }
    for (const dimension of this.measure.dimensions.items) {
      if (dimension.code === dimensionCode) {
        return true;
      }
    }
    return false;
  }

  public canViewDetailedDashboard(columnCode: string) {
    return Object.keys(this.drilldownDashboardActions).includes(columnCode);
  }

  public getDetailedDashboardLink(column: Header, item: any) {
    const selectingAction = this.drilldownDashboardActions[column.value];

    let link = `${selectingAction.report}?name=${item[selectingAction.filterCode]}`;

    if (selectingAction.idParam && item[selectingAction.idParam] !== undefined) {
      link = `${link}&id=${item[selectingAction.idParam]}`;
    }
    if (this.filterTimerange.length === 3) {
      link = `${link}&start_time=${this.filterTimerange[0]}&end_time=${this.filterTimerange[1]}`;
    }
    return link;
  }

  public getDataTypeByColumnCode(code: string) {
    for (const column of this.report.columns) {
      if (column.code === code) {
        return column.dataType;
      }
    }
    return '';
  }

  public getSelectingColumnName() {
    const columnCode = this.selectingFilterValue.columnCode;
    return this.getColumnNameByColumnCode(columnCode);
  }

  public getColumnNameByColumnCode(code: string) {
    for (const column of this.report.columns) {
      if (column.code === code) {
        return column.name;
      }
    }
    return '';
  }

  public getDimensionCode(columnCode: string) {
    for (const column of this.report.columns) {
      if (column.code === columnCode) {
        return column.luisMapping;
      }
    }
    return '';
  }

  public doNotTranslateRowValue(item: any) {
    return 'notranslate';
  }

  public initPopularDimensions() {
    this.popularDimensions = [
      {
        type: 'Time Period',
        dimensions: [
          { id: 3, name: 'Day', code: 'by_day' },
          { id: 13, name: 'Week', code: 'by_week' },
          { id: 8, name: 'Month', code: 'by_month' },
          { id: 10, name: 'Quarter', code: 'by_quarter' },
          { id: 14, name: 'Year', code: 'by_year' },
        ],
      },
      {
        type: 'Business Object',
        dimensions: [
          { id: 9, name: 'Product', code: 'by_product' },
          { id: 157, name: 'Product Variant', code: 'by_variant' },
          { id: 161, name: 'Product Type', code: 'by_product_type' },
          { id: 2, name: 'Collection', code: 'by_category' },
          { id: 23, name: 'Store Location', code: 'by_location' },
          { id: 245, name: 'Fulfilled Location', code: 'fulfilled_location' },
          { id: 28, name: 'Customer', code: 'by_customer' },
          { id: 85, name: 'Vendor', code: 'by_vendor' },
          { id: 262, name: 'Sales Channel', code: 'sales_channel_name' },
          { id: 265, name: 'Staff Name', code: 'by_staff' },
          { id: 47, name: 'Payment Method', code: 'by_tender_type' },
        ],
      },
      {
        type: 'Address Location',
        dimensions: [
          { id: 165, name: 'Billing City', code: 'by_city' },
          { id: 164, name: 'Billing State', code: 'by_state' },
          { id: 163, name: 'Billing Country', code: 'by_country' },
          { id: 239, name: 'Shipping City', code: 'by_shipping_city' },
          { id: 240, name: 'Shipping State', code: 'by_shipping_state' },
          { id: 187, name: 'Shipping Country', code: 'by_shipping_country' },
          { id: 189, name: 'Shipping Postcode', code: 'by_shipping_postcode' },
        ],
      },
      {
        type: 'Order Attribute',
        dimensions: [
          { id: 203, name: 'Discount code', code: 'by_discount_code' },
          { id: 130, name: 'Order Number', code: 'document_no' },
          { id: 271, name: 'Order Value Range', code: 'by_order_value_range' },
          { id: 249, name: 'Order Line Item', code: 'Order Item' },
          { id: 237, name: 'Order Tags', code: 'by_order_tag' },
        ],
      },
      {
        type: 'Product Attributes',
        dimensions: [
          { id: 219, name: 'Variant Option', code: 'by_variant_option' },
          { id: 228, name: 'Product Type & Variant Option 1', code: 'by_product_type_variant_option_1' },
          { id: 229, name: 'Product Type & Variant Option 2', code: 'by_product_type_variant_option_2' },
          { id: 224, name: 'Collection & Variant Option 1', code: 'by_variant_option_collection' },
          { id: 227, name: 'Collection & Variant Option 2', code: 'by_collection_variant_option_2' },
          { id: 222, name: 'Vendor & Variant Option 1', code: 'by_variant_option_vendor' },
          { id: 225, name: 'Vendor & Variant Option 2', code: 'by_vendor_variant_option_2' },
          { id: 223, name: 'Location & Variant Option 1', code: 'by_variant_option_location' },
          { id: 226, name: 'Location & Variant Option 2', code: 'by_location_variant_option_2' },
          { id: 241, name: 'Product Properties', code: 'product_properties' },
          { id: 162, name: 'Product Tags', code: 'by_product_tag' },
        ],
      },
    ];
    this.getDimensionListSelectingMeasure();
  }

  public getDimensionListSelectingMeasure() {
    if (!this.measure.code) {
      this.selectingDimensions = this.popularDimensions;
      return;
    }

    const dimensionList: any = [];
    for (const group of this.popularDimensions) {
      const newGroup: any = { type: group.type, dimensions: [] };
      for (const d1 of group.dimensions) {
        for (const d2 of this.measure.dimensions.items) {
          if (d1.code === d2.code) {
            newGroup.dimensions.push(d1);
            break;
          }
        }
      }
      if (newGroup.dimensions.length > 0) {
        dimensionList.push(newGroup);
      }
    }
    this.selectingDimensions = dimensionList;
  }

  public sendMixPanelEvent(name: string = '', data: any = {}) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      data.measure = this.measure.code;
      data.dimension = this.dimension.code;
      eventHandler.track(name, data);
    } catch (e) {
      // ignore this
    }
  }

  public showDrilldownDataTable(code: any) {
    this.dialogDrilldown = false;
    if (code) {
      for (const dimension of this.measure.dimensions.items) {
        if (dimension.code === code) {
          this.selectedDrilldownDimension = dimension;
          this.dialogDrilldownDatatable = true;
          this.sendMixPanelEvent('Show drilldown table report', {drilldownDimension: code});
        }
      }
      this.loadSavedReportColumnsFromTemplate();
    }
  }

  public viewLink(link: any) {
    if (link) {
      this.$router.push(link);
    } else {
      this.$router.push('/reports');
    }
  }

  public drilldown(row: any) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`Show drilldown dialog`, {
        measure: this.measure.code,
        dimension: this.dimension.code,
      });
    } catch (e) {
      // ignore this
    }
    let data: any;
    this.drilldownFilterColumns = new FilterColumnList();
    for (const filter of this.filterColumns.items) {
      if (filter.code && filter.code !== undefined) {
        data = {
          name: filter.name,
          code: filter.code,
          value: filter.value,
          operator: filter.operator,
          dataType: filter.dataType,
        };
        this.drilldownFilterColumns.add(new FilterColumnModel(data));
      }

    }
    for (const fieldCode of Object.keys(row)) {
      for (const column of this.report.columns) {
        if (column.code === fieldCode) {
          if (column.dataType === 'text' || column.dataType === 'date') {
            let duplicate = false;
            for (const fc of this.drilldownFilterColumns.items) {
              if (fc.code === column.code) {
                duplicate = true;
              }
            }
            if (!duplicate) {
              if (row[fieldCode] !== 'N/A' && row[fieldCode] !== '-') {
                data = {
                  name: column.name,
                  code: column.code,
                  value: [row[fieldCode]],
                  operator: 'eq',
                  dataType: column.dataType,
                };
                this.drilldownFilterColumns.add(new FilterColumnModel(data));
              }
            }
          }
        }
      }
    }
    this.drilldownItem = row;
    this.dialogDrilldown = true;
  }

  public isNameExist(v: any) {
    return !!v || 'Please enter your name!';
  }

  public reload() {
    if (!this.report || !this.report.rows) {
      return;
    }
    this.generateReportColumns();

    if (this.report.rows.length === 0) {
      return;
    }
    let selectedReportColumns: any = this.selectedReportColumns;
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      selectedReportColumns = this.report.columns;
    }

    this.headers = [];
    this.rows = [];
    this.headers.push(
      {
        text: 'Actions',
        value: 'actions',
        class: 'table-header',
        cellClass: 'table-item font-weight-regular',
        sortable: false,
        width: '80px',
        align: 'center d-print-none',
      }
    );

    for (const item of selectedReportColumns) {
      for (const c of this.report.columns) {
        if (c.code !== item.code) {
          continue;
        }
        this.headers.push(
          {
            text: item.name,
            value: item.code,
            class: (item.sortable !== false) ? 'table-header' : ['table-header', 'non-sortable-header'],
            sortable: item.code !== 'image' && item.sortable !== false,
            filterable: item.code !== 'image' && item.filterable !== false,
            cellClass: (item.sortable !== false) ? 'table-item' : ['table-item', 'non-sortable-item'],
            description: c.description,
            colorIndex: c.colorIndex,
            dataType: c.dataType,
          });
      }
    }
    for (const index in this.report.rows) {
      if (this.report.rows[index]) {
        const res: any = {};
        for (const column of this.report.columns) {
          if (!this.isSelectedColumn(column.code)) {
            continue;
          }
          if (column.values[index]) {
            res[column.code] = column.values[index];
          } else {
            if ([
              'number',
              'decimal',
              'currency',
              'percent'
            ].includes(column.dataType)) {
              res[column.code] = '0';
            } else {
              res[column.code] = '-';
            }
          }
          // if (column.originalValues[index] === null && column.sortable === false) {
          //   res[column.code] = '';
          // }
        }
        this.rows.push(res);
      }
    }
    
  }

  public isSelectedColumn(code: string) {
    if (!this.selectedReportColumns || this.selectedReportColumns.length === 0) {
      return true;
    }
    for (const c of this.selectedReportColumns) {
      if (c.code === code) {
        return true;
      }
    }
    return false;
  }

  public resetOptions() {
    if (this.isCreated) {
      this.isResetOptions = true;
      this.page = 1;
      this.perPage = this.options.itemsPerPage;
      this.options = {};
      this.options.sortBy = this.sortBy;
      this.options.sortDesc = this.sortDesc;
    }
  }

  public resetSortOptions() {
    if (this.isCreated) {
      this.sortBy = [];
      this.sortDesc = [];
    }
  }

  public saveReportHeaders() {
    this.$emit('save-report-headers', this.headers);
  }

  public generateReportColumns() {
    const reportColumns: any = [];
    const reportColumnList: any = [];
    if (this.report && this.report.columns && this.report.columns.length > 0) {
      for (const c of this.report.columns) {
        reportColumns.push({
          name: c.name,
          code: c.code,
          dataType: c.dataType,
          luisMapping: c.luisMapping,
        });
      }
    }
    if (this.report && this.report.columnList && this.report.columnList.length > 0) {
      for (const c of this.report.columnList) {
        reportColumnList.push({
          name: c.name,
          code: c.code,
          dataType: c.dataType,
          luisMapping: c.luisMapping,
        });
      }
    }
    this.$emit('update-report-column', {reportColumns, reportColumnList});

  }

  public getDownloadExcelFileName() {
    let fileName: string;
    fileName = `${this.measure.name.split(' ').join('_').split('-').join('_')}`;
    fileName += `_group_by_${this.dimension.name.split(' ').join('_').split('-').join('_')}`;
    if (this.filterTimerange.length > 1) {
      fileName += `_from_${this.filterTimerange[0].split(' ').join('_').split('-').join('_')}`;
      fileName += `_to_${this.filterTimerange[1].split(' ').join('_').split('-').join('_')}`;
    }
    return fileName;
  }

  public prepareDataExcel() {
    let data: any[];
    data = [];
    for (const row of this.rows) {
      let items: any;
      items = {};
      for (const col of this.headers) {
        if (col.text === 'Actions') {
          continue;
        }
        if (col.text && col.value) {
          items[col.text] = row[col.value];
        }
      }
      data.push(items);
    }
    return data;
  }

  public loadSavedReportColumnsFromTemplate() {
    if (this.store.currentPackage.name !== 'Free') {
      return;
    }
    this.selectedDrilldownReportColumns = [];
    for (const report of this.templateReports.items) {
      if (this.measure.code === report.measure.code &&
          this.selectedDrilldownDimension.code === report.dimension.code) {
        if (report.reportColumns && report.reportColumns.length > 0) {
          this.selectedDrilldownReportColumns = report.reportColumns;
        }
      }
    }
  }

  public reportWrongData() {
    const reportUrl: string = `https://admin.shopify.com/store/${this.store.shopName.split('.')[0]}/apps/${process.env.VUE_APP_NAME}` + this.$route.fullPath;
    let message: string = 'This report is wrong: ' + reportUrl + '.\n';
    message = message + this.wrongDataMessage;
    this.openChat(message);
    this.dialogReportWrongData = false;
  }
  public openChat(openChatMessage: string) {
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
    // @ts-ignore: Unreachable code error
    // this.$crisp.do('message:send', ['text', this.$t(`chat_with_us.${this.defaultMessage}`)]);
    this.$crisp.do('message:send', ['text', openChatMessage]);
  }

  public isOptionsChanged() {
    if (this.page !== this.options.page) {
      return true;
    }
    if (this.perPage !== this.options.itemsPerPage) {
      return true;
    }
    if (this.options.sortBy
        && this.options.sortBy.length
        && this.sortBy
        && this.sortBy.length > 0
        && this.options.sortBy.length !== this.sortBy.length
       ) {
         return true;
       }
       if (this.options.sortDesc
           && this.options.sortDesc.length > 0
           && this.sortDesc && this.sortDesc.length > 0
           && this.sortDesc.length !== this.options.sortDesc.length
          ) {
            return true;
          }

          if (this.options.sortDesc && this.sortDesc && this.options.sortDesc.length === this.sortDesc.length) {
            for (let index = 0;  index < this.options.sortDesc.length; index++ ) {
              if (this.options.sortDesc[index] !== this.sortDesc[index]) {
                return true;
              }
            }
          }

          if (this.options.sortBy && this.sortBy && this.options.sortBy.length === this.sortBy.length) {
            for (let index = 0;  index < this.options.sortDesc.length; index++ ) {
              if (this.options.sortBy[index] !== this.sortBy[index]) {
                return true;
              }
            }
          }
          return false;
  }

  public async optionChanged() {
    this.page = this.options.page;
    this.perPage = this.options.itemsPerPage;
    this.sortBy = this.options.sortBy;
    this.sortDesc = this.options.sortDesc;
    this.$emit('update-report-option', this.options);
    this.tableLoading = true;
    this.rows = [];

    try {
      if (this.isCreated) {
        await this.getData();
      } else {
        await this.getReport();
      }
    } catch (error: any) {
      this.tableLoading = false;
      this.isLoading = false;
      throw (error);
    }
    this.tableLoading = false;
  }


  public async download(emails: any[], selectedDriveFolder: string, downloadName: string = '') {
    this.report.downloadFileExt = this.downloadFileExt;
    this.report.measure = this.measure;
    this.report.dimension = this.dimension;
    this.report.analyticsType = this.analyticsType;
    this.report.filterTimerange = this.filterTimerange;
    this.report.filterColumns = this.filterColumns;
    this.report.reportParams = this.reportParams;
    this.report.isDetail = this.isDetail;
    this.report.filterDimensions = this.filterDimensions;
    this.report.pivotOption = this.pivotOption;
    this.report.reportColumns = this.reportColumns;
    if (this.sortBy || this.report.sortBy) {
      this.report.sortBy = this.sortBy;
      this.report.sortDesc = this.sortDesc;
    }
    try {
      await this.report.download(emails, true, selectedDriveFolder, downloadName);
      this.sendMixPanelEvent('download data', {
        fileFormat: this.report.downloadFileExt,
        fullData: true,
        measure: this.measure.code,
        dimension: this.dimension.code,
      });
      EventBus.$emit('show-snackbar', {
        message: 'The report is being prepared and will be sent to your email shortly.',
        color: 'success',
      });
    } catch (error: any) {
      // show error message
      EventBus.$emit('show-snackbar', {
        message: error.message,
        color: 'error',
      });
    }
  }

  public getPurchaseOrderReportColumns() {
    const columns: any = [];
    const columnCodes: any = [
      'Item[Item Description]',
      'variant',
      'product_sku',
      'Vendor Name',
      '[inventory_replenishment]',
      '[replenishment_inventory_cost]',
    ];
    for (const column of this.filterReportColumns) {
      if (columnCodes.includes(column.code)) {
        columns.push(column);
      }
    }
    return columns;
  }

  public async generatePurchaseOrder(emails: any[], selectedDriveFolder: string, downloadName: string = '') {
    const report: ReportModel = new ReportModel();
    report.downloadFileExt = this.downloadFileExt;
    report.measure = this.measure;
    report.dimension = this.dimension;
    report.analyticsType = this.analyticsType;
    report.filterTimerange = this.filterTimerange;
    report.filterColumns = this.filterColumns;
    report.reportParams = this.reportParams;
    report.isDetail = this.isDetail;
    report.filterDimensions = this.filterDimensions;
    report.pivotOption = this.pivotOption;
    report.reportColumns = this.getPurchaseOrderReportColumns();
    if (this.sortBy) {
      report.sortBy = this.sortBy;
      report.sortDesc = this.sortDesc;
    }
    try {
      await report.download(emails, true, selectedDriveFolder, downloadName);
      this.sendMixPanelEvent('generate purchase order', {
        fileFormat: this.downloadFileExt,
        measure: this.measure.code,
        dimension: this.dimension.code,
      });
      EventBus.$emit('show-snackbar', {
        message: 'The purchase order is being prepared and will be sent to your email shortly.',
        color: 'success',
      });
    } catch (error: any) {
      // show error message
      EventBus.$emit('show-snackbar', {
        message: error.message,
        color: 'error',
      });
    }
  }

  public updateDownloadFileExt(fileExt: string) {
    this.downloadFileExt = fileExt;
  }

  @Watch('initOptions', { immediate: true, deep: true })
  private async initOptionsChanged(newVal: any, oldVal: any) {
    if (this.initOptions && this.initOptions.sortBy) {
      this.options = this.initOptions;
      this.fisrtTimeInitOption = true;
    }
  }

  @Watch('options', { immediate: true, deep: true })
  private async optionsChanged(newVal: any, oldVal: any) {
    if (this.fisrtTimeInitOption) {
      this.fisrtTimeInitOption = false;
      return;
    }
    if (this.isResetOptions) {
      this.isResetOptions = false;
      return;
    }
    if (this.isOptionsChanged()) {
      this.optionChanged();
    }
  }
}
